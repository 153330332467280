import "./inpPageAIModel.css";

import { useState, useEffect, useContext } from "react";
import {
  FormInp,
  FormSelectInp,
  RadioBtnInpFaceDetection,
  SelectOptionWithModalPopup,
} from "../../../utility/formElements/formElements";
import { IoIosSearch } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import FormInpWrpprFaceDetection from "../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";
import { Checkbox } from "@mui/material";
import { FileSyncButton } from "../../../utility/button/button";
import {
  getAllCameraInfo,
  fetchAllUploadedData,
  submitJobDetails,
  editJobDetails,
  fetchPreDefModels,
} from "../../../action/action";
import { NavBarOptions } from "../../../utility/variables/variables";
import { toast } from "react-toastify";
import { MyContext } from "../../../context/context";

function InpPageAIModel() {
  const [selRadioVal, setSelRadioVal] = useState("pre_trained_model");
  const [selItemList, setSelItemList] = useState([]);
  const [selLabelSelDrpDwn, setSelLabelSelDrpDwn] = useState(
    "Select Pre-Train Model"
  );

  const [preTrainModels, setPreTrainModels] = useState(null);

  const { jobPage, allDetails, sideNav } = useContext(MyContext);

  const InitialDataJobDetl = {
    name: "",
    job_description: "",
    job_type: "pre_trained_model",
    pre_trend_model: "",
    selected_camera: [],
    selected_stored_files: [],
  };
  const [formDataJobDetl, setFormDataJobDetl] = useState({
    ...InitialDataJobDetl,
  });

  const [formDataErrorObj, setFormDataErrorObj] = useState({
    name: "",
    job_description: "",
    job_type: "",
    pre_trend_model: "",
    selected_camera: "",
    selected_stored_files: "",
  });

  const [selectCameraModalDisplay, setSelectCameraModalDisplay] =
    useState(false);
  const [selectStrgFilesDisplay, setSelectStrgFilesDisplay] = useState(false);

  function handleSetInpforform(e) {
    let name = e?.target?.name,
      value = e?.target?.value;
    console.log("changing input for name ===>", name, value);
    setFormDataJobDetl({ ...formDataJobDetl, [name]: value });
  }

  // select camera modal states .........

  const [selectedCamList, setSelectedCamList] = useState([]);

  function handleSelectCamList(x) {
    console.log("handle select cam list ====>", x);
    setSelectedCamList((prev) => {
      let myArr = [...prev],
        isIncluded = false;
      console.log("prev my arr =====>", myArr);
      myArr?.forEach((e) => {
        if (e?.camera_id === x?.camera_id) isIncluded = true;
      });
      if (isIncluded) {
        myArr = myArr?.filter((e) => x?.camera_id !== e?.camera_id);
      } else {
        myArr?.push({ ...x, myLabel: x?.camera_name });
      }
      console.log("modified my arr =====>", myArr);
      return myArr;
    });
  }

  // select camera modal states .........

  // select files from storage modal states .........

  const [selectedFileList, setSelectedFileList] = useState([]);

  function handleSingleFileSelect(x) {
    console.log("selected row data ====>", x);
    setSelectedFileList((prev) => {
      let myArr = [...prev],
        isIncluded = false;
      myArr?.forEach((e) => {
        if (e?.file_id === x?.file_id) isIncluded = true;
      });
      if (isIncluded) {
        myArr = myArr?.filter((e) => e?.file_id !== x?.file_id);
      } else {
        myArr?.push({ ...x, myLabel: x?.file_name });
      }
      console.log("modified my arr ======>", myArr);
      return myArr;
    });
  }

  // select files from storage modal states .........

  const preTrainModelArr = [
    { name: "Model 1", id: 1 },
    { name: "Model 2", id: 2 },
    { name: "Model 3", id: 3 },
    { name: "Model 4", id: 4 },
  ];

  function handleChangeRadio(x) {
    let value = x?.target?.value;
    console.log("selected radio btn value ===>", value);
    // setSelRadioList([{value:x?.value}])
    setSelRadioVal(value);
    setFormDataJobDetl({ ...formDataJobDetl, job_type: value });
  }

  function handleAddModelSel(e) {
    console.log("getting select values ==>", e[0]);
    setSelItemList([{ ...e[0] }]);
    // setAddStorageDetl({ ...addStorageDetl, type: e[0]?.name });
    setSelLabelSelDrpDwn(e[0]?.name);
    setFormDataJobDetl({ ...formDataJobDetl, pre_trend_model: e[0]?.name });
  }

  function handleSelectCameraModalDisplay(x = false) {
    setSelectCameraModalDisplay(x);
  }

  function handleSelectStrgFilesModalDisplay(x = false) {
    setSelectStrgFilesDisplay(x);
  }

  function checkInpJobPageDetl() {
    let isCamListEmpty = selectedCamList[0] ? false : true,
      isFileListEmpty = selectedFileList[0] ? false : true,
      myErrorObj = {},
      isOkay = true;

    if (!formDataJobDetl?.name) {
      myErrorObj.name = 1;
      isOkay = false;
    }
    if (!formDataJobDetl?.job_description) {
      myErrorObj.job_description = 1;
      isOkay = false;
    }
    if (!formDataJobDetl?.job_type) {
      myErrorObj.job_type = 1;
      isOkay = false;
    }
    if (!formDataJobDetl?.pre_trend_model) {
      myErrorObj.pre_trend_model = 1;
      isOkay = false;
    }
    if (isCamListEmpty) {
      myErrorObj.selected_camera = 1;
      // isOkay = false;
    }
    if (isFileListEmpty) {
      myErrorObj.selected_stored_files = 1;
    }
    if (isCamListEmpty && isFileListEmpty && isOkay) {
      isOkay = false;
      toast.error("select alteast one field from Select Data");
    }

    setFormDataErrorObj({ ...myErrorObj });
    return isOkay;
  }

  async function handleFormSubmitJobDetl() {
    console.log("job details to submit ===>", formDataJobDetl);
    console.log("selected cam list to submit ===>", selectedCamList);
    console.log("selected files list to submit ===>", selectedFileList);

    let camIDList = [],
      fileList = [];

    camIDList = selectedCamList?.map((x) => {
      return x?.camera_id;
    });

    console.log("cam id list ==>", camIDList);

    fileList = selectedFileList?.map((x) => x?.file_id);

    if (!checkInpJobPageDetl()) return;

    let data = {
      name: formDataJobDetl?.name,
      job_description: formDataJobDetl?.job_description,
      job_type: formDataJobDetl?.job_type,
      pre_trend_model: formDataJobDetl?.pre_trend_model,
      selected_camera: camIDList || [],
      selected_stored_files: fileList || [],
    };

    let resp,
      msg = "",
      err = "";
    if (jobPage?.editJobCardDetails?.isEditedCall) {
      console.log(
        "sending request for edit job card =================11111111"
      );
      resp = await editJobDetails(jobPage?.editJobCardDetails?.model_id, data);
      msg = "job edited !";
      err = "job editing failed !";
      jobPage?.handleEditJobCardDetails(InitialDataJobDetl, false);
    } else {
      console.log(
        "sending request for create job card =================11111111"
      );
      resp = await submitJobDetails(data);
      msg = "job created !";
      err = "job creation failed !";
    }

    if (resp?.success) {
      console.log("getting response submit job details api ===>", resp?.data);
      toast.success(msg);
      setFormDataJobDetl({ ...InitialDataJobDetl });
      setSelectedCamList([]);
      setSelectedFileList([]);
      let dataObj = { main: "", sub: "", current: "" };
      dataObj.main = NavBarOptions["AI Modeling"].slang;
      dataObj.sub = NavBarOptions["AI Modeling"].subOptn["Job Page"];
      dataObj.current = NavBarOptions["AI Modeling"].subOptn["Job Page"];
      sideNav?.handleSelScreen(dataObj);
    } else {
      toast?.error(err);
    }
  }

  function handleCancelFormJobDetl() {
    setFormDataJobDetl({ ...InitialDataJobDetl });
    setSelectedCamList([]);
    setSelectedFileList([]);
    setSelRadioVal("pre_trained_model");
    setSelLabelSelDrpDwn("Select Pre-Train Model");
    if (jobPage?.editJobCardDetails?.isEditedCall) {
      let dataObj = { main: "", sub: "", current: "" };
      dataObj.main = NavBarOptions["AI Modeling"].slang;
      dataObj.sub = NavBarOptions["AI Modeling"].subOptn["Job Page"];
      dataObj.current = NavBarOptions["AI Modeling"].subOptn["Job Page"];
      sideNav?.handleSelScreen(dataObj);
    }
    jobPage?.handleEditJobCardDetails("", false);
  }

  useEffect(() => {
    console.log(
      "getting job card details in useEffect ------------->",
      jobPage?.editJobCardDetails
    );

    if (!jobPage?.editJobCardDetails?.isEditedCall) return;

    let newObj = {
      name: jobPage?.editJobCardDetails?.name,
      job_description: jobPage?.editJobCardDetails?.job_description,
      job_type: jobPage?.editJobCardDetails?.job_type,
      pre_trend_model: jobPage?.editJobCardDetails?.pre_trend_model,
      selected_camera: jobPage?.editJobCardDetails?.selected_camera || [],
      selected_stored_files:
        jobPage?.editJobCardDetails?.selected_stored_files || [],
    };
    setFormDataJobDetl({ ...newObj });
    setSelRadioVal(jobPage?.editJobCardDetails?.job_type);

    let selectedPreTrendModel = { name: "", id: "" };
    preTrainModelArr?.forEach((e) => {
      if (e?.name === jobPage?.editJobCardDetails?.pre_trend_model) {
        // console.log(
        //   "is match select pre trend model details =====>",
        //   jobPage?.editJobCardDetails,
        //   e
        // );
        selectedPreTrendModel = { ...e };
      }
    });
    // console.log(
    //   "before setting pre trend model 1111====>",
    //   selectedPreTrendModel
    // );
    // allDetails:{
    //   allCamList:allCamList,
    //   allStrdFilesList:allStrgFilesList
    // }

    // console.log(
    //   "before setting pre trend model all cam list====>",
    //   allDetails?.allCamList,
    //   "and all files list ========>",
    //   allDetails?.allStrdFilesList
    // );

    function handleSelectedList(
      idArr = [],
      arrToFilter = [],
      filterPropLabel = "",
      propToAddInLable = ""
    ) {
      let filtered_result = [];
      idArr?.forEach((id) => {
        arrToFilter?.forEach((elem) => {
          if (elem[filterPropLabel] === id) {
            // console.log(
            //   "my elem iter id",
            //   elem[filterPropLabel],
            //   "   and passed id",
            //   id,
            //   "   of label ====>",
            //   filterPropLabel
            // );
            filtered_result.push({ ...elem, myLabel: elem[propToAddInLable] });
          } // myLabel: x?.camera_name
          //  return elem[filterPropLabel] === id
        });
      });
      // console.log(
      //   "filtered result for cam details by id ======>",
      //   filtered_result
      // );
      return filtered_result;
    }

    let mySelCamDetailsArr = handleSelectedList(
        newObj?.selected_camera,
        allDetails?.allCamList,
        "camera_id",
        "camera_name"
      ),
      mySelStrdFileDetailsArr = handleSelectedList(
        newObj?.selected_stored_files,
        allDetails?.allStrdFilesList,
        "file_id",
        "file_name"
      );

    setSelItemList([{ ...selectedPreTrendModel }]);
    setSelLabelSelDrpDwn(selectedPreTrendModel?.name);
    setSelectedCamList([...mySelCamDetailsArr]);
    setSelectedFileList([...mySelStrdFileDetailsArr]);
  }, [jobPage?.editJobCardDetails]);

  useEffect(() => {
    if (preTrainModels) return;
    async function getPreDefModels() {
      let resp = await fetchPreDefModels();

      if (resp?.success) {
        console.log(
          "getting response fetch pre defined data ==>",
          resp?.data?.data?.data
        );
        resp?.data?.data?.data &&
          setPreTrainModels([...resp?.data?.data?.data]);
      }
    }
    getPreDefModels();
  }, []);

  return (
    <div className="connect_storage_screen_container">
      <h2>Basic Details For AI Modeling</h2>
      <p className="connect_storage_screen_container_p">
        Enter Your Details To Make Your Own Custom Model{" "}
      </p>
      <div className="inp_page_ai_model_flex_form_inp_cont">
        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Job Name"}
            placeHldr={"Enter Job Name"}
            nameStr={"name"}
            value={formDataJobDetl?.name}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDataErrorObj?.name && "Field can't be left empty"}
          />
        </FormInpWrpprFaceDetection>

        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Job Description"}
            placeHldr={"Enter Job Description"}
            nameStr={"job_description"}
            value={formDataJobDetl?.job_description}
            handlChange={(e) => handleSetInpforform(e)}
            error={
              formDataErrorObj?.job_description && "Field can't be left empty"
            }
          />
        </FormInpWrpprFaceDetection>
      </div>
      <div className="inp_page_ai_model_flex_form_inp_cont">
        <FormInpWrpprFaceDetection>
          <RadioBtnInpFaceDetection
            label="Job Type"
            radioList={[
              {
                value: "pre_trained_model",
                name: "model_type",
                label: "pre_trained_model",
              },
              {
                value: "custom_model",
                name: "model_type",
                label: "custom_model",
              },
            ]}
            handleChange={(x) => handleChangeRadio(x)}
            selectedValue={selRadioVal}
          />
        </FormInpWrpprFaceDetection>
        <FormInpWrpprFaceDetection>
          <FormSelectInp
            title={"Select Pre-Train Model"}
            placeHldr={"Select Pre-Train Model"}
            multiSel={false}
            handlChange={handleAddModelSel}
            optionList={preTrainModels}
            selProdList={selItemList}
            initialLabelDropDown={selLabelSelDrpDwn}
            error={
              formDataErrorObj?.pre_trend_model &&
              "Please select a model to continue"
            }
          />
        </FormInpWrpprFaceDetection>
      </div>
      <p className="connect_storage_screen_container_p">Select Data</p>

      <SelectOptionWithModalPopup
        mainHeadLabel="Select Camera"
        handleModalDisplay={(x) => handleSelectCameraModalDisplay(x)}
        modalDisplay={selectCameraModalDisplay}
        seletedItemList={selectedCamList}
        // error={
        //   formDataErrorObj?.selected_camera &&
        //   "Please selected atleast one option to continue"
        // }
      >
        <SelectCameraModal
          handleModalDisplay={(x) => handleSelectCameraModalDisplay(x)}
          handleCamList={handleSelectCamList}
          seletedCamList={selectedCamList}
        />
      </SelectOptionWithModalPopup>

      <div className="selectStrgFilesInptWrppr">
        <SelectOptionWithModalPopup
          mainHeadLabel="Select Stored Files"
          handleModalDisplay={(x) => handleSelectStrgFilesModalDisplay(x)}
          modalDisplay={selectStrgFilesDisplay}
          seletedItemList={selectedFileList}
          // error={
          //   formDataErrorObj?.selected_stored_files &&
          //   "Please selected atleast one option to continue"
          // }
        >
          <SelectFileFrmStrgModal
            handleModalDisplay={(x) => handleSelectStrgFilesModalDisplay(x)}
            selectedFileList={selectedFileList}
            handleSingleSelFiles={handleSingleFileSelect}
          />
        </SelectOptionWithModalPopup>
      </div>

      <div className="inp_page_ai_modal_submit_btnWrppr">
        <FileSyncButton
          title="Save"
          stylObj={{
            boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            color: "white",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
          handleClick={handleFormSubmitJobDetl}
        />
        <FileSyncButton
          title="Cancel"
          stylObj={{
            background: "white",
            color: "#05306B",
            // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
          handleClick={handleCancelFormJobDetl}
        />
      </div>
    </div>
  );
}

export default InpPageAIModel;

function SelectCameraModal({
  handleModalDisplay,
  handleCamList,
  seletedCamList,
}) {
  const [allCamList, setAllCamList] = useState([]);

  function handleCheckIsSelectedCheckBox(id) {
    let isSelected = false;
    seletedCamList?.forEach((e) => {
      if (e?.camera_id === id) isSelected = true;
    });
    return isSelected;
  }

  useEffect(() => {
    async function fetchCameraDetails(isInitial = true) {
      let response = await getAllCameraInfo();

      console.log(
        "the response fetch camera details =======>",
        response?.data?.AllCamera
      );
      if (!response?.success) return;
      let allCamArr = response?.data?.AllCamera || [];

      setAllCamList([...allCamArr]);
      if (isInitial) {
        let myDetl = response?.data?.AllCamera[0];
        console.log("my ==========>", myDetl);
      }
    }
    fetchCameraDetails();
  }, []);

  return (
    <div
      className="sel_camera_modal_main_cont"
      onClick={(e) => e?.stopPropagation()}
    >
      <IoMdClose
        className="close_btn_sel_camera_modal"
        onClick={() => handleModalDisplay(false)}
      />
      <p className="sel_camera_modal_main_head">Select Camera</p>
      <div className="sel_camera_modal_activity_area">
        <div className="sel_camera_modal_search_inp_wrppr">
          <input type="text" name="" id="" placeholder="Search Camera" />
          <IoIosSearch />
        </div>
        <div className="sel_camera_modal_options_outer_wrppr">
          <div className="sel_camera_modal_options_wrppr">
            {/* select camera modal camera card with check box */}

            {allCamList?.map((e) => {
              return (
                <div className="sel_camera_modal_sel_cam_card_checkbox">
                  <Checkbox
                    size="12"
                    checked={handleCheckIsSelectedCheckBox(e?.camera_id)}
                    onChange={() => handleCamList(e)}
                  />
                  <p>{e?.camera_name}</p>
                </div>
              );
            })}

            {/* select camera modal camera card with check box */}
          </div>
        </div>
      </div>
      {/* <div className="sel_camera_modal_submit_btnWrppr">
        <FileSyncButton
          title="Save"
          stylObj={{
            boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            color: "white",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        />
        <FileSyncButton
          title="Cancel"
          stylObj={{
            background: "white",
            color: "#05306B",
            // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        />
      </div> */}
    </div>
  );
}

function SelectFileFrmStrgModal({
  handleModalDisplay,
  handleSingleSelFiles = () => {},
  selectedFileList = [],
}) {
  const headers = ["File Name", "File Type", "Size", "Upload Date & Time"];

  const columnMinWidths = [43, 43, 43, 43, 43, 43];

  const [allFilesList, setAllFilesList] = useState([]);

  useEffect(() => {
    async function fetchAllUploadData() {
      let resp = await fetchAllUploadedData();

      console.log("fethed uploaded data =======>", resp?.data?.data?.file_info);
      let myArr = resp?.data?.data?.file_info || [];
      setAllFilesList([...myArr]);
    }
    fetchAllUploadData();
  }, []);

  return (
    <>
      <div
        className="sel_files_from_storage_modal_main_cont"
        onClick={(e) => e?.stopPropagation()}
      >
        <IoMdClose
          className="close_btn_sel_camera_modal"
          onClick={() => handleModalDisplay(false)}
        />
        <p className="sel_camera_modal_main_head">Select File From Storage </p>
        <div className="sel_files_from_storage_activity_area">
          <div className="sel_camera_modal_search_inp_wrppr">
            <input type="text" name="" id="" placeholder="search File name" />
            <IoIosSearch />
          </div>
          <div className="sel_files_from_storage_options_outer_wrppr">
            <TableCompSelectFilesFromStorage
              headers={headers}
              columnMinWidths={columnMinWidths}
              data={allFilesList}
              handleSingleSelect={handleSingleSelFiles}
              selectedItemsList={selectedFileList}
            />
          </div>
        </div>
        {/* <div className="sel_files_from_storage_modal_submit_btnWrppr">
          <FileSyncButton
            title="Save"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
          <FileSyncButton
            title="Cancel"
            stylObj={{
              background: "white",
              color: "#05306B",
              // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
        </div> */}
      </div>
    </>
  );
}

function TableCompSelectFilesFromStorage({
  headers = [],
  data = [],
  columnMinWidths = [],
  handleSingleSelect = () => {},
  handleSelectAll = () => {},
  selectedItemsList = [],
}) {
  function handleCheckIsSelectedCheckBox(id) {
    console.log(
      "coming id ===>",
      id,
      "selected items list ====>",
      selectedItemsList
    );
    let isSelected = false;
    selectedItemsList?.forEach((e) => {
      if (e?.file_id === id) isSelected = true;
    });
    return isSelected;
  }
  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            <th style={{ width: `50px` }}>
              <Checkbox
                //   checked={admin?.crtdUsers?.length === selectedUserList?.length}
                //   onChange={handleSelectAllUsers}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </th>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI">
          {data?.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`} className="datasetTableRowFDFI">
              <td width={"50px"}>
                <Checkbox
                  checked={handleCheckIsSelectedCheckBox(row?.file_id)}
                  onChange={() => {
                    handleSingleSelect(row);
                  }}
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>
              <td
                // key={`cell-${rowIndex}-${cellIndex}`}
                style={{ width: `${columnMinWidths[0]}%` }}
              >
                {row?.file_name}
              </td>
              <td style={{ width: `${columnMinWidths[1]}%` }}>
                {row?.file_type}
              </td>
              <td style={{ width: `${columnMinWidths[2]}%` }}>
                {row?.file_size}
              </td>
              <td style={{ width: `${columnMinWidths[3]}%` }}>
                {row?.timestamp}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
