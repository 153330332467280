import "./button.css";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export function FileSyncButton({
  title='',
  src,
  bkg = "makeBlueBtn",
  handleClick,
  disabled,
  stylObj=false,
  children,
  btnRef = null,
}) {
  return (
    <>
      <button
        className={`fileSyncBtnWrppr ${bkg}`}
        onClick={handleClick}
        disabled={disabled}
        style={{...stylObj}}
        ref={btnRef}
      >
        {children ? (
          children
        ) : (
          src&&<img className="fileSyncBtnIcon" src={"/assests/img/" + src} alt="" />
        )}

        <p className={!stylObj?"fileSyncBtnTitle":""}>{title}</p>
      </button>
    </>
  );
}

export function AdminPanelBtn({
  title,
  src,
  handleClick,
  bgCol = "#0574EA",
  stylObj = {},
  iconStyl = {},
  disabled,
}) {
  return (
    <>
      <button
        className={`adminPromptBtnWrppr`}
        onClick={handleClick}
        style={{ ...stylObj, background: bgCol }}
        disabled={disabled}
      >
        <img
          className="adminPromptBtnIcon"
          src={"/assests/img/" + src}
          alt="icon"
          style={{ ...iconStyl }}
        />
        <p className="adminPromptBtnTitle">{title}</p>
      </button>
    </>
  );
}

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E42827' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export function AllCamScrnFormatBtn({label,children,clickHandler=()=>{},active=false}){
return <button className={`allcams_screen_btn ${active ? "active":""}`} onClick={clickHandler}>
  {children}
  <p>{label}</p>
</button>
}