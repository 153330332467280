import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Dropdown,
  MultiSelectDropdown,
} from "../../../utility/twElems/dropDown/dropDown";
import { ButtonComp } from "../../..//utility/twElems/Button/button";

import {
  fetchCameraFilterList,
  waitingFunction,
} from "../../../utility/functions/helperFunc";

import ViewPageCameraSettings from "./viewPageCameraSettings";

import { Plus, MoveRight, Trash2 } from "lucide-react";

import { MyContext } from "../../../context/context";
import {
  getDSModelList,
  getCameraModelList,
  sendCameraModelConnectionList,
  deleteCameraModelConnectionListByID,
} from "../../../action/action";
import { toast } from "react-toastify";

const multiSelectOptionList = [
    {
      id: 0,
      label: "option-1",
    },
    {
      id: 1,
      label: "option-2",
    },
    {
      id: 2,
      label: "option-3",
    },
    {
      id: 3,
      label: "option-4",
    },
    {
      id: 4,
      label: "option-5",
    },
    {
      id: 5,
      label: "option-6",
    },
  ],
  singleSelectOptionList = [
    "option-1",
    "option-2",
    "option-3",
    "option-4",
    "option-1",
    "option-2",
    "option-3",
    "option-4",
  ];

export default function CameraSettingsScrn() {
  const [modelOnCamList, setModelOnCamList] = useState([]);
  const [modelList, setModelList] = useState([]);

  const [modelRowSetList, setModelRowSetList] = useState([]);

  const [loadingSaveModelOnCam, setLoadingSaveModelOnCam] = useState(false);

  const { cameraFilters, allDetails, sideNav } = useContext(MyContext);
  const [formvalues, setFormvalues] = useState({});

  const [camPremisesFilter, setCamPremisesFilter] = useState([]);
  const [camFloorFilter, setCamFloorFilter] = useState([]);
  const [camSectionFilter, setCamSectionFilter] = useState([]);

  let modifiedList = allDetails?.allCamList?.map((item) => {
    return { id: item?.camera_id, label: item?.camera_name };
  });
  const [filteredList, setFilteredList] = useState([...modifiedList]);
  const [isDisplayViewPage, setIsDisplayViewPage] = useState(false);

  const selectedFilter = useRef({
    location: "",
    premises: "",
    floor: "",
    section: "",
    type: "",
  });

  function handleSwitchToViewCameraSettings() {
    setIsDisplayViewPage((prev) => {
      return !prev;
    });
  }

  async function handleInputChange(v) {
    let val = v?.value,
      label = v?.name;
    console.log(
      "input change call ===>",
      label,
      formvalues?.location,
      formvalues?.building,
      formvalues?.floor
    );

    setFormvalues({ ...formvalues, [label]: val });
  }

  async function handleLocationChange(val) {
    setCamPremisesFilter([]);
    setCamFloorFilter([]);
    setCamSectionFilter([]);

    let responseList = await fetchCameraFilterList({
      location: val,
      premises: "",
      floor: "",
      type: "location",
    });
    setFormvalues({
      location: val,
      building: "",
      floor: "",
      section: "",
    });
    setCamPremisesFilter([...responseList]);
  }

  async function handlePremisesChange(val) {
    // setCamPremisesFilter([]);
    setCamFloorFilter([]);
    setCamSectionFilter([]);

    let responseList = await fetchCameraFilterList({
      location: selectedFilter.current?.location,
      premises: val,
      floor: "",
      type: "premises",
    });
    setFormvalues((prev) => {
      return {
        ...prev,
        building: val,
        floor: "",
        section: "",
      };
    });
    setCamFloorFilter([...responseList]);
  }

  async function handleFloorChange(val) {
    // setCamPremisesFilter([]);
    // setCamFloorFilter([]);
    setCamSectionFilter([]);

    let responseList = await fetchCameraFilterList({
      location: selectedFilter.current?.location,
      premises: selectedFilter.current?.premises,
      floor: val,
      type: "floor",
    });

    setCamSectionFilter([...responseList]);
    setFormvalues((prev) => {
      return {
        ...prev,
        floor: val,
        section: "",
      };
    });
  }

  function appendToModelOnCamRow() {
    let newRow = {};
    newRow.set_id = Date.now();
    setModelOnCamList([...modelOnCamList, newRow]);
  }

  async function deleteModelOnCamRow(modelInfo) {
    console.log("deleting selected row =====>", modelInfo);

    setModelOnCamList((prev) => {
      let newList = prev.filter((item) => {
        return item.set_id !== modelInfo.set_id;
      });
      return newList;
    });

    // return;
    // toast.info("Deleting selected row");
    // let resp = await deleteCameraModelConnectionListByID(modelInfo.set_id);
    // if (resp?.success) {
    //   toast.success("done");
    // getCamerModelList();
    // } else {
    //   toast.error("Please try again");
    // }
  }

  function handleClickedClearAll() {
    console.log("clicked clear all =====>");

    setCamPremisesFilter([]);
    setCamFloorFilter([]);
    setCamSectionFilter([]);

    console.log(
      "input change call ===>",
      formvalues?.location,
      formvalues?.building,
      formvalues?.floor
    );
    setFilteredList(allDetails?.allCamList);
    setFormvalues((prev) => {
      // console.log("prev =====>", prev);
      return { ...prev, location: "", premises: "", floor: "", section: "" };
    });
    selectedFilter.current = {
      location: "",
      premises: "",
      floor: "",
      section: "",
      type: "location",
    };
    // waitingFunction(500);
    // getCamerModelList();
  }

  function handleClickedApply() {
    console.log("clicked apply =====>", allDetails?.allCamList);
    console.log("selected filters =====>", selectedFilter.current);
    let mydataList = filterData(allDetails?.allCamList, selectedFilter.current);
    console.log("my data list =====>", mydataList);
    mydataList = mydataList.map((item) => {
      return {
        id: item.id,
        label: item.name,
      };
    });
    setFilteredList(mydataList);
    setModelOnCamList((prev) => {
      console.log("apply click model on camera list ===>", prev);
      return prev;
    });
    // getCamerModelList();
  }
  function filterData(data, filters) {
    return data.filter((item) => {
      return (
        (filters.floor === "" || item.floor === filters.floor) &&
        (filters.location === "" || item.city === filters.location) &&
        (filters.premises === "" || item.building_name === filters.premises) &&
        (filters.section === "" || item.area === filters.section)
      );
    });
  }

  async function getCamerModelList() {
    let resp = await getCameraModelList(
      selectedFilter.current?.location,
      selectedFilter.current?.premises,
      selectedFilter.current?.floor,
      selectedFilter.current?.section
    );
    if (resp?.success) {
      let mydataList =
        updateUpcomingCamModelListOnGet(resp?.data?.data?.data) || [];
      setModelOnCamList(mydataList);
    }
  }

  function handleAddSelectedModelSet(selCamLIst, selModelLIst, id) {
    console.log("getting selcamlist =====>", selCamLIst);
    console.log("getting selmodellist =====>", selModelLIst);

    setModelOnCamList((prev) => {
      const prevList = [...prev];
      if (prev?.length === 0) {
        if (!(selCamLIst?.length && selModelLIst?.length)) return prevList;
        let newRow = {
          set_id: Date.now(),
          assign_camera: [...selCamLIst],
          assign_model: [...selModelLIst],
        };
        console.log("new row =====>", newRow);
        prevList?.push(newRow);
      } else {
        console.log("modifying existing row =====>", prev);
        prevList?.forEach((item, index) => {
          if (item.set_id === id) {
            prevList[index]["assign_camera"] = [...selCamLIst];
            prevList[index]["assign_model"] = [...selModelLIst];
          }
        });
      }
      console.log("row model cam list updated =====>", prevList);
      return prevList;
    });
  }

  // function handleDeleteSelectedModelSet(id) {
  //   setModelRowSetList((prev) => {
  //     return prev.filter((item) => item.id !== id);
  //   });
  // }

  async function handleSaveModelCameraCOnnection(data = []) {
    // console.log("my camera settings data to send ===>", data);

    // return;
    if (data?.length === 0) {
      toast.warn("Please select camera and model");
      return;
    }

    /*

[
	{
		"camera_id": 1,
		"camera_name": "Raipur office",
		"assign_model": [
			{
				"id": 1,
				"name": "fd"
			},
			{
				"id": 2,
				"name": "cd"
			}
		]
	},
	{
		"camera_id": 33,
		"camera_name": "Main Parking",
		"assign_model": [
			{
				"id": 1,
				"name": "fd"
			}
		]
	}
]

*/

    let modDataList = [];
    data.forEach((item) => {
      let obj = { ...item };
      obj.set_id = `${obj?.set_id}`;
      let newDataList = obj?.assign_camera.map((cam) => {
        let dataObj = {
          camera_id: "",
          camera_name: "",
          assign_model: [],
        };
        dataObj.camera_id = cam?.id || "";
        dataObj.camera_name = cam?.label || "";
        let newArrAssignModel = obj?.assign_model.map((model) => {
          return { id: model?.id, name: model?.label };
        });

        dataObj.assign_model = [...newArrAssignModel];

        return dataObj;
      });

      modDataList = [...modDataList, ...newDataList];

      // return dataObj;
    });

    // console.log("saving camera-model connection list =====>", modDataList);
    setLoadingSaveModelOnCam(true);
    let resp = await sendCameraModelConnectionList({ data: modDataList });
    setLoadingSaveModelOnCam(false);
    if (resp?.success) {
      toast.success("Saved Successfully");
      setModelOnCamList([]);
      handleClickedClearAll();
    } else {
      toast.error("Please try again");
    }
  }

  function updateUpcomingCamModelListOnGet(data) {
    let modDataList = data.map((item) => {
      let obj = { ...item };
      obj.set_id = `${obj?.cam_set_id}`;
      obj.assign_camera = obj?.assign_camera?.map((cam) => {
        return { id: cam?.id, label: cam?.name };
      });
      obj.assign_model = obj?.assign_model?.map((model) => {
        return { id: model?.id, label: model?.name };
      });
      return obj;
    });
    return modDataList;
  }

  useEffect(() => {
    console.log("filtered list", filteredList);
    async function getModelList() {
      let resp = await getDSModelList();
      if (resp?.success) {
        console.log("resp get model list ======>", resp?.data?.data?.data);
        let mydataList = resp?.data?.data?.data?.map((item) => {
          return {
            id: item?.id,
            label: item?.name,
          };
        });
        setModelList(mydataList);
      }
    }
    getModelList();

    // async function getCameraModelSetList() {
    //   let resp = await getCameraModelList(
    //     selectedFilter.current?.location,
    //     selectedFilter.current?.premises,
    //     selectedFilter.current?.floor,
    //     selectedFilter.current?.section
    //   );
    //   if (resp?.success) {
    //     let mydataList =
    //       updateUpcomingCamModelListOnGet(resp?.data?.data?.data) || [];
    //     console.log("getting camera model list =====>", mydataList);
    //     setModelOnCamList(mydataList);
    //   }
    // }
    // getCameraModelSetList();
  }, []);

  return (
    <>
      {isDisplayViewPage ? (
        <ViewPageCameraSettings
          handleSwitchToCameraSettings={handleSwitchToViewCameraSettings}
        />
      ) : (
        <div className="connect_storage_screen_container w-full h-full flex flex-col">
          <div className="flex justify-between items-center">
            <div className="w-full">
              <h6 class="font-manrope text-xl font-bold text-left text-blue-900">
                Camera Settings
              </h6>

              <p class="font-manrope text-sm font-normal leading-5 text-left mb-4">
                Create and set model to your camera{" "}
              </p>
            </div>
            <div className="w-full flex justify-end my-4">
              <ButtonComp
                // icon={}
                label="View Camera & Model Connection"
                className="bg-blue-900 text-white font-manrope flex gap-2 p-4 rounded"
                clickHandler={() => handleSwitchToViewCameraSettings()}
              />
            </div>
          </div>

          <div className="flex gap-6 mb-4">
            <div className="flex-1">
              <Dropdown
                label="Location"
                placehldr="Select Camera Location"
                options={cameraFilters?.location || []}
                important={false}
                fetchValue={(val) => {
                  selectedFilter.current = {
                    location: val,
                    premises: "",
                    section: "",
                    floor: "",
                    type: "location",
                  };
                  console.log("location val changes ===>", val);
                  handleLocationChange(val);
                  handleInputChange({ value: val, name: "location" });
                }}
                valueToSet={formvalues?.location || ""}
              />
            </div>
            <div className="flex-1">
              <Dropdown
                label="Premises"
                // label="Building"
                options={camPremisesFilter || []}
                placehldr="Select Camera Premises"
                important={false}
                fetchValue={(val) => {
                  selectedFilter.current = {
                    ...selectedFilter.current,
                    premises: val,
                    section: "",
                    floor: "",
                    type: "premises",
                  };
                  handlePremisesChange(val);
                  handleInputChange({ value: val, name: "premises" });
                }}
                valueToSet={formvalues?.premises || ""}
              />
            </div>
            <div className="flex-1">
              <Dropdown
                label="Floor"
                placehldr="Select Camera Floor"
                options={camFloorFilter || []}
                important={false}
                fetchValue={(val) => {
                  selectedFilter.current = {
                    ...selectedFilter.current,
                    section: "",
                    floor: val,
                    type: "floor",
                  };
                  handleFloorChange(val);
                  handleInputChange({ value: val, name: "floor" });
                }}
                valueToSet={formvalues?.floor || ""}
              />
            </div>
            <div className="flex-1">
              <Dropdown
                label="Section"
                placehldr="Select Camera Section"
                options={camSectionFilter || []}
                important={false}
                fetchValue={(val) => {
                  selectedFilter.current = {
                    ...selectedFilter.current,
                    section: val,
                    type: "section",
                  };
                  handleInputChange({ value: val, name: "section" });
                }}
                valueToSet={formvalues?.section || ""}
              />
            </div>
            <ButtonComp
              label="Clear All"
              className="font-semibold bg-blue-100 text-blue-900 p-4 rounded"
              clickHandler={() => {
                handleClickedClearAll();
              }}
            />
            {/* <div className="flex-1">
        </div> */}
            <ButtonComp
              label="Apply"
              className="font-semibold bg-blue-900 text-white p-4 rounded"
              clickHandler={() => {
                handleClickedApply();
              }}
            />
            {/* <div className="flex-1">
        </div> */}
          </div>
          <div className="bg-white flex-1 p-8 rounded-md">
            <div className="w-full flex my-6">
              <div className="w-1/2">
                <p className="font-manrope font-semibold text-base text-slate-900">
                  Assign Camera
                </p>
              </div>
              <div className="w-1/2">
                <p className="font-manrope font-semibold text-base text-slate-900">
                  Assign Model
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-4 mb-6">
              {modelOnCamList.length ? (
                modelOnCamList?.map((row, index) => {
                  return (
                    <ModelOnCamRow
                      isDelete={true}
                      handleDelete={() => deleteModelOnCamRow(row)}
                      modelInfo={row}
                      cameraList={filteredList}
                      selectedCamListVal={row?.assign_camera}
                      selectedModelListVal={row?.assign_model}
                      modelList={modelList}
                      key={index + "modeloncamrow"}
                      fetchValue={(camlist, modelList) =>
                        handleAddSelectedModelSet(
                          camlist,
                          modelList,
                          row.set_id
                        )
                      }
                    />
                  );
                })
              ) : (
                <ModelOnCamRow
                  cameraList={filteredList}
                  modelList={modelList}
                  // selectedCamListVal={[]}
                  //     selectedModelListVal={[]}
                  fetchValue={(camlist, modelList) =>
                    handleAddSelectedModelSet(camlist, modelList, "")
                  }
                />
              )}
            </div>
            <div className="w-full flex justify-end">
              <ButtonComp
                icon={<Plus />}
                label="Add Row"
                className="bg-blue-900 text-white font-manrope flex gap-2 p-4 rounded"
                clickHandler={appendToModelOnCamRow}
              />
            </div>
            <div className="w-full flex justify-start">
              <ButtonComp
                // icon={}
                label="Save Settings"
                className="bg-blue-900 text-white font-manrope flex gap-2 p-4 rounded"
                clickHandler={() =>
                  handleSaveModelCameraCOnnection(modelOnCamList)
                }
                isLoading={loadingSaveModelOnCam}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ModelOnCamRow({
  isDelete = false,
  handleDelete = () => {},
  modelInfo = { id: 0, name: "row" },
  cameraList = [],
  modelList = [],
  selectedCamListVal = [],
  selectedModelListVal = [],
  fetchValue = () => {},
}) {
  console.log("camera list =====>", cameraList);
  const [selectedCamList, setSelectedCamList] = useState([
    ...selectedCamListVal,
  ]);
  const [selectedModelList, setselectedModelList] = useState([
    ...selectedModelListVal,
  ]);

  function handleSelectedCamList(val) {
    setSelectedCamList(val);
  }

  function handleSelectedModelList(val) {
    setselectedModelList(val);
  }

  useEffect(() => {
    fetchValue(selectedCamList, selectedModelList);
  }, [selectedCamList, selectedModelList]);

  return (
    <div className="w-full flex gap-12 items-center rounded-md border-slate-200 border p-3">
      <div className="w-5/12 border-blue-100 border rounded-md">
        <MultiSelectDropdown
          label="Camera"
          placehldr="Select Camera"
          important={false}
          options={cameraList}
          fettchValue={handleSelectedCamList}
          selectedOptionsListVal={selectedCamList}
        />
      </div>
      <MoveRight />
      <div className="w-5/12 border-blue-100 border rounded-md">
        <MultiSelectDropdown
          label="Model"
          placehldr="Select model"
          important={false}
          options={modelList}
          fettchValue={handleSelectedModelList}
          selectedOptionsListVal={selectedModelList}
        />
      </div>
      {isDelete ? (
        <ButtonComp
          icon={<Trash2 />}
          // label="Delete"
          className="bg-red-600 text-white flex gap-2 p-4 rounded"
          clickHandler={handleDelete}
        />
      ) : (
        <div className="w-16"></div>
      )}
    </div>
  );
}
