// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin_top_append{
    margin-top: 1rem;
}

.face_detec_web_cam_main_cont{
    display: flex;
    justify-content: center;
}

.web_cam_stream_cir_cont{
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}

.web_cam_stream_cont{
    overflow: hidden;
    border-radius: 100%;
    /* width: 29rem;
    height: 29rem; */
    width: 464px;
    height: 464px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.face_detec_web_cam_main_cont_face_indicators{
    
}`, "",{"version":3,"sources":["webpack://./src/components/screens/addEmployeeScreen/addEmployeeScrn.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB;oBACgB;IAChB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;;AAEA","sourcesContent":[".margin_top_append{\n    margin-top: 1rem;\n}\n\n.face_detec_web_cam_main_cont{\n    display: flex;\n    justify-content: center;\n}\n\n.web_cam_stream_cir_cont{\n    position: relative;\n    width: fit-content;\n}\n\n.web_cam_stream_cont{\n    overflow: hidden;\n    border-radius: 100%;\n    /* width: 29rem;\n    height: 29rem; */\n    width: 464px;\n    height: 464px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n\n.face_detec_web_cam_main_cont_face_indicators{\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
