import { FormInput } from "../../../utility/twElems/input/input";
import { Dropdown } from "../../../utility/twElems/dropDown/dropDown";
import { ButtonComp } from "../../../utility/twElems/Button/button";

import { Camera, X } from "lucide-react";
import { useEffect, useState, useContext, useRef } from "react";

import { fetchCameraFilterList } from "../../../utility/functions/helperFunc";

import { MyContext } from "../../../context/context";

export function AddCameraForm({
  handleClose = () => {},
  initialValues = null,
  handleEmptyInitVal = () => {},
  handleSubmitCallback = async () => {},
  SubmitMode = null,
}) {
  const [formvalues, setFormvalues] = useState({});
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const { cameraFilters } = useContext(MyContext);

  const selectedFilter = useRef({
    location: "",
    premises: "",
    floor: "",
    section: "",
    type: "",
  });

  const [camPremisesFilter, setCamPremisesFilter] = useState([]);
  const [camFloorFilter, setCamFloorFilter] = useState([]);
  const [camSectionFilter, setCamSectionFilter] = useState([]);

  async function handleInputChange(v) {
    let val = v?.value,
      label = v?.name;
    console.log(
      "input change call ===>",
      label,
      formvalues?.location,
      formvalues?.building,
      formvalues?.floor
    );
    // if (label === "location") {
    //   setCamPremisesFilter([]);
    //   setCamFloorFilter([]);
    //   setCamSectionFilter([]);
    // }
    // let responseList = await fetchCameraFilterList({
    //   location: formvalues?.location || "",
    //   premises: formvalues?.building || "",
    //   floor: formvalues?.floor || "",
    //   type: v?.name || "",
    // });
    // if (label === "location") {
    //   setCamPremisesFilter([...responseList]);
    // } else if (label === "premises") {
    //   setCamFloorFilter([...responseList]);
    // } else if (label === "floor") {
    //   setCamSectionFilter([...responseList]);
    // }

    // if (selectedFilter?.type === "location") {
    //   setCamPremisesFilter([]);
    //   setCamFloorFilter([]);
    //   setCamSectionFilter([]);
    // } else if (selectedFilter?.type === "premises") {
    //   setCamFloorFilter([]);
    //   setCamSectionFilter([]);
    // } else if (selectedFilter?.type === "floor") {
    //   setCamSectionFilter([]);
    // }

    setFormvalues({ ...formvalues, [label]: val });

    // let responseList = await fetchCameraFilterList({
    //   location: selectedFilter?.location || "",
    //   premises: selectedFilter?.building || "",
    //   floor: selectedFilter?.floor || "",
    //   type: selectedFilter?.type || "",
    // });

    // if (selectedFilter?.type === "location") {
    //   setCamPremisesFilter([...responseList]);
    // } else if (selectedFilter?.type === "premises") {
    //   setCamFloorFilter([...responseList]);
    // } else if (selectedFilter?.type === "floor") {
    //   setCamSectionFilter([...responseList]);
    // }
  }

  async function handleLocationChange(val) {
    setCamPremisesFilter([]);
    setCamFloorFilter([]);
    setCamSectionFilter([]);

    let responseList = await fetchCameraFilterList({
      location: val,
      premises: "",
      floor: "",
      type: "location",
    });

    setCamPremisesFilter([...responseList]);
  }

  async function handlePremisesChange(val) {
    // setCamPremisesFilter([]);
    setCamFloorFilter([]);
    setCamSectionFilter([]);

    let responseList = await fetchCameraFilterList({
      location: selectedFilter.current?.location,
      premises: val,
      floor: "",
      type: "premises",
    });

    setCamFloorFilter([...responseList]);
  }

  async function handleFloorChange(val) {
    // setCamPremisesFilter([]);
    // setCamFloorFilter([]);
    setCamSectionFilter([]);

    let responseList = await fetchCameraFilterList({
      location: selectedFilter.current?.location,
      premises: selectedFilter.current?.premises,
      floor: val,
      type: "floor",
    });

    setCamSectionFilter([...responseList]);
  }

  async function handleSubmitForm() {
    setLoadingSubmitButton(true);
    await handleSubmitCallback(formvalues);
    setLoadingSubmitButton(false);
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (initialValues) {
      setFormvalues(initialValues);
    }
    return () => {
      if (initialValues) {
        handleEmptyInitVal(null);
      }
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="bg-white p-4 rounded-lg shadow max-w-5xl flex flex-col gap-4 h-5/6 overflow-y-auto scrollbar">
      <h3 className="text-xl font-semibold text-slate-900 font-manrope">
        {SubmitMode === "add" && "Add Camera"}
        {SubmitMode === "edit" && "Edit Camera"}
      </h3>
      <div className="relative">
        <X
          className="absolute bottom-4 right-0 cursor-pointer text-slate-600"
          size={28}
          onClick={handleClose}
        />
      </div>
      <FormInput
        label="Camera Name"
        important={true}
        placehldr="Enter Camera Name"
        fetchvalue={(val) => handleInputChange({ value: val, name: "name" })}
        valueToSet={formvalues?.name}
      />

      <FormInput
        label="Camera Description"
        important={true}
        placehldr="Enter Camera Description"
        fetchvalue={(val) =>
          handleInputChange({ value: val, name: "description" })
        }
        valueToSet={formvalues?.description}
      />
      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <Dropdown
            label="Location"
            options={cameraFilters?.location || []}
            placehldr="Select Location"
            important={false}
            fetchValue={(val) => {
              selectedFilter.current = {
                location: val,
                premises: "",
                section: "",
                floor: "",
                type: "location",
              };
              console.log("location val changes ===>", val);
              handleLocationChange(val);
              handleInputChange({ value: val, name: "location" });
            }}
            valueToSet={formvalues?.location || ""}
          />
        </div>
        <div className="flex-1">
          <Dropdown
            label="Building"
            options={camPremisesFilter || []}
            placehldr="Select Building"
            important={false}
            fetchValue={(val) => {
              selectedFilter.current = {
                ...selectedFilter.current,
                premises: val,
                section: "",
                floor: "",
                type: "premises",
              };
              handlePremisesChange(val);
              handleInputChange({ value: val, name: "premises" });
            }}
            valueToSet={formvalues?.building || ""}
          />
        </div>
        <div className="flex-1">
          <Dropdown
            label="Floor"
            options={camFloorFilter || []}
            important={false}
            placehldr="Select Floor"
            fetchValue={(val) => {
              selectedFilter.current = {
                ...selectedFilter.current,
                section: "",
                floor: val,
                type: "floor",
              };
              handleFloorChange(val);
              handleInputChange({ value: val, name: "floor" });
            }}
            valueToSet={formvalues?.floor || ""}
          />
        </div>
        <div className="flex-1">
          <Dropdown
            label="Section"
            options={camSectionFilter || []}
            important={false}
            placehldr="Select Section"
            fetchValue={(val) => {
              selectedFilter.current = {
                ...selectedFilter.current,
                section: val,
                type: "section",
              };
              handleInputChange({ value: val, name: "section" });
            }}
            valueToSet={formvalues?.section || ""}
          />
        </div>
      </div>
      <FormInput
        label="User Name"
        important={true}
        placehldr="Enter User Name"
        fetchvalue={(val) =>
          handleInputChange({ value: val, name: "user_name" })
        }
        valueToSet={formvalues?.user_name}
      />
      <FormInput
        label="Password"
        important={true}
        placehldr="Enter Password"
        type="password"
        fetchvalue={(val) =>
          handleInputChange({ value: val, name: "password" })
        }
        valueToSet={formvalues?.password}
      />
      <FormInput
        label="IP Address"
        important={true}
        placehldr="Enter IP Address"
        fetchvalue={(val) =>
          handleInputChange({ value: val, name: "ip_address" })
        }
        valueToSet={formvalues?.ip_address}
      />
      <FormInput
        label="Port"
        important={true}
        placehldr="Enter Port"
        fetchvalue={(val) => handleInputChange({ value: val, name: "port" })}
        valueToSet={formvalues?.port}
      />
      <div className="w-fit h-fit">
        <ButtonComp
          label={
            SubmitMode === "edit"
              ? "Update Camera"
              : SubmitMode === "add"
              ? "Connect Camera"
              : "null"
          }
          className="bg-blue-900 text-white p-4 font-manrope font-semibold rounded-md flex gap-4 items-center"
          clickHandler={handleSubmitForm}
          icon={<Camera className="text-white" size={18} />}
          isLoading={loadingSubmitButton}
        />
      </div>
    </div>
  );
}
