import React from "react";
import { Filter, Search, Eye } from "lucide-react";
import { FormInput } from "../../../utility/twElems/input/input";
import { ButtonComp } from "../../../utility/twElems/Button/button";

import { TableComp } from "../../../utility/twElems/table/table";

const headers = [
  "Camera Name",
  "Camera Building",
  "Location",
  "Floor",
  "Section",
  "Model Running",
];

const data = [
  {
    "Camera Name": "camera name",
    "Camera Building": "camera name",
    Location: "camera name",
    Floor: "camera name",
    Section: "camera name",
    "Model Running": "active",
  },
  {
    "Camera Name": "camera name",
    "Camera Building": "camera name",
    Location: "camera name",
    Floor: "camera name",
    Section: "camera name",
    "Model Running": "active",
  },
];

export default function ViewPageCameraSettings({
  handleSwitchToCameraSettings = () => {},
}) {
  return (
    <div
      className="connect_storage_screen_container w-full h-full flex flex-col"
      onClick={handleSwitchToCameraSettings}
    >
      <h6 class="font-manrope text-xl font-bold text-left text-blue-900">
        Camera Settings
      </h6>

      <p class="font-manrope text-sm font-normal leading-5 text-left mb-4">
        Create and set model to your camera{" "}
      </p>
      <div className="flex items-center gap-4">
        <div className="w-4/12 flex items-center gap-2 bg-white p-3 rounded-lg shadow">
          <Search className="text-slate-300" />
          <div className="flex-1">
            <FormInput
              important={false}
              placehldr="Search Camera Name/ Camera ID"
              className="bg-transparent"
            />
          </div>
        </div>
        <ButtonComp
          label="Filter"
          className="bg-blue-900 text-white p-4 font-manrope font-semibold rounded-md flex gap-4"
          clickHandler={() => console.log("clicked filter")}
          icon={<Filter />}
        />
      </div>

      <div className="">
        <TableComp
          headers={headers}
          data={data}
          actions={{
            "Model Running": {
              action: (info) => {
                return (
                  <ButtonComp
                    className="bg-green-600 text-white p-2 font-manrope font-semibold rounded-md flex gap-4 mx-auto"
                    clickHandler={() => {
                      //   handleEditCameraByTable(info);
                    }}
                    icon={<Eye size={18} />}
                  />
                );
              },
            },
          }}
        />
      </div>
    </div>
  );
}
