// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectElementWrppr_attndnc_mngment .inp_page_ai_model_form_inp_wrppr{
    width: 21rem;
    flex: none;
}

.inp_page_ai_model_form_inp_wrppr ul{
    left: -3%;
}

.date_range_bar_wrppr_attendance_mngmnt{
    background: #E6EAF0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
    margin: 1rem 0rem;
    border-radius: .5rem;
}
.date_range_bar_date_selector_wrppr{
    max-width: -moz-fit-content;
    max-width: fit-content;
    display: flex;
    gap: 1rem;
    max-height: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/attendanceMngmntScrn/attendanceMngmntScrn.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,2BAAsB;IAAtB,sBAAsB;IACtB,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".selectElementWrppr_attndnc_mngment .inp_page_ai_model_form_inp_wrppr{\n    width: 21rem;\n    flex: none;\n}\n\n.inp_page_ai_model_form_inp_wrppr ul{\n    left: -3%;\n}\n\n.date_range_bar_wrppr_attendance_mngmnt{\n    background: #E6EAF0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: .5rem 1rem;\n    margin: 1rem 0rem;\n    border-radius: .5rem;\n}\n.date_range_bar_date_selector_wrppr{\n    max-width: fit-content;\n    display: flex;\n    gap: 1rem;\n    max-height: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
