// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adjust_sel_opt_list .inp_page_ai_model_form_inp_wrppr{
    width: 19rem;
    flex: none;
}

.sel_drp_dwn_container{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.adjust_sel_opt_list ul{
  width: 103%;
  left: -2%;
}

.usr_mngmnt_view_usr_switch_btn_wrppr{
    gap: 1rem;
    max-height: 2.5rem;
    margin-top: 1rem;
}

.usr_mngmnt_view_usr_switch_btn_wrppr button{
    flex: auto;
    padding: 0.1rem 0rem;
    border-radius: 5px;
}

.usr_mngmnt_view_usr_switch_btn_wrppr p{
    width: 100%;
    margin: 0.1rem 0;
    padding: 0;
}

/* ----------------------------- COMMON FILTER LABEL CSS ------------------------------ */

.commn_filtr_cont_wrppr{
    display: flex;
    gap: .3rem;
    align-items: end;
    margin: 2rem 0 3rem 0;
}

.common_filter_list_view_usr_usrmngmnt{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;
    max-height: 2rem;
    max-width: 83%;
}

.more_link_commn_filtr_cont_list{
    color: var(--Primary-Color, #05306b);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/UserManagementViewUsrScreen/UserManagementViewUsrScreen.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;EACE,WAAW;EACX,SAAS;AACX;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,UAAU;AACd;;AAEA,yFAAyF;;AAEzF;IACI,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":[".adjust_sel_opt_list .inp_page_ai_model_form_inp_wrppr{\n    width: 19rem;\n    flex: none;\n}\n\n.sel_drp_dwn_container{\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n}\n\n.adjust_sel_opt_list ul{\n  width: 103%;\n  left: -2%;\n}\n\n.usr_mngmnt_view_usr_switch_btn_wrppr{\n    gap: 1rem;\n    max-height: 2.5rem;\n    margin-top: 1rem;\n}\n\n.usr_mngmnt_view_usr_switch_btn_wrppr button{\n    flex: auto;\n    padding: 0.1rem 0rem;\n    border-radius: 5px;\n}\n\n.usr_mngmnt_view_usr_switch_btn_wrppr p{\n    width: 100%;\n    margin: 0.1rem 0;\n    padding: 0;\n}\n\n/* ----------------------------- COMMON FILTER LABEL CSS ------------------------------ */\n\n.commn_filtr_cont_wrppr{\n    display: flex;\n    gap: .3rem;\n    align-items: end;\n    margin: 2rem 0 3rem 0;\n}\n\n.common_filter_list_view_usr_usrmngmnt{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 1rem;\n    overflow: hidden;\n    max-height: 2rem;\n    max-width: 83%;\n}\n\n.more_link_commn_filtr_cont_list{\n    color: var(--Primary-Color, #05306b);\n    text-align: center;\n    font-family: Manrope;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    cursor: pointer;\n    width: fit-content;\n    height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
