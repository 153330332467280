export const NavBarOptions = {
  CCTV: {
    slang: "cctv",
    subOptn: {
      "All Camera": "allCam",
      "My Camera": "myCam",
      Recordings: "recordg",
    },
  },
  Connections: {
    slang: "connection",
    subOptn: {
      "Connect Storage": "connStrg",
      "Connect Camera": "connCam",
      "Camera Settings":"connCamSet"
    },
  },
  Storage: {
    slang: "cloudstrg",
    subOptn: {},
  },
  ["AI Modeling"]: {
    slang: "aimodl",
    subOptn: {
      Input: "inppgaimdl",
      ["Job Page"]: "jobpgaimdl",
    },
  },
  ["Add Employee"]: {
    slang: "addemply",
    subOptn: {
      "Input Page": "inppgaddemply",
      "User Details Page": "usrdtlpgaddemply",
    },
  },
  Attendance: {
    slang: "attendance",
    subOptn: {
      "Attendance Management": "attndncmngmnt",
      "Attendance Setting": "attndncsettngs",
    },
  },
  ["User Management"]: {
    slang: "usrmngmnt",
    subOptn: {
      "Create User": "usrmngmntcrtusr",
      "User View": "usrmngmntusrview",
    },
  },
  ["Image Annotation"]: {
    slang: "annotationimg",
    subOptn: {},
  },
};
