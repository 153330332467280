import "./modals.css";
import { MdOutlineClose } from "react-icons/md";
import { Checkbox } from "@mui/material";
import {
  FormSelectInp,
  RadioBtnInpFaceDetection,
} from "../formElements/formElements";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { FaDownload } from "react-icons/fa6";
import { useState } from "react";
import { toast } from "react-toastify";
import { FileSyncButton } from "../button/button";
import { useDropzone } from "react-dropzone";
import { addEmployee } from "../../action/action";

export function ExportTypeModalForAIModeling() {
  const [selectedOptList, setSelectedOptList] = useState([]);

  function handleChangeSelectInput(elem) {
    let alreadyStored = false;
    selectedOptList?.forEach((x) => {
      if (x?.id === elem[0]?.id) alreadyStored = true;
    });
    if (alreadyStored) {
      toast.warn("already selected model !");
    } else {
      setSelectedOptList([...selectedOptList, elem[0]]);
    }
  }

  function handleDeleteSelectedInput(elem) {
    setSelectedOptList((prevList) => {
      let newList = prevList?.filter((e) => e?.id !== elem?.id);
      return newList;
    });
  }

  return (
    <>
      <div className="exportTypeModalFAIM_main_cont">
        <div className="exportTypeModalFAIM_head_cont">
          <p className="exportTypeModalFAIM_head">View Storage Access</p>
          <MdOutlineClose className="exportTypeModalFAIM_close_icon" />
        </div>
        <div className="exportTypeModalFAIM_output_table_cont">
          {/* ------------------ TABLE CONTENT ------------------ */}

          <table>
            <thead>
              <tr>
                <th className="exportTypeModalFAIM_head_th">
                  <div className="exportTypeModalFAIM_head_para_wrppr">
                    <p className="exportTypeModalFAIM_head_para">Head 1</p>
                  </div>
                </th>
                <th className="exportTypeModalFAIM_head_th">
                  <div className="exportTypeModalFAIM_head_para_wrppr">
                    <p className="exportTypeModalFAIM_head_para">Head 2</p>
                  </div>
                </th>
                <th className="exportTypeModalFAIM_head_th">
                  <div className="exportTypeModalFAIM_head_para_wrppr">
                    <p className="exportTypeModalFAIM_head_para">Head 3</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="exportTypeModalFAIM_head_body">
              {Array(4)
                .fill(" ")
                .map((e, i) => {
                  return (
                    <tr key={`exportTypeModalFAIM_body_row_${i}`}>
                      <td className="exportTypeModalFAIM_output_type_para_col">
                        My Output
                      </td>
                      <td
                      //   key={`cell-${rowIndex}-${cellIndex}`}
                      // style={{ width: `50px` }}
                      >
                        <Checkbox
                        // checked={selectedUserList?.includes(e.id)}
                        // onChange={() => {
                        //   handleSingleSelectUser(e);
                        // }}

                        // sx={{
                        //   color: "#00AFEF",
                        //   "&.Mui-checked": { color: "#00AFEF" },
                        // }}
                        />
                      </td>
                      <td
                      // style={{ width: `50px` }}
                      >
                        <Checkbox
                        // checked={selectedUserList?.includes(e.id)}
                        // onChange={() => {
                        //   handleSingleSelectUser(e);
                        // }}

                        // sx={{
                        //   color: "#00AFEF",
                        //   "&.Mui-checked": { color: "#00AFEF" },
                        // }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {/* ------------------ TABLE CONTENT ------------------ */}
        </div>
        <div className="exportTypeModalFAIM_value_added_model_cont">
          <p className="exportTypeModalFAIM_head">Value Added Models</p>
          <div className="selectedLabelContOutputModal">
            {selectedOptList?.map((e, i) => {
              return (
                <SelectedModelLabelForOutputModal
                  title={e?.name}
                  handleDelete={() => {
                    console.log("clicking handle delete...");
                    handleDeleteSelectedInput(e);
                  }}
                  key={`selected_modal_label_${i}`}
                />
              );
            })}
          </div>
          <OutputModalWrpprForSelectInp>
            <FormSelectInp
              title={""}
              placeHldr={"Select Pre-Train Model"}
              multiSel={false}
              handlChange={(e) => {
                console.log("handle change running...", e);
                handleChangeSelectInput(e);
              }}
              optionList={[
                { name: "model 1", id: 1 },
                { name: "model 2", id: 2 },
                { name: "model 3", id: 3 },
                { name: "model 4", id: 4 },
              ]}
              selProdList={[]}
              initialLabelDropDown={"Select Model"}
              error={false && "Please select a model to continue"}
            />
          </OutputModalWrpprForSelectInp>
        </div>
        <p className="exportTypeModalFAIM_head">Training Input Method</p>
        <RadioBtnInpFaceDetection
          label="Training Input Method"
          radioList={[
            {
              value: "add_employee",
              name: "employee",
              label: "add employee",
            },
          ]}
          handleChange={(x) =>
            console.log("handle change triggered --->", x?.target?.value)
          }
          // selectedValue={selRadioVal}
        />
        <RadioBtnInpFaceDetection
          label="Select Folder Path"
          radioList={[
            {
              value: "C:UsersADMINDocumentsDownloads",
              name: "path",
              label: "C:UsersADMINDocumentsDownloads",
            },
          ]}
          handleChange={(x) =>
            console.log("handle change triggered path --->", x?.target?.value)
          }
          // selectedValue={selRadioVal}
        />
        <div className="inp_page_ai_modal_submit_btnWrppr exportTypeModalFAIM_output_modal_btn_wrppr">
          <FileSyncButton
            title="Save"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
            handleClick={() => console.log("clicked save button...")}
          />
          <FileSyncButton
            title="Cancel"
            stylObj={{
              background: "#DCEBFF",
              color: "#05306B",
              // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
            handleClick={() => console.log("clicked cancel button...")}
          />
        </div>
      </div>
    </>
  );
}

function OutputModalWrpprForSelectInp({ children }) {
  return <div className="outputModalWrpprSelectInp">{children}</div>;
}

function SelectedModelLabelForOutputModal({
  title = "my title",
  handleDelete = () => {},
}) {
  return (
    <div className="outputModalSelectedModalLabel">
      <p>{title}</p>
      <RiDeleteBinLine
        onClick={handleDelete}
        color="#FF004D"
        style={{ cursor: "pointer", fontSize: "1.3rem" }}
      />
    </div>
  );
}

const FileSvgIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.595 15.6971H8.375"
      stroke="#05306B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.595 11.9371H8.375"
      stroke="#05306B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.13 8.17712H8.375"
      stroke="#05306B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0174 7.848C19.1754 4.104 16.8164 2.75 12.0004 2.75C5.70737 2.75 3.60938 5.063 3.60938 12C3.60938 18.937 5.70737 21.25 12.0004 21.25C18.2944 21.25 20.3914 18.937 20.3914 12"
      stroke="#05306B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function UserBulkUploadModalAddEmployeeScrn({ closeModal = () => {} }) {
  const [uploadedDataList, setUploadedDataList] = useState([]);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadedData,setUploadedData]=useState({})

  async function onDrop(e) {
    console.log("getting data on drop =========>", e);
    let formData = new FormData(),
      dataArr = [];

    setIsLoadingUpload(true);

    e?.forEach((x) => {
      formData.append("file", x || "");
      // formData.append("file_type", x?.type || "");
      // formData.append("model_name", "model name");
      // formData.append("action_type", 1);
      // formData.append("file_size", x?.size || 0);

      let dataObj = {
        file: x,
        file_type: x?.type || "",
        model_name: "model name",
        action_type: 1,
        file_size: x?.size || 0,
      };
      dataArr?.push(dataObj);
    });
    const dataArrString = JSON.stringify(dataArr);
    setUploadedData(dataArr?.[0]);
    setIsLoadingUpload(false);
  }

  const uploadData = async (data = []) => {
    setIsLoadingUpload(true);
    let formData = new FormData();
    formData.append("file",data?.file);
    let response = undefined;
    let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name,
    myCookie = localStorage?.getItem(acc_token_key_name);
    response = await addEmployee(formData,myCookie);

    console.log("uploaded data response ======>", response);
    if (response?.success) {
        setIsLoadingUpload(false);
        closeModal()
      // getAllUploadedData();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    directory: true, // Allow dropping directories
  });
  return (
    <>
      <div className="usr_bulk_upld_mdl_add_emply_scrn">
        <div className="usr_bulk_upld_mdl_add_emply_scrn_head_cont">
          <h2 className="usr_bulk_upld_mdl_add_emply_scrn_heading">
            User Bulk Upload
          </h2>
          <button
            className="usr_bulk_upld_mdl_add_emply_scrn_close_btn"
            onClick={closeModal}
          >
            <IoCloseOutline />
          </button>
        </div>
        <div className="usr_bulk_upld_mdl_add_emply_scrn_excl_dwnld_cont">
          <div className="usr_bulk_upld_mdl_add_emply_scrn_file_icon_cont">
            {FileSvgIcon}
          </div>
          <div className="usr_bulk_upld_mdl_add_emply_scrn_other_detl_cont">
            <h3>Add-Users.xls</h3>
            <p>Download this template file.</p>
          </div>
          <button className="usr_bulk_upld_mdl_add_emply_scrn_dwnld_btn">
            <FaDownload />
          </button>
        </div>
        <div className="usr_bulk_upld_mdl_add_emply_scrn_drp_excl_file_cont">
          {/* drop-zone container */}

          <div className="dropzoneWrpprFD">
            <div {...getRootProps()} className="dropzoneDashBorder">
              <input {...getInputProps()} />
              <button className="dropzoneUpldIconFD">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 5.25C10.0137 5.25 8.28809 6.17871 7.05469 7.54688C6.95215 7.53516 6.86133 7.5 6.75 7.5C5.10059 7.5 3.75 8.85059 3.75 10.5C2.45215 11.291 1.5 12.627 1.5 14.25C1.5 16.7256 3.52441 18.75 6 18.75H9.75V17.25H6C4.33594 17.25 3 15.9141 3 14.25C3 13.0049 3.75586 11.9414 4.82812 11.4844L5.34375 11.2734L5.27344 10.7109C5.25879 10.5879 5.25 10.5176 5.25 10.5C5.25 9.66211 5.91211 9 6.75 9C6.85547 9 6.97266 9.01465 7.10156 9.04688L7.57031 9.16406L7.85156 8.78906C8.8125 7.55566 10.3125 6.75 12 6.75C14.458 6.75 16.5088 8.44043 17.0859 10.7109L17.2266 11.2969L17.8594 11.2734C18.0176 11.2646 18.0527 11.25 18 11.25C19.6641 11.25 21 12.5859 21 14.25C21 15.9141 19.6641 17.25 18 17.25H14.25V18.75H18C20.4756 18.75 22.5 16.7256 22.5 14.25C22.5 11.8828 20.6338 9.9873 18.3047 9.82031C17.3877 7.18066 14.9443 5.25 12 5.25ZM12 11.25L9 14.25H11.25V20.25H12.75V14.25H15L12 11.25Z"
                    fill="#05306B"
                  />
                </svg>
              </button>
              <p>
                {uploadedData?.file?.name ? uploadedData?.file?.name:isDragActive
                  ? "Drop files here..."
                  : "Drag / click to upload files or folders here"}
                <br />
                <span>Click Here to Upload / Choose Folder Path</span>
              </p>
            </div>
          </div>

          {/* drop-zone container */}
        </div>
        <div className="usr_bulk_upld_mdl_add_emply_scrn_cncl_save_btn_cont">
          <div className="selStorageModalActvArea_btnWrppr justify_end">
            <FileSyncButton 
            handleClick={()=>{
              uploadData(uploadedData)
            }}
              title="Upload"
              stylObj={{
                boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            />
            <FileSyncButton
              title="Cancel"
              stylObj={{
                background: "white",
                color: "#05306B",
                // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
