import './attendanceMngmntScrn.css';

import React, { useState, useEffect } from 'react';
import FormInpWrpprFaceDetection from '../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection';
import { FormSelectInp } from '../../../utility/formElements/formElements';
import { FileSyncButton } from '../../../utility/button/button';
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { Checkbox } from '@mui/material';
import { fetchEmployeesData, getAttendanceDataByEmpID } from '../../../action/action';


const InitialDataJobDetl = {
    name: "",
    job_description: "",
    job_type: "pre_trained_model",
    pre_trend_model: "",
    selected_camera: [],
    selected_stored_files: [],
    access_type: "",
};

const usersList = [
    { id: 1, name: "user 1" },
    { id: 2, name: "user 2" },
];

// ------------------------ ATTENDANCE MANAGEMENT SCREEN -----------------------------

const headersCamTable = [
    "Week Days",
    "Absent/Present",
    "Check In",
    "Check Out",
    "History",
    "Total Hours"
]

const columnMinWidths = [10, 10, 10, 10, 10, 10];

const myArr = [
    {
        "week_days": "Mon 24",
        "attendance": "Absent",
        "check_in": "10:00 AM",
        "check_out": "10:00 AM",
        "history": "",
        "total_hours": "09:00 Hrs"
    },
    {
        "week_days": "Mon 24",
        "attendance": "Absent",
        "check_in": "10:00 AM",
        "check_out": "10:00 AM",
        "history": "",
        "total_hours": "09:00 Hrs"
    },
    {
        "week_days": "Mon 24",
        "attendance": "Absent",
        "check_in": "10:00 AM",
        "check_out": "10:00 AM",
        "history": "",
        "total_hours": "09:00 Hrs"
    }
];

// ------------------------ ATTENDANCE MANAGEMENT SCREEN -----------------------------

function AttenDanceMngmntScrn() {
    const [selLabelSelDrpDwn, setSelLabelSelDrpDwn] = useState(
        "Search by Employee ID / Employee Name"
    );
    const [selLabelSelDrpDwnAccTyp, setSelLabelSelDrpDwnAccTyp] =
        useState("Select Access Type");

    const [formDataJobDetl, setFormDataJobDetl] = useState({
        ...InitialDataJobDetl,
    });

    const [formDataErrorObj, setFormDataErrorObj] = useState({
        name: "",
        job_description: "",
        job_type: "",
        pre_trend_model: "",
        selected_camera: "",
        selected_stored_files: "",
        access_type: "",
    });
    const [selItemList, setSelItemList] = useState([]);
    const [selItemListAccTyp, setSelItemListAccTyp] = useState([]);
    // setEmplyData
    const [emplyData, setEmplyData] = useState([]);
    const [attendanceList, setAttendanceList] = useState([]); // ----------------- attendance data of employee
    const [attendanceType, setAttendanceType] = useState("weekly"); // monthly
    const [selelemListData, setSelelemListData] = useState([{ id: "", name: "" }]);

    function updateAttendanceType(t = "") {
        console.log("setting attendance type ==>", t);
        setAttendanceType(t);
    }

    function handleAddModelSel(e) {
        console.log("getting select values ==>", e[0]);
        setSelItemList([{ ...e[0] }]);
        // setAddStorageDetl({ ...addStorageDetl, type: e[0]?.name });
        setSelLabelSelDrpDwn(e[0]?.name);
        setFormDataJobDetl({ ...formDataJobDetl, pre_trend_model: e[0]?.name });
    }

    function handleAddModelSelAccTyp(e) {
        console.log("getting select values access types ==>", e[0]);
        setSelItemListAccTyp([{ ...e[0] }]);
        // setAddStorageDetl({ ...addStorageDetl, type: e[0]?.name });
        setSelLabelSelDrpDwnAccTyp(e[0]?.name);
        setFormDataJobDetl({ ...formDataJobDetl, access_type: e[0]?.name });
    }

    function convertToSelListData(arr = []) {
        let data = arr.map((e) => {
            /*
            id: 1
            organization: null
            username: "gaurav"
            */
            //    { id: 1, name: "user 1" }
            return { id: e?.employee_id, name: e?.username }
        })
        console.log("converted data list ---->", data);
        setSelelemListData([...data]);
    }

    // ------------------- attendance data updating ---------------

    useEffect(() => {
        async function getEmpAttendanceByID(d) {
            let resp = await getAttendanceDataByEmpID({ emp_id: d?.id, filter: attendanceType }); // monthly
            console.log("employee attendance response ===", resp);
            if (resp?.success) {
                console.log("employee attendance data ===>", resp.data?.data?.data);
                let dataList = resp.data?.data?.data || [];
                setAttendanceList([...dataList]);
            }
        }
        selItemList[0] && getEmpAttendanceByID(selItemList[0]);
    }, [selItemList, attendanceType]);

    // ------------------- attendance data updating ---------------

    useEffect(() => {
        async function getEmployeesData() {
            let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name,
                myCookie = localStorage?.getItem(acc_token_key_name);
            let resp = await fetchEmployeesData(myCookie);
            if (resp?.success) {
                // console.log(
                //   "my employees data 24244244242 ========>",
                //   resp?.data?.data?.data
                // );
                let data = resp?.data?.data?.data;
                setEmplyData([...data]);
                convertToSelListData([...data]);
            }
        }
        getEmployeesData();
        /*
    biometric_id: ""
    company_name: "marktine"
    contact_num: "9999999999"
    email: "gaurav.surolia@marktine.com"
    employee_id: null
    id: 1
    organization: null
    username: "gaurav"
        */
    }, []);
    return <>
        <div className="connect_storage_screen_container">
            <h2>Attendance Management</h2>
            <p className="connect_storage_screen_container_p">
                Manage your employee attandance{" "}
            </p>
            <div className="selectElementWrppr_attndnc_mngment">
                <FormInpWrpprFaceDetection>
                    <FormSelectInp
                        title={"Search by Employee ID / Employee Name"}
                        placeHldr={"Search by Employee ID / Employee Name"}
                        multiSel={false}
                        handlChange={handleAddModelSel}
                        optionList={selelemListData} // selelemListData usersList
                        selProdList={selItemList}
                        initialLabelDropDown={selLabelSelDrpDwn}
                        error={
                            formDataErrorObj?.pre_trend_model &&
                            "Please select a model to continue"
                        }
                        unableSearch={true}
                    />
                </FormInpWrpprFaceDetection>
            </div>
            <DateRangeBar handleUpdateDateFilter={updateAttendanceType} dateFilter={attendanceType} attendanceList={attendanceList} selectedEmployee={selItemList} />
            <AttendanceTable headers={headersCamTable} columnMinWidths={columnMinWidths} data={attendanceList} selectedEmployee={selItemList} />
        </div>
    </>
}

export default AttenDanceMngmntScrn;

function DateRangeBar({ handleUpdateDateFilter = () => { }, dateFilter = "weekly", attendanceList = [],selectedEmployee=[] }) {
    const [daterangeStr, setDaterangeStr] = useState("Opp's no data...")
    /*
    {
            "date": "Fri 01",
            "year": "2024",
            "month": "March",
            "present_status": "Absent",
            "check_in_time": "",
            "check_out_time": "",
            "total_hours": "00:00 Hrs"
        }

        "24 Mar 2024 - 29 Mar 2024"
    */
    useEffect(() => {
        function convertToDaterangeStr() {
            let lastIndex = attendanceList.length - 1;
            let fromDateStr = attendanceList[0]?.date?.split(" ")[1]+ " " + attendanceList[0]?.month?.substring(0, 3) + " " + attendanceList[0]?.year,
                toDateStr = attendanceList[lastIndex]?.date?.split(" ")[1] + " " + attendanceList[lastIndex]?.month?.substring(0, 3) + " " + attendanceList[lastIndex]?.year;
            console.log("tostr ===>", fromDateStr);
            console.log("split value ===>",attendanceList[0]?.date?.split(" "));
            setDaterangeStr(fromDateStr + " - " + toDateStr);
        }
        selectedEmployee[0]&&convertToDaterangeStr();
    }, [attendanceList]);
    return <>
        <div className='date_range_bar_wrppr_attendance_mngmnt'>
            <div className="selStorageModalActvArea_btnWrppr">
                <FileSyncButton
                    title="Weekly"
                    stylObj={

                        dateFilter === "weekly" ?
                            {
                                boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                                color: "white",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                            }
                            :
                            {
                                background: "white",
                                color: "#05306B",
                                // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                            }

                    }
                    handleClick={() => handleUpdateDateFilter("weekly")}
                />
                <FileSyncButton
                    title="Monthly"
                    stylObj={dateFilter === "monthly" ?
                        {
                            boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                            color: "white",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                        }
                        :
                        {
                            background: "white",
                            color: "#05306B",
                            // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                        }}
                    handleClick={() => handleUpdateDateFilter("monthly")}
                />
            </div>
            <p className='date_range_bar_text_date'>{
                daterangeStr
            }</p>
            <div className='date_range_bar_date_selector_wrppr'>
                <FileSyncButton
                    title=""
                    stylObj={{
                        background: "white",
                        color: "#05306B",
                        // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                    }}
                >
                    <FaRegArrowAltCircleLeft />
                </FileSyncButton>
                <FileSyncButton
                    title="Today"
                    stylObj={{
                        boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                    }}
                />
                <FileSyncButton
                    title=""
                    stylObj={{
                        background: "white",
                        color: "#05306B",
                        // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                    }}
                >
                    <FaRegArrowAltCircleRight />
                </FileSyncButton>
            </div>
        </div>
    </>
}

function AttendanceTable({
    headers = [],
    data = [],
    columnMinWidths = [],
    handleSelRow = () => { },
    currentSelRow,
    handleActStrg = () => { },
    handleSendMail = () => { },
    selectedRow = [],
    handleSelAllEmp = () => { },
    handleSelSinglEmp = () => { },
    selectedEmployee = []
}) {

    /*
    {
            "date": "Fri 01",
            "year": "2024",
            "month": "March",
            "present_status": "Absent",
            "check_in_time": "",
            "check_out_time": "",
            "total_hours": "00:00 Hrs"
        }
    */

    return <>
        <table>
            <thead className="mtHeadFDFI">
                <tr>
                    {/* <th style={{ width: `3%` }}>
                        <Checkbox
                            sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
                        onChange={handleSelectedAll}
                        checked={selectedRow?.length === data?.length}
                        />
                    </th> */}
                    {headers?.map((title, index) => (
                        <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                            {title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI file_status_for_job_page_table">
                {
                    selectedEmployee[0] || data[0] ?
                        data?.map((row, rowIndex) => (
                            <tr
                                key={`row-${rowIndex}`}
                                className={`datasetTableRowFDFI`}
                            >
                                <td>{row?.date || "--"}</td>
                                <td>{row?.present_status || "--"}</td>
                                <td>{row?.check_in_time || "--"}</td>
                                <td>{row?.check_out_time || "--"}</td>
                                <td>
                                    <FaEye style={{ cursor: "pointer" }} />
                                </td>
                                <td>{row?.total_hours || "--"}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <p style={{fontWeight:500,textAlign:"center"}}>Opps no Data, Please select employee from the list...</p>
                        </tr>
                }
            </tbody>
        </table>
    </>
}

/*



*/