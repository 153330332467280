import "./attendanceSettingScrn.css";

import React, { useState, useRef, useEffect } from "react";

import { Calendar } from "../../../shadcnComponents/ui/calendar";
import { CalendarIcon, ChevronDown } from "lucide-react";

import { GrAdd } from "react-icons/gr";
import { MdRemove } from "react-icons/md";

import { Dropdown } from "../../../utility/twElems/dropDown/dropDown";
import { ButtonComp } from "../../..//utility/twElems/Button/button";
import { FormInput } from "../../../utility/twElems/input/input";

import classNames from "classnames";

function AttendanceSettingScrn() {
  const [holidayList, setHolidayList] = useState([]);

  function handleAddNewHolidayToList() {
    setHolidayList((prev) => {
      return [...prev, { label: "", date: "", id: `holiday_id_${Date.now()}` }];
    });
  }

  function handleRemoveHolidayByID(id) {
    setHolidayList((prev) => {
      let newLIst = prev.filter((elem) => elem.id !== id);
      return newLIst;
    });
  }

  return (
    <>
      <div className="connect_storage_screen_container">
        <h6 class="font-manrope text-xl font-bold text-left text-blue-900">
          Attendance Settings
        </h6>

        <p class="font-manrope text-sm font-normal leading-5 text-left mb-4">
          Manage your employee attendance settings{" "}
        </p>

        <div className="attendance_fe_settings_page_form_section flex flex-col gap-5">
          <div className="attendace_fe_settings_page_form_inp_wrapper ">
            <FormSectionLabel text="Set office hours" />
            <div className="flex gap-2">
              <div className="flex-1">
                <FormInput
                  label={"Check In Time"}
                  important={true}
                  placehldr="Enter Time"
                />
              </div>
              <div className="flex-1">
                <FormInput
                  label={"Check Out Time"}
                  important={true}
                  placehldr="Enter Time"
                />
              </div>
            </div>
          </div>
          <div className="attendace_fe_settings_page_form_inp_wrapper ">
            <div className="flex gap-2">
              <div className="flex-1">
                <FormSectionLabel text="Set Camera for Attendance" />
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Dropdown
                      label="Check-In-Camera"
                      placehldr="Select Camera For Check-In"
                      options={[
                        "option-1",
                        "option-2",
                        "option-3",
                        "option-4",
                        "option-1",
                        "option-2",
                        "option-3",
                        "option-4",
                      ]}
                    />
                  </div>
                  <div className="flex-1">
                    <Dropdown
                      label="Check-Out-Camera"
                      placehldr="Select Camera For Check-Out"
                      options={["option-1", "option-2", "option-3", "option-4"]}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <FormSectionLabel text="Create Co-Admin" />
                <Dropdown
                  label="Co-Admin"
                  placehldr="Select Co-Admin From List"
                  options={["option-1", "option-2", "option-3", "option-4"]}
                />
              </div>
            </div>
          </div>
          <div className="attendace_fe_settings_page_form_inp_wrapper ">
            <div className="flex gap-2">
              <div className="flex-1">
                <FormSectionLabel text="Set late time criteria" />
                <FormInput
                  label="Please Add Late Criteria After Check In Time"
                  placehldr="Exp:- 30min"
                />
              </div>
              <div className="flex-1">
                <FormSectionLabel text="Enable email notification" />
                <SwitchField label="Enable Auto email notification sending to people for being late" />
              </div>
            </div>
          </div>
          <div className="attendace_fe_settings_page_form_inp_wrapper ">
            <FormSectionLabel text="Set office hours" />
            <div className="flex flex-col gap-4">
              {holidayList?.map((holidayElem, indx) => (
                <AddHolidayFieldComp
                  key={holidayElem.id}
                  handleField={() => {
                    handleRemoveHolidayByID(holidayElem.id);
                  }}
                  fieldIndex={indx + 1}
                  removable={true}
                />
              ))}
              <AddHolidayFieldComp
                handleField={handleAddNewHolidayToList}
                fieldIndex={holidayList.length + 1}
                removable={false}
              />
            </div>
          </div>
          <div className="attendace_fe_settings_page_form_inp_wrapper">
            <FormSectionLabel text="Set working days" />
            <div className="flex flex-col gap-4 w-1/2">
              <SwitchField label="Monday" />
              <SwitchField label="Tuesday" />
              <SwitchField label="Wednesday" />
              <SwitchField label="Thursday" />
              <SwitchField label="Friday" />
              <SwitchField label="Saturday" />
              <SwitchField label="Sunday" />
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <ButtonComp
            label="Reset"
            bgColor="bg-blue-100"
            textColor="text-blue-900"
          />
          <ButtonComp
            label="Save"
            bgColor="bg-blue-900"
            textColor="text-white"
          />
        </div>
      </div>
    </>
  );
}

export default AttendanceSettingScrn;

function FormSectionLabel({ text = "" }) {
  return (
    <p class="font-manrope font-bold text-base leading-[21.86px] text-left text-blue-900 mb-3">
      {text}
    </p>
  );
}

// const FormInput = ({ label = "", important = true, placehldr = "" }) => {
//   const [inputValue, setInputValue] = useState("");

//   return (
//     <div className="bg-white p-3 rounded-lg shadow">
//       {label && (
//         <label className="block mb-2 text-xs font-normal leading-tight text-left font-manrope text-blue-700">
//           {label} {important && <span className="text-red-500">*</span>}
//         </label>
//       )}
//       <input
//         type="text"
//         value={inputValue}
//         onChange={(e) => setInputValue(e.target.value)}
//         className="w-full px-1 py-1 focus:outline-none font-manrope text-sm placeholder-gray-400 text-[#2D2D2D]"
//         placeholder={placehldr}
//       />
//     </div>
//   );
// };

const SwitchField = ({ label = "" }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    console.log("runnign handle change");
    setChecked(event.target.checked);
  };
  return (
    <div className="flex bg-white p-3 rounded-lg shadow justify-between">
      <label className="font-manrope font-semibold text-xs leading-[16.39px] text-left text-blue-900">
        {label}
      </label>
      <TailwindSwitch
        checked={checked}
        onChange={handleChange}
        onColor="bg-blue-900"
        offColor="bg-slate-300"
      />
    </div>
  );
};

const TailwindSwitch = ({ onColor, offColor, checked, onChange, disabled }) => {
  const baseClasses =
    "relative inline-block w-12 h-6 rounded-full cursor-pointer transition-colors shadow-md";
  const thumbClasses =
    "absolute w-6 h-6 rounded-full bg-white border-2 border-transparent transition-transform";

  const trackClasses = classNames(
    "absolute left-0 w-full h-full rounded-full transition-colors",
    {
      "bg-gray-300 dark:bg-gray-600": !checked,
      [offColor]: !checked,
      [onColor]: checked,
      "opacity-50": disabled,
    }
  );

  const inputElem = useRef(null);

  function handleClickWrapper() {
    inputElem.current && inputElem.current?.click();
  }

  return (
    <div className={baseClasses} onClick={handleClickWrapper}>
      <input
        type="checkbox"
        className="sr-only" //
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        ref={inputElem}
      />
      <div className={trackClasses} />
      <div
        className={classNames(thumbClasses, {
          "transform translate-x-full": checked,
        })}
      />
    </div>
  );
};

// const ButtonComp = ({ label = "", bgColor = "", textColor = "" }) => {
//   return (
//     <button className={` px-8 py-3  ${bgColor} ${textColor} rounded-md`}>
//       {label}
//     </button>
//   );
// };

const AddHolidayFieldComp = ({
  handleField = () => {},
  removable = false,
  fieldIndex = 1,
}) => {
  const [displayCal, setDisplayCal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const calendarWrapper = useRef(null);
  const calendarIconButton = useRef(null);

  function handleCalendarDisplay() {
    setDisplayCal((prev) => {
      return !prev;
    });
  }

  function handleSelectDate(date) {
    setSelectedDate(date);
  }

  useEffect(() => {
    function handleCheckCalendarClick(event) {
      let target = event.target,
        check =
          calendarWrapper.current &&
          !calendarWrapper.current.contains(target) &&
          !calendarIconButton.current.contains(target);
      if (check) {
        setDisplayCal(false);
      }
    }
    document.addEventListener("click", handleCheckCalendarClick);
    return () => {
      document.removeEventListener("click", handleCheckCalendarClick);
    };
  }, []);
  return (
    <>
      <div className="flex gap-2 items-end relative">
        <span className="font-sans text-base font-normal text-left">
          {fieldIndex}.
        </span>
        <div className="flex-1">
          <FormInput label={""} important={true} placehldr="Holiday name" />
        </div>
        <div className="flex-1 relative">
          <FormInput label={""} important={true} placehldr="Date" />
          <CalendarIcon
            className="absolute right-2 top-4 cursor-pointer"
            onClick={handleCalendarDisplay}
            ref={calendarIconButton}
          />
        </div>
        <div
          className={`flex-1 absolute w-1/2 bg-white right-0 top-16 z-10 ${
            displayCal ? "" : "hidden"
          }`}
          ref={calendarWrapper}
        >
          <Calendar
            mode="single"
            selected={selectedDate}
            onSelect={handleSelectDate}
            className="rounded-md border"
          />
        </div>
        <button
          className={`shadow rounded-full relative bottom-2 ${
            !removable ? "bg-lime-500" : "bg-red-500"
          } cursor-pointer size-8 flex justify-center items-center`}
          onClick={handleField}
        >
          {removable ? (
            <MdRemove className="text-white text-xl" />
          ) : (
            <GrAdd className="text-white text-xl" />
          )}
        </button>
      </div>
    </>
  );
};
