import "./header.css";
import { MyContext } from "../../context/context";
import { useContext, useEffect } from "react";
import { FileSyncButton } from "../../utility/button/button";
import { useNavigate } from "react-router-dom";

function Header() {
  const { user } = useContext(MyContext);

  const navigate = useNavigate();
  
  return (
    <>
      {/* <div className="header_wrppr_face_recognition"> */}
      {/* ********************************************************** */}

      <div className="header_main_face_recognition">
        <div className="header_face_recog_log_out_button">
          <FileSyncButton
            title={"Log out"}
            handleClick={() => {
              localStorage.removeItem("FACEDETECTIONACCTOKEN");
              navigate("/login");
            }}
          />
        </div>
        <img src={user?.userData?.data?.profile_image||"/images/Ellipse 35.svg"} alt="user" />
        <p>{user?.userData?.data?.username || "User Name"}</p>
      </div>

      {/* ********************************************************** */}
      {/* </div> */}
    </>
  );
}

export default Header;
