import React from "react";
import { toast } from "react-toastify";
// import { useParams } from "react-router-dom";
import { Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  AiFillCaretDown,
  AiFillEyeInvisible,
  AiFillEye,
  AiOutlineClose,
  // AiFillPlusCircle,
  // AiFillMinusCircle,
} from "react-icons/ai";
import { IoCloseCircle } from "react-icons/io5";
import { useState, useEffect, useRef, useCallback } from "react";

import "./formElements.css";

export function FormInp({
  title,
  error,
  nameStr,
  handlChange,
  placeHldr,
  typ = "text",
  value = "",
  isDisable = false,
}) {
  return (
    <div className="adminSignupFormInpWrppr">
      <p className="adminSignupFormInpLable">{title}</p>
      <div className="adminSignupFormInpElemWrppr">
        <input
          type={typ}
          className="adminSignupFormInpElem"
          name={nameStr}
          onChange={(e) => handlChange(e)}
          placeholder={placeHldr}
          value={value}
          disabled={isDisable}
        />
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
}

export function FormPassInp({
  title,
  error,
  nameStr,
  handlChange,
  placeHldr,
  value,
}) {
  const [visiblty, setVisiblty] = useState(false);
  return (
    <div className="adminSignupFormInpWrppr">
      <p className="adminSignupFormInpLable">{title}</p>
      <div className="adminSignupFormPassInpWrppr">
        <input
          type={visiblty ? "text" : "password"}
          className="adminSignupFormInpElem"
          name={nameStr}
          onChange={(e) => handlChange(e)}
          placeholder={placeHldr}
          value={value}
        />
        {/* <img
            src="./assets/img/visibilityIcon.svg"
            className="adminSignupFormVisbIcon"
            alt="eye"
            onClick={() => setVisiblty(!visiblty)}
          /> */}
        <div
          className="adminSignupFormVisbIcon"
          onClick={() => setVisiblty(!visiblty)}
        >
          {visiblty ? <AiFillEyeInvisible /> : <AiFillEye />}
        </div>
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
}

export const FormSelectInp= React.memo(function FormSelectInp({
  title = "",
  error,
  // nameStr,
  handlChange,
  // placeHldr,
  // idStr = "",
  optionList = [],
  selProdList = [],
  initialLabelDropDown = "",
  multiSel = true,
  disabled = false,
  unableSearch = false,
}) {
  // const [selOptions, setSelOptions] = useState([]);

  function handleSelectedOptions(value) {
    console.log("getting value in select arr list------->", value);
    console.log("getting in select arr product list------->", selProdList);
    if (selProdList?.some((x) => x?.id === value?.id)) {
      let newArr = selProdList?.filter((e) => value?.id !== e?.id);
      handlChange(newArr);
      // setSelOptions([...newArr]);
    } else {
      if (multiSel) {
        handlChange([...selProdList, value]);
      } else {
        handlChange([{ ...value }]);
      }
      // setSelOptions([...selOptions, value]);
    }
  }

  function handleRemoveSelOpt(value) {
    let newArr = selProdList?.filter((e) => value?.id !== e?.id);
    handlChange(newArr);
    // setSelOptions([...newArr]);
  }

  // console.log("getting options list --->",optionList);
  return (
    <div className="adminSignupFormInpWrppr">
      {title && <p className="adminSignupFormInpLable">{title}</p>}
      <div className="adminSignupFormInpElemWrppr adminSignupFormSelectElemWrppr">
        <CustomSelectDropDown
          optList={optionList}
          handleSelOptions={(v) => {
            handleSelectedOptions(v);
          }}
          multi={multiSel}
          selectedArrList={selProdList}
          initialLabel={initialLabelDropDown}
          disabled={disabled}
          unableSearch={unableSearch}
        />
        {multiSel
          ? selProdList?.length > 0 && (
              <div className="custSelectedProdLableListWrppr">
                {selProdList?.map((e) => (
                  <CustomSelProdLabel
                    title={e?.name}
                    handleRemSelOpt={() => {
                      handleRemoveSelOpt(e);
                    }}
                  />
                ))}
              </div>
            )
          : ""}
      </div>
      {error && <p className="adminSignupFormInpErrLable">{error}</p>}
    </div>
  );
})



export function CustomSelectDropDown({
  optList = [],
  handleSelOptions,
  multi = false,
  selectedArrList = [],
  initialLabel = "Select Options",
  disabled = false,
  unableSearch = false,
}) {
  const [drpDwnMenu, setDrpDwnMenu] = useState(false);
  // const [searchFilterVal, setSearchFilterVal] = useState("");
  const [filteredOptList, setFilteredOptList] = useState(optList);

  const selDrpDwnCont = useRef(null);

  // console.log("selected arr list -------->", selectedArrList);
  // console.log("multi select prop: -------->", multi);

  function handleSelect(value) {
    // console.log("selected value ---->", value);
    handleSelOptions(value);
    !multi && setDrpDwnMenu(false);
  }

  function handleClickOutSide(e) {
    let target = e.target;
    if (selDrpDwnCont?.current && !selDrpDwnCont?.current.contains(target)) {
      // console.log("clicked outside ------->");
      setDrpDwnMenu(false);
    }
  }

  function handleClickSelectBtn(e) {
    e.preventDefault();
    // console.log("button clicked------->");
    if (!(optList?.length > 0)) {
      toast.warn("no list to select from");
      return;
    }
    setDrpDwnMenu(!drpDwnMenu);
  }

  function handleChangeSearchFilter(val = "") {
     let newFilteredArr=[];
    if(val){
      newFilteredArr = optList.filter((e) => e?.name?.includes(val)).map(item=>item?.name)
    }
    else{
      newFilteredArr = optList.map((e) => {
      if (e?.name?.includes(val)) {
        return e?.name;
      }
    });
  }
    setFilteredOptList([...newFilteredArr]);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);

    // if(unableSearch){
    // }

      function updateFilterOptList(){
        let newfilteredList = optList?.map((e)=>{
          return e?.name;
        });
        newfilteredList&&setFilteredOptList([...newfilteredList]);
      }updateFilterOptList();


    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [optList]);

  return (
    <>
      <div className="customSelDrpDwnWrppr" ref={selDrpDwnCont}>
        <button
          className="customSelDrpDwnBtn"
          onClick={handleClickSelectBtn}
          disabled={disabled}
        >
          {initialLabel} <AiFillCaretDown style={{ marginLeft: "5px" }} />
        </button>
        {drpDwnMenu && optList?.length > 0 ? (
          <ul className={"customSelDrpDwnMenu"}>
            { unableSearch &&
              <input
              type="text"
              placeholder="search here..."
              className="search_filter_sel_drop_down"
              onChange={(e) =>
                handleChangeSearchFilter(e?.target?.value || "")
              }
              // value={searchFilterVal}
              key={"customSelDrpDwnOptDNDK_search"}
            />}
            {optList?.map((e, i) => {
              return (
                
                  <li
                    className={`customSelDrpDwnOpt ${
                      multi
                        ? ""
                        : selectedArrList?.some((x) => x?.id === e?.id)
                        ? "actvCustmSelDrpDwn"
                        : ""
                    }`}
                    onClick={() => handleSelect(e)}
                    key={`customSelDrpDwnOptDNDK${i}`}
                    style={{
                      display: `${
                        filteredOptList?.includes(e?.name) ? "flex" : "none"
                      }`
                    }}
                  >
                    {e?.name}
                    {/* {selectedArrList?.some(x=>x?.id === e?.id) ? (
                      <AiFillMinusCircle color="#00AFEF" />
                    ) : (
                      <AiFillPlusCircle color="#00AFEF" />
                    )} */}
                    {multi && (
                      <Checkbox
                        checked={selectedArrList?.some((x) => x?.id === e?.id)}
                        sx={{
                          color: "#00AFEF",
                          "&.Mui-checked": { color: "#00AFEF" },
                        }}
                      />
                    )}
                  </li>
                
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export function CustomSelProdLabel({
  title,
  handleRemSelOpt,
  onlyLabel = false,
}) {
  function handleClose(e) {
    e.preventDefault();
    console.log("remove this product...", title);
    handleRemSelOpt();
  }
  return (
    <button className="customSelProdLabel">
      {title}{" "}
      {!onlyLabel && (
        <AiOutlineClose
          onClick={handleClose}
          className="closeBtnCustomSelProdLabel"
        />
      )}
    </button>
  );
}

// *********************************************  FACE DETECTION FORM INPUTS *********************************************

export function RadioBtnInpFaceDetection({
  label = "my_label",
  radioList = [{ value: "", name: "", label: "" }],
  handleChange = () => {},
  selectedValue = "",
}) {
  return (
    <div className="radio_btn_inp_facedet_main_cont">
      <p>{label}</p>
      <div className="forminp_radio_btn_main_wrppr">
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedValue}
          onChange={handleChange}
          sx={{
            flexDirection: "row",
            gap: "1rem",
            ".css-j204z7-MuiFormControlLabel-root": {
              margin: 0,
            },
          }}
        >
          {radioList?.map((e, i) => (
            <FormControlLabel
              value={e?.value}
              control={
                <Radio
                  sx={{
                    color: "#BEDAFF",
                    // background:"#BEDAFF",
                    "&.Mui-checked": {
                      color: "#05306B",
                    },
                  }}
                />
              }
              label={e?.label}
              key={`SJCSECNEIN_JNECNWEI@3#$${i + 323}`}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}

export function SelectOptionWithModalPopup({
  mainHeadLabel = "My Label",
  handleModalDisplay = () => {},
  modalDisplay = false,
  seletedItemList,
  error = "",
  children,
  removeLable = (e) => {
    console.log("remove my lable", e);
  },
}) {
  // const [showModalPopup, setShowModalPopup] = useState(false);

  function handleModalPopup(x = true) {
    // console.log("setting showModal state to : -------->", x);
    // setShowModalPopup(x);
    handleModalDisplay(x);
  }

  useEffect(() => {
    if (modalDisplay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalDisplay]);
  return (
    <>
      <div
        className="sel_opt_with_modl_popup_main_cont"
        onClick={() => handleModalPopup(true)}
      >
        {modalDisplay ? (
          <div
            className="modal_popup_wrppr_sel_opt_with_modl_popup"
            onClick={(e) => {
              // console.log("handle click popup modal ------>");
              e?.stopPropagation();
              handleModalPopup(false);
            }}
          >
            {children}
          </div>
        ) : (
          ""
        )}
        <p className="sel_opt_with_modl_popup_para">{mainHeadLabel}</p>
        <div className="sel_opt_with_modl_popup_selected_optn_wrppr">
          {seletedItemList?.map((e) => (
            <SelectedOptionCardType1
              label={e?.myLabel}
              removeLable={(x) => {
                // x?.stopPropagation(); // comp...
                removeLable(e);
              }}
            />
          ))}

          {/* <SelectedOptionCardType1 label="option2" /> */}
        </div>
      </div>
      {error && <p className="select_option_with_modal_error_para">{error}</p>}
    </>
  );
}

export function SelectedOptionCardType1({
  label = "",
  removeLable = () => {},
}) {
  return (
    <div className="sel_opt_card_typ1_main_cont">
      <p>{label}</p>
      <IoCloseCircle onClick={removeLable} />
    </div>
  );
}

// *********************************************  FACE DETECTION FORM INPUTS *********************************************
