import "./App.css";
// import FileUploader from './fileUpload/fileUploader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { MyContextProvider } from "./context/context";

// *************************** ROUTES ***************************

import {
  DemoRoute,
  Components,
  FaceRecognitionApp,
  IpCamWebSocket,
  AllCamTestComponent,
  ImageCoordinatesCoordinates,
  ProtectedAnnotationScreen,
} from "./routes/routes";

import LoginSignup from "./components/login_signup/login_signup";
import ExtractFaceCaptureScrnWrppr from "./components/screens/extractFaceCaptureScrnWrppr/extractFaceCaptureScrnWrppr";
import Accordion from "./components/screens/logScreen/logScreen";

// *************************** ROUTES ***************************

function App() {
  return (
    <BrowserRouter>
      <MyContextProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<FaceRecognitionApp />} />
            <Route path="/comp" element={<Components />} />
            <Route path="/test" element={<DemoRoute />} />
            <Route path="/websocket_ipcam" element={<IpCamWebSocket />} />
            <Route path="/test_multi_cam" element={<AllCamTestComponent />} />
            <Route path="/logs" element={<Accordion />} />
            <Route
              path="/test_image_coord"
              element={<ImageCoordinatesCoordinates />}
            />
            <Route path="/" element={<Outlet />}>
              <Route path="login" element={<LoginSignup toRender="login" />} />
              <Route
                path="register"
                element={<LoginSignup toRender="register" />}
              />
              <Route
                path="reset_password/:token"
                element={
                  <LoginSignup
                    toRender="reset_pass"
                    actToCall="reset_password"
                  />
                }
              />
            </Route>
            <Route
              path="/add_employee"
              element={<ExtractFaceCaptureScrnWrppr />}
            />
            <Route
              path="/img_annotation"
              element={<ProtectedAnnotationScreen />}
            />
          </Routes>
          <ToastContainer />
        </div>
      </MyContextProvider>
    </BrowserRouter>
  );
}

export default App;
