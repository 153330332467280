import "./login_signup.css";

import { useRef, useState } from "react";
import FormInpWrpprFaceDetection from "../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";
import { FormInp, FormPassInp } from "../../utility/formElements/formElements";
import { FileSyncButton } from "../../utility/button/button";

import {
  createNewOrganisation,
  loginUser,
  resetPassword,
  forgotPassword,
} from "../../action/action";

import LoadingComponent from "../../utility/loadingSpinner/loadingSpinner";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";

function LoginSignup({ toRender = "register", actToCall = "" }) {
  const navigate = useNavigate(),
    { token } = useParams();

  async function handleActionToCall(d) {
    console.log("switch case route path", actToCall);
    switch (actToCall) {
      case "reset_password": {
        console.log("reset password by forgot pass link ======>", token);
        let resp = await resetPassword(d, token);
        return resp;
        // break;
      }

      default:
        break;
    }
  }

  return (
    <div className="login_signup_page_wrppr_FD">
      <div className="login_signup_form_cont_wrppr">
        {toRender === "register" && (
          <RegisterFormComponent
            handleCompSwitch={() => {
              console.log("click span login");
              navigate("/login");
            }}
          />
        )}
        {toRender === "login" && (
          <LoginFormComponent
            handleCompSwitch={() => {
              navigate("/register");
            }}
          />
        )}
        {toRender === "reset_pass" && (
          <ResetPassFormComponent
            handleCompSwitch={() => {
              navigate("/login");
            }}
            actionToCall={(d) => handleActionToCall(d)}
          />
        )}
      </div>
    </div>
  );
}

export default LoginSignup;

function RegisterFormComponent({ handleCompSwitch = () => {} }) {
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    contact_number: "",
    employee_id:"",
    org_details: "",
    password: "",
    confirm_password: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    contact_number: "",
    employee_id:"",
    org_details: "",
    password: "",
    confirm_password: "",
  });

  const [registerUserLoading, setRegisterUserLoading] = useState(false);

  function handleChangeInput(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setFormDetails({ ...formDetails, [name]: value });
  }

  function checkRequiredFields() {
    let newFormErrorObj = {},
      isOkay = true;
    for (const key in formDetails) {
      // console.log(`form details key:${key} and value:${formDetails[key]}`);
      if (!formDetails[key]) {
        newFormErrorObj[key] = 1;
        isOkay = false;
      }
    }
    // console.log("form error object ===>", newFormErrorObj);
    setFormErrors({ ...newFormErrorObj });
    return isOkay;
  }

  async function handleSubmitDetails() {
    let checkVal = checkRequiredFields();
    // console.log("form details ====>", formDetails);
    // console.log("form Errors ====>", formErrors);
    if (!checkVal) return;

    let data = {
      name: formDetails?.name || "",
      email: formDetails?.email || "",
      password: formDetails?.password || "",
      confirm_password: formDetails?.confirm_password || "",
      mobile: formDetails?.contact_number || "",
      employee_id:formDetails?.employee_id||"",
      company_name: formDetails?.org_details || "",
    };

    setRegisterUserLoading(true);

    let resp = await createNewOrganisation(data);

    setRegisterUserLoading(false);

    if (resp?.success) {
      // console.log(
      //   "response data paylaod create new organisation ===>",
      //   resp?.data?.data
      // );
      setFormDetails({
        name: "",
        email: "",
        contact_number: "",
        org_details: "",
        password: "",
        employee_id:"",
        confirm_password: "",
      });
      handleCompSwitch();
      toast.success("user created!");
    } else {
      toast.error("failed creating user");
    }
  }
  return (
    <div className="login_form_component_FD">
      <h1 className="login_form_component_FD_head">Register</h1>
      <p className="login_form_component_FD_para">
        Enter following details to access your account
      </p>
      <div className="login_form_component_inp_wrppr_inline">
        <FormInpWrpprFaceDetection styleObj={{ background: "white" }}>
          <FormInp
            title={"Name"}
            placeHldr={"Enter Your Name"}
            nameStr={"name"}
            value={formDetails?.name}
            handlChange={handleChangeInput}
            error={formErrors?.name && "Field Required, can't leave it empty !"}
          />
        </FormInpWrpprFaceDetection>
        <FormInpWrpprFaceDetection styleObj={{ background: "white" }}>
          <FormInp
            title={"Email"}
            placeHldr={"Enter Your Email"}
            nameStr={"email"}
            value={formDetails?.email}
            handlChange={handleChangeInput}
            error={
              formErrors?.email && "Field Required, can't leave it empty !"
            }
          />
        </FormInpWrpprFaceDetection>
      </div>
      <FormInpWrpprFaceDetection
        styleObj={{ maxWidth: "100%", background: "white" }}
      >
        <FormInp
          title={"Contact Number"}
          placeHldr={"Enter Your Contact Details"}
          nameStr={"contact_number"}
          value={formDetails?.contact_number}
          handlChange={handleChangeInput}
          error={
            formErrors?.contact_number &&
            "Field Required, can't leave it empty !"
          }
        />
      </FormInpWrpprFaceDetection>
       <FormInpWrpprFaceDetection
        styleObj={{ maxWidth: "100%", background: "white" }}
      >
        <FormInp
          title={"Employee ID"}
          placeHldr={"Enter Your Employee Id"}
          nameStr={"employee_id"}
          value={formDetails?.employee_id}
          handlChange={handleChangeInput}
          error={
            formErrors?.employee_id &&
            "Field Required, can't leave it empty !"
          }
        />
      </FormInpWrpprFaceDetection>
      <FormInpWrpprFaceDetection
        styleObj={{ maxWidth: "100%", background: "white" }}
      >
        <FormInp
          title={"Organisation Details"}
          placeHldr={"Enter Your Organisation Details"}
          nameStr={"org_details"}
          value={formDetails?.org_details}
          handlChange={handleChangeInput}
          error={
            formErrors?.org_details && "Field Required, can't leave it empty !"
          }
        />
      </FormInpWrpprFaceDetection>
      <div className="login_form_component_inp_wrppr_inline">
        <FormInpWrpprFaceDetection styleObj={{ background: "white" }}>
          <FormPassInp
            title={"Password"}
            nameStr={"password"}
            handlChange={handleChangeInput}
            placeHldr={"Enter Your Password"}
            error={
              formErrors?.password && "Field Required, can't leave it empty !"
            }
            value={formDetails?.password}
          />
        </FormInpWrpprFaceDetection>
        <FormInpWrpprFaceDetection styleObj={{ background: "white" }}>
          <FormPassInp
            title={"Confirm Password"}
            nameStr={"confirm_password"}
            handlChange={handleChangeInput}
            placeHldr={"Confirm Your Password"}
            error={
              formErrors?.confirm_password &&
              "Field Required, can't leave it empty !"
            }
            value={formDetails?.confirm_password}
          />
        </FormInpWrpprFaceDetection>
      </div>
      <div
        className="inp_page_ai_modal_submit_btnWrppr"
        style={{ maxHeight: "3rem" }}
      >
        <FileSyncButton
          title="SignUp"
          stylObj={{
            color: "#05306B",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            background: "#E7EAEE",
          }}
          handleClick={handleSubmitDetails}
          disabled={registerUserLoading}
        >
          {registerUserLoading ? (
            <div className="loadingSpinner_wrppr_conn_strg">
              <LoadingComponent
                styl={{
                  bottom: "0px",
                  right: "0px",
                  position: "relative",
                }}
                size={"14px"}
              />
            </div>
          ) : (
            ""
          )}
        </FileSyncButton>
      </div>

      <h6>
        Do you have an account?<span onClick={handleCompSwitch}>Login</span>
      </h6>
    </div>
  );
}

function LoginFormComponent({ handleCompSwitch = () => {} }) {
  const [forgotPassModalDisplay, setForgotPassModalDisplay] = useState(false);
  const [loadinguserLogin,setLoadinguserLogin] = useState(false);
  const loginBtn = useRef(null);
  // const loadinguserLogin = useRef(false);

  const [formDetails, setFormDetails] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  function handleChangeInput(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setFormDetails({ ...formDetails, [name]: value });
  }

  function checkRequiredFields() {
    let newFormErrorObj = {},
      isOkay = true;
    for (const key in formDetails) {
      // console.log(`form details key:${key} and value:${formDetails[key]}`);
      if (!formDetails[key]) {
        newFormErrorObj[key] = 1;
        isOkay = false;
      }
    }
    // console.log("form error object ===>", newFormErrorObj);
    setFormErrors({ ...newFormErrorObj });
    return isOkay;
  }

  async function handleSubmitDetails() {
    let checkVal = checkRequiredFields();
    // console.log("form details ====>", formDetails);
    // console.log("form Errors ====>", formErrors);

    if (!checkVal) return;
    // let data = new FormData();

    // data?.append("email", formDetails?.email);
    // data?.append("password", formDetails?.password);

    let data = {
      email: formDetails?.email,
      password: formDetails?.password,
    };
    loginBtn.current.disabled = true; 
    // loadinguserLogin.current = true;
    setLoadinguserLogin(true);
    console.log("my login btn ===>", loginBtn.current?.disabled);
    let resp = await loginUser(data);
    loginBtn.current.disabled = false;
    // loadinguserLogin.current = false;
    setLoadinguserLogin(false);
    console.log("loadinguser login 111111===>", loadinguserLogin.current);
    console.log("my login btn afer request===>", loginBtn.current?.disabled);
    if (resp?.success) {
      toast.success("login success");
      // console.log("login success response ===>", resp?.data?.data);

      let access_token = resp?.data?.data?.access_token,
        acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name;

      localStorage?.setItem(acc_token_key_name, access_token);
      setFormDetails({ email: "", password: "" });
      navigate("/");
    } else {
      toast.error("login failed");
    }
  }
  return (
    <div className="login_form_component_FD">
      {forgotPassModalDisplay && (
        <ForgotPasswordPopupComp
          handleModalDisplay={() => setForgotPassModalDisplay(false)}
        />
      )}
      <h1 className="login_form_component_FD_head">LogIn</h1>
      <p className="login_form_component_FD_para">
        Enter following details to access your account
      </p>
      <FormInpWrpprFaceDetection
        styleObj={{ maxWidth: "100%", background: "white" }}
      >
        <FormInp
          title={"Email"}
          placeHldr={"Enter Your Email"}
          nameStr={"email"}
          value={formDetails?.email}
          handlChange={handleChangeInput}
          error={formErrors?.email && "Field Required, can't leave it empty !"}
        />
      </FormInpWrpprFaceDetection>

      <FormInpWrpprFaceDetection
        styleObj={{ maxWidth: "100%", background: "white" }}
      >
        <FormPassInp
          title={"Password"}
          nameStr={"password"}
          handlChange={handleChangeInput}
          placeHldr={"Enter Your Password"}
          error={
            formErrors?.password && "Field Required, can't leave it empty !"
          }
          value={formDetails?.password}
        />
      </FormInpWrpprFaceDetection>

      <h6
        className="forgot_pass_link_FD"
        onClick={() => setForgotPassModalDisplay(true)}
      >
        Forgot Password?
      </h6>

      <div
        className="inp_page_ai_modal_submit_btnWrppr"
        style={{ maxHeight: "3rem" }}
      >
        <FileSyncButton
          title="Login"
          stylObj={{
            // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            color: "#05306B",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            background: "#E7EAEE",
          }}
          handleClick={handleSubmitDetails}
          btnRef={loginBtn}
        >
        {loadinguserLogin ? (
            <div className="loadingSpinner_wrppr_conn_strg">
              <LoadingComponent
                styl={{
                  bottom: "0px",
                  right: "0px",
                  position: "relative",
                }}
                size={"14px"}
              />
            </div>
          ) : (
            ""
          )}
        </FileSyncButton>
      </div>
      <h6>
        Don't have an account yet?
        <span onClick={handleCompSwitch}>Sign Up</span>
      </h6>
    </div>
  );
}

function ForgotPasswordPopupComp({ handleModalDisplay = () => {} }) {
  const [formDetails, setFormDetails] = useState({
    email: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
  });

  function handleChangeInput(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setFormDetails({ ...formDetails, [name]: value });
  }

  function checkRequiredFields() {
    let newFormErrorObj = {},
      isOkay = true;
    for (const key in formDetails) {
      console.log(`form details key:${key} and value:${formDetails[key]}`);
      if (!formDetails[key]) {
        newFormErrorObj[key] = 1;
        isOkay = false;
      }
    }
    console.log("form error object ===>", newFormErrorObj);
    setFormErrors({ ...newFormErrorObj });
    return isOkay;
  }

  async function handleSubmitDetails() {
    console.log("form details ====>", formDetails);
    console.log("form Errors ====>", formErrors);

    let check = checkRequiredFields();

    // console.log("before check forgot password");

    if (!check) return;

    // console.log("after check forgot password");

    let resp = await forgotPassword(formDetails?.email);

    if (resp?.success) {
      console.log("forgot password response ====>", resp?.data);
      setFormDetails({ email: "" });
      setFormErrors({ email: "" });
      toast.success("request sent for forgot password");
      handleModalDisplay(false);
    } else {
      toast.error("failed forgot password request");
    }
  }
  return (
    <div
      className="forgot_pass_modal_wrppr"
      onClick={() => handleModalDisplay(false)}
    >
      <div
        className="forgot_pass_modal_cont"
        onClick={(e) => e?.stopPropagation()}
      >
        <h1>Forgot Password</h1>
        <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
          <FormInp
            title={"Email"}
            placeHldr={"Enter Your Email For Forgot Password Link"}
            nameStr={"email"}
            value={formDetails?.email}
            handlChange={handleChangeInput}
            error={
              formErrors?.email && "Field Required, can't leave it empty !"
            }
          />
        </FormInpWrpprFaceDetection>
        <div
          className="inp_page_ai_modal_submit_btnWrppr"
          style={{ maxHeight: "3rem" }}
        >
          <FileSyncButton
            title="Send Mail"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
            handleClick={handleSubmitDetails}
          />
        </div>
      </div>
    </div>
  );
}

function ResetPassFormComponent({
  handleCompSwitch = () => {},
  actionToCall = async () => {},
}) {
  const [formDetails, setFormDetails] = useState({
    // name: "",
    // email: "",
    // contact_number: "",
    // org_details: "",
    password: "",
    confirm_password: "",
  });

  const [formErrors, setFormErrors] = useState({
    // name: "",
    // email: "",
    // contact_number: "",
    // org_details: "",
    password: "",
    confirm_password: "",
  });

  const [resetPassLoading, setResetPassLoading] = useState(false);

  function handleChangeInput(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setFormDetails({ ...formDetails, [name]: value });
  }

  function checkRequiredFields() {
    let newFormErrorObj = {},
      isOkay = true;
    for (const key in formDetails) {
      // console.log(`form details key:${key} and value:${formDetails[key]}`);
      if (!formDetails[key]) {
        newFormErrorObj[key] = 1;
        isOkay = false;
      }
    }
    // console.log("form error object ===>", newFormErrorObj);
    setFormErrors({ ...newFormErrorObj });
    return isOkay;
  }

  async function handleSubmitDetails() {
    let checkVal = checkRequiredFields();
    // console.log("form details ====>", formDetails);
    // console.log("form Errors ====>", formErrors);
    if (!checkVal) return;

    let data = {
      // name: formDetails?.name || "",
      // email: formDetails?.email || "",
      password: formDetails?.password || "",
      confirm_password: formDetails?.confirm_password || "",
      // mobile: formDetails?.contact_number || "",
      // company_name: formDetails?.org_details || "",
    };

    setResetPassLoading(true);

    let resp = await actionToCall(data);

    setResetPassLoading(false);

    if (resp?.success) {
      console.log("response data paylaod reset password ===>", resp?.data);
      setFormDetails({
        // name: "",
        // email: "",
        // contact_number: "",
        // org_details: "",
        password: "",
        confirm_password: "",
      });
      handleCompSwitch();
      toast.success("password reset!");
    } else {
      console.log("error reset password", resp?.data);
      let msg = resp?.data?.message;
      if (!msg) {
        msg = "failed reseting password";
      }
      toast.error(msg);
    }
  }
  return (
    <div className="login_form_component_FD">
      <h1 className="login_form_component_FD_head">Reset Password</h1>
      <p className="login_form_component_FD_para">
        Enter following details to reset password
      </p>
      <div className="login_form_component_inp_wrppr_inline">
        <FormInpWrpprFaceDetection styleObj={{ background: "white" }}>
          <FormPassInp
            title={"Password"}
            nameStr={"password"}
            handlChange={handleChangeInput}
            placeHldr={"Enter Your Password"}
            error={
              formErrors?.password && "Field Required, can't leave it empty !"
            }
            value={formDetails?.password}
          />
        </FormInpWrpprFaceDetection>
        <FormInpWrpprFaceDetection styleObj={{ background: "white" }}>
          <FormPassInp
            title={"Confirm Password"}
            nameStr={"confirm_password"}
            handlChange={handleChangeInput}
            placeHldr={"Confirm Your Password"}
            error={
              formErrors?.confirm_password &&
              "Field Required, can't leave it empty !"
            }
            value={formDetails?.confirm_password}
          />
        </FormInpWrpprFaceDetection>
      </div>
      <div
        className="inp_page_ai_modal_submit_btnWrppr"
        style={{ maxHeight: "3rem" }}
      >
        <FileSyncButton
          title="Reset"
          stylObj={{
            color: "#05306B",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            background: "#E7EAEE",
          }}
          handleClick={handleSubmitDetails}
          disabled={resetPassLoading}
        >
          {resetPassLoading ? (
            <div className="loadingSpinner_wrppr_conn_strg">
              <LoadingComponent
                styl={{
                  bottom: "0px",
                  right: "0px",
                  position: "relative",
                }}
                size={"14px"}
              />
            </div>
          ) : (
            ""
          )}
        </FileSyncButton>
      </div>

      <h6>
        Do you have an account?<span onClick={handleCompSwitch}>Login</span>
      </h6>
    </div>
  );
}
