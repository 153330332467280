import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import "./videoHLSPlayer.css";

import { getVideoFeedByCamID } from "../../action/action";
import { toast } from "react-toastify";
import { useContext } from "react";
import { MyContext } from "../../context/context";

const VideoPlayerhlsmod = ({
  updCurrentTime,
  updDuration,
  dimension = {},
  currTimeOnSeek,
  isUserInteracted = false,
  camID,
}) => {
  const videoRef = useRef(null);
  const hlsObj = useRef(null);
  const [error, setError] = useState(null);
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const { user, mySelCamStream } = useContext(MyContext);

  useEffect(() => {
    const video = videoRef.current;
    let isComponentMounted = true;

    async function streamPlayBack() {
      const companyName = user?.userData?.data?.company_name;

      const now = new Date();
      const day = String(now.getDate()).padStart(2, "0");
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const year = now.getFullYear();
      const recordingDate = `${year}-${month}-${day}`;

      if (!companyName || !recordingDate || !camID) {
        // toast.warn("Invalid request, check details");
        console.log("Invalid request user details ===>", user?.userData?.data);
        console.log("Invalid request company ===>", companyName);
        console.log("Invalid request recording date ===>", recordingDate);
        console.log("Invalid request cam id ===>", camID);
        return;
      }

      let resp = await getVideoFeedByCamID({
        company: companyName,
        recordingDate: recordingDate,
        cam_id: camID,
      });

      if (resp.success && camID && isComponentMounted) {
        if (Hls.isSupported()) {
          const hls = new Hls({
            enableWorker: true,
            liveBackBufferLength: 0,
            liveSyncDurationCount: 1,
            liveMaxLatencyDurationCount: 10,
            liveDurationInfinity: true,
            highBufferWatchdogPeriod: 1,
          });
          hlsObj.current = hls;
          hls.loadSource(
            `${process.env.REACT_APP_AdminBaseUrl}/camera/hls/stream/vclip.m3u8`
          );
          hls.attachMedia(video);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (!isVideoLoaded && isComponentMounted) {
              setVideoLoaded(true);
            }
          });
          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal && isComponentMounted) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  setError(
                    "Network error occurred. Please check your internet connection."
                  );
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  setError("Media error occurred. Please try again later.");
                  break;
                default:
                  setError(
                    "An unexpected error occurred. Please try again later."
                  );
                  break;
              }
            }
          });

          video.addEventListener("loadedmetadata", () => {
            if (isComponentMounted && video) {
              video.currentTime = video.duration;
              updCurrentTime(video.currentTime);
            }
          });

          video.addEventListener("timeupdate", () => {
            if (isComponentMounted && video) {
              updCurrentTime(video.currentTime);
            }
          });

          video.addEventListener("durationchange", () => {
            if (isComponentMounted && video) {
              updDuration(video.duration);
            }
          });

          return () => {
            hls.destroy();
          };
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
          video.src = `${process.env.REACT_APP_AdminBaseUrl}/camera/hls/stream/vclip.m3u8`;
          video.addEventListener("loadedmetadata", () => {
            if (!isVideoLoaded && isComponentMounted) {
              setVideoLoaded(true);
              if (video) {
                video.currentTime = video.duration;
                updCurrentTime(video.currentTime);
              }
            }
          });

          video.addEventListener("timeupdate", () => {
            if (isComponentMounted && video) {
              updCurrentTime(video.currentTime);
            }
          });

          video.addEventListener("durationchange", () => {
            if (isComponentMounted && video) {
              updDuration(video.duration);
            }
          });
        }
      } else {
        // toast.error("Error loading playback stream");
        console.log("Error loading playback stream");
      }
    }
    streamPlayBack();

    return () => {
      isComponentMounted = false;
      if (videoRef.current) {
        videoRef.current.pause();
      }
      if (hlsObj.current) {
        hlsObj.current.destroy();
      }
    };
  }, [camID]);

  useEffect(() => {
    if (currTimeOnSeek !== null && videoRef.current) {
      videoRef.current.currentTime = currTimeOnSeek;
      videoRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }
  }, [currTimeOnSeek]);

  useEffect(() => {
    if (videoRef.current) {
      if (!isUserInteracted) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      }
    }
  }, [isUserInteracted]);

  return (
    <div>
      {error ? (
        <div
          style={{
            width: dimension?.width,
            height: dimension?.height,
            color: "white",
            background: "grey",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Error: {error}
        </div>
      ) : (
        <video
          ref={videoRef}
          width={dimension?.width}
          height={dimension?.height}
          style={{ background: "grey", objectFit: "fill" }}
          className={
            mySelCamStream?.selCameraFullScreen
              ? "video_player_hls_full_screen"
              : ""
          }
        ></video>
      )}
    </div>
  );
};

export default VideoPlayerhlsmod;
