// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  position: relative;
}

.progress-bar__filled {
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
}

.progress_bar_current_status {
  height: 100%;
  background-color: white;
  border-radius: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/utility/progressBar/progressBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".progress-bar {\n  width: 100%;\n  height: 10px;\n  background-color: #ddd;\n  border-radius: 5px;\n  position: relative;\n}\n\n.progress-bar__filled {\n  height: 100%;\n  background-color: #007bff;\n  border-radius: 5px;\n}\n\n.progress_bar_current_status {\n  height: 100%;\n  background-color: white;\n  border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
