export function faceDetectionCoordToBoundingBox(
  coordinates = {},
  originalImageWidth = 0,
  originalImageHeight = 0
) {
  let myDivList = [];
  for (const key in coordinates) {
    let name = key,
      // [y1, x2, y2, x1] = coordinates[key]; // [tl-y,br-x,br-y,tl-x]
      [x1, y1, x2, y2] = coordinates[key]; // [tl-x,tl-y,br-x,br-y]

    name = name.split("_")[0];

    // console.log("extracted name =======>", name);
    // console.log(
    //   `original image dimensions ===>  width:  ${originalImageWidth}`,
    //   "color:blue; font-size:20px;"
    // );
    // console.log(
    //   `original image dimensions ===>  height:  ${originalImageHeight}`,
    //   "color:brown; font-size:20px;"
    // );
    // console.log(
    //   `original image dimensions ===>  y1:  ${y1}`,
    //   "color:purple; font-size:20px;"
    // );

    const width = Math.abs(x2 - x1);
    const height = Math.abs(y2 - y1);
    const left = (x1 / originalImageWidth) * 100;
    const top = (y1 / originalImageHeight) * 100;
    const paraTop = top - 3;
    const paraLeft = left;

    // console.log("width: ===>", width);
    // console.log("height: ===>", height);
    // console.log("left: ===>", left);
    // if (left > 0 && left < 100) {
    //   let outputColor = "color:green; font-size:20px;";
    //   console.log(`%c left:    ====>${left}`, outputColor);
    // } else {
    //   let outputColor = "color:red; font-size:20px;";
    //   console.log(`%c left:    ====>${left}`, outputColor);
    // }
    // console.log("top: ===>", top);
    // if (top > 0 && top < 100) {
    //   let outputColor = "color:yellow; font-size:20px;";
    //   console.log(`%c top:    ====>${top}`, outputColor);
    // } else {
    //   let outputColor = "color:pink; font-size:20px;";
    //   console.log(`%c top:    ====>${top}`, outputColor);
    // }
    // console.log("paraTop: ===>", paraTop);
    // console.log("paraLeft: ===>", paraLeft);

    const overlayStyle = {
        position: "absolute",
        left: left + "%",
        top: top + "%",
        width: (width / originalImageWidth) * 100 + "%",
        height: (height / originalImageHeight) * 100 + "%",
        border: `2px solid ${
          name.toLowerCase() === "unknown" ? "red" : "#04AA6D"
        }`, // Adjust styling as needed
        boxSizing: "border-box",
      },
      boundingDetails = {
        name,
        style: { ...overlayStyle },
        paraTop,
        paraLeft,
      };

    myDivList.push(boundingDetails);
    // console.log("before updating div list name =====>", myDivList);
  }
  return myDivList;
}

export function objectCountCoordToBoundingBox(
  objects = {},
  originalImageWidth = 0,
  originalImageHeight = 0
) {
  /*
  "laptop": {
          "count": 1,
          "coordinates": [
            [
              1518.65087890625,
              672.632080078125,
              1846.8421630859375,
              877.5049438476562
            ]
          ]
        }
  */

  let myDivList = [];

  for (const key in objects) {
    let item_type = key,
      coordinatesList = objects[key].coordinates;
    // [x1, y1, x2, y2] = objects[key].coordinates[0]; // [tl-x,tl-y,br-x,br-y]

    coordinatesList.forEach((coordinates) => {
      const [x1, y1, x2, y2] = coordinates; // [tl-x,tl-y,br-x,br-y]

      const width = Math.abs(x2 - x1);
      const height = Math.abs(y2 - y1);
      const left = (x1 / originalImageWidth) * 100;
      const top = (y1 / originalImageHeight) * 100;
      const paraTop = top - 3;
      const paraLeft = left;

      const overlayStyle = {
          position: "absolute",
          left: left + "%",
          top: top + "%",
          width: (width / originalImageWidth) * 100 + "%",
          height: (height / originalImageHeight) * 100 + "%",
          border: `2px solid red`, // Adjust styling as needed
          boxSizing: "border-box",
        },
        boundingDetails = {
          name: item_type,
          style: { ...overlayStyle },
          paraTop,
          paraLeft,
        };

      myDivList.push(boundingDetails);
    });
  }

  return myDivList;
}
