import "./connectStorageScreen.css";

import {
  FormInp,
  FormSelectInp,
} from "../../../utility/formElements/formElements";
import { FileSyncButton, IOSSwitch } from "../../../utility/button/button";
import {
  PlusIcon,
  ConntdLink,
  DisConntdLink,
} from "../../../utility/Icons/icons";

import {
  addStorage,
  getAllStorage,
  connectStorage,
  activateStorage,
} from "../../../action/action";

import LoadingComponent from "../../../utility/loadingSpinner/loadingSpinner";
import FormInpWrpprFaceDetection from "../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";

import { useState, useEffect } from "react";
import { toast } from "react-toastify";

function ConnectStorageScreen() {
  const [selStorage, setSelStorage] = useState(null);
  const [isConnectLoading, setIsConnectLoading] = useState(false);

  const storageTypeArr = [
      { name: "iCloud", id: 1 },
      { name: "OneDrive", id: 2 },
      { name: "Google", id: 3 },
      { name: "Dropbox", id: 4 },
    ],
    initialConnStrg = {
      accKey: "",
      secAccKey: "",
      bucktName: "",
      serviceName: "",
      regionName: "",
    };

  const [selItemList, setSelItemList] = useState([]);
  const [selLabelSelDrpDwn, setSelLabelSelDrpDwn] = useState(
    "Select Storage Type"
  );
  const [addStorageDetl, setAddStorageDetl] = useState({ type: "", name: "" });
  const [connStorageDetl, setConnStorageDetl] = useState({
    ...initialConnStrg,
  });

  const [addStorageDetlErrObj, setAddStorageDetlErrObj] = useState({
    storage_name: "",
    storage_type: "",
  });
  const [connStorageDetlErrObj, setConnStorageDetlErrObj] = useState({
    storage_id: "",
    access_key: "",
    secret_access_key: "",
    bucket_name: "",
    service_name: "",
    region_name: "",
    is_active: "",
  });

  const [storageTableData, setStorageTableData] = useState([]);

  const headers = ["Storage Name", "Connection Status", "Activity Status"],
    myArr = [
      { id: 1, name: "Storage1", connectStatus: true, active: false },
      { id: 2, name: "Storage2", connectStatus: false, active: true },
      { id: 3, name: "Storage3", connectStatus: false, active: false },
    ];

  function handleAddStorageInp(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    console.log("getting inp values ==>", value, name);

    setAddStorageDetl({ ...addStorageDetl, [name]: value });
  }

  function handleConnStorageInp(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setConnStorageDetl({ ...connStorageDetl, [name]: value });
  }

  function handleAddStorageSel(e) {
    console.log("getting select values ==>", e[0]);
    setSelItemList([{ ...e[0] }]);
    setAddStorageDetl({ ...addStorageDetl, type: e[0]?.name });
    setSelLabelSelDrpDwn(e[0]?.name);
  }

  function checkEmptyFieldsForAddCamera(data) {
    let myErrObj = {},
      isOkay = true;
    for (let [key, val] of data?.entries()) {
      myErrObj[key] = val ? false : true;
      if (!val) isOkay = false;
    }

    return { myErrObj, isOkay };
  }

  async function handleAddStorage() {

    let data = new FormData();
    data.append("storage_name", addStorageDetl?.name);
    data.append("storage_type", addStorageDetl?.type);

    let { myErrObj, isOkay } = checkEmptyFieldsForAddCamera(data);
    console.log("error object ====>", myErrObj);

    setAddStorageDetlErrObj({ ...myErrObj });
    if (!isOkay) return;

    let resp = await addStorage(data);

    console.log("getting add storage response ===>", resp);
    if (resp?.success) {
      setAddStorageDetl({ name: "", type: "" });
      setSelItemList([]);
      setSelLabelSelDrpDwn("Select Storage Type");
      await fetchAllStorage();
    }
  }
  async function fetchAllStorage() {
    let resp = await getAllStorage();
    console.log("getting all storage response ===>", resp);
    if (resp?.success) {
      let data = resp?.data?.data?.paylod?.AllStorage;
      console.log("dataArr ====>", data);
      setStorageTableData([...data]);
    }
  }

  async function activateStrg(v) {
    console.log("active this =====>", v);

    let activeStatus = 0;

    storageTableData?.forEach((e) => {
      if (e?.storage_id === v?.storage_id) {
        activeStatus = v?.is_active ? 0 : 1;
      }
    });

    let data = new FormData();
    data.append("storage_id", v?.storage_id);
    data.append("storage_active_status", activeStatus);
    let resp = await activateStorage(data);

    if (resp?.success) {
      console.log("activate storage response ====>", resp?.data);
      fetchAllStorage();
    }
  }

  async function connStorage() {
    if (!selStorage?.storage_id) {
      toast.info("please select a storage");
      return;
    }

    if (selStorage?.connection_status) {
      toast.info("already connected storage");
      return;
    }

    console.log("selected strg ======>", selStorage);

    let data = new FormData();

    data.append("access_key", connStorageDetl?.accKey);
    data.append("secret_access_key", connStorageDetl?.secAccKey);
    data.append("bucket_name", connStorageDetl?.bucktName);
    data.append("service_name", connStorageDetl?.serviceName);
    data.append("region_name", connStorageDetl?.regionName);

    let { myErrObj, isOkay } = checkEmptyFieldsForAddCamera(data);
    console.log("error object ====>", myErrObj);

    setConnStorageDetlErrObj({ ...myErrObj });
    if (!isOkay) return;

    data.append("storage_id", selStorage?.storage_id);
    data.append("is_active", "0");

    setIsConnectLoading(true);

    let resp = await connectStorage(data);

    setIsConnectLoading(false);

    if (resp?.success) {
      // setConnStorageDetl({ ...initialConnStrg });

      let data = new FormData();
      data.append("storage_id", selStorage?.storage_id);
      data.append("storage_active_status", 1);

      await activateStorage(data);
      await fetchAllStorage();
    }
  }

  function handleSelRowFromTable(v) {
    console.log("selected row data", v);
    setSelStorage(v);

    setConnStorageDetl({
      accKey: v?.access_key,
      secAccKey: v?.secret_access_key,
      bucktName: v?.bucket_name,
      serviceName: v?.service_name,
      regionName: v?.region_name,
    });
    setConnStorageDetlErrObj({
      storage_id: "",
      access_key: "",
      secret_access_key: "",
      bucket_name: "",
      service_name: "",
      region_name: "",
      is_active: "",
    });
  }

  useEffect(() => {
    async function fetchAllStorage() {
      let resp = await getAllStorage();
      console.log("getting all storage response ===>", resp);

      if (resp?.success) {
        let data = resp?.data?.data?.paylod?.AllStorage;
        console.log("dataArr ====>", data);
        setStorageTableData([...data]);
        // setSelStorage({ ...data[0] });
        handleSelRowFromTable(data[0]);
      }
    }
    fetchAllStorage();
  }, []);
  return (
    <>
      {/* <div className="connect_storage_screen_wrppr"> */}
      {/* ***************************************************************** */}

      <div className="connect_storage_screen_container">
        <h2>Connect Storage</h2>
        <p className="connect_storage_screen_container_p">
          Connect your Storage with our software{" "}
        </p>
        <div className="connect_storage_screen_addstorage_form_cont align_center">
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"Storage Name"}
              placeHldr={"Enter Storage Name"}
              handlChange={handleAddStorageInp}
              nameStr={"name"}
              value={addStorageDetl?.name}
              error={
                addStorageDetlErrObj?.storage_name &&
                "Field can't be left empty"
              }
            />
          </FormInpWrpprFaceDetection>
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormSelectInp
              title={"Storage Type"}
              placeHldr={"Select Storage Type"}
              multiSel={false}
              handlChange={handleAddStorageSel}
              optionList={storageTypeArr}
              selProdList={selItemList}
              initialLabelDropDown={selLabelSelDrpDwn}
              error={
                addStorageDetlErrObj?.storage_type &&
                "Please select a storage type"
              }
            />
          </FormInpWrpprFaceDetection>
          <div onClick={handleAddStorage}>
            <MyButton label="Add Storage">{PlusIcon}</MyButton>
          </div>
        </div>
        <div className="connect_storage_screen_user_activity_area">
          <div className="connect_storage_screen_mytable_wrrpr">
            <MyStorageTable
              headers={headers}
              data={storageTableData}
              handleSelRow={(v) => handleSelRowFromTable(v)}
              currentSelRow={selStorage}
              handleActStrg={(v) => activateStrg(v)}
            />
          </div>
          <div className="connect_storage_screen_user_details_form connect_cam_screen_gaping">
            <h2>Enter Details</h2>
            <p className="connect_storage_screen_container_p">
              Connect your Storage with our software{" "}
            </p>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Access Key *"}
                placeHldr={"Enter Access Key"}
                nameStr={"accKey"}
                handlChange={(e) => handleConnStorageInp(e)}
                value={connStorageDetl?.accKey}
                error={
                  connStorageDetlErrObj?.access_key &&
                  "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Secret Access Key *"}
                placeHldr={"Enter Secret Access Key"}
                nameStr={"secAccKey"}
                handlChange={(e) => handleConnStorageInp(e)}
                value={connStorageDetl?.secAccKey}
                error={
                  connStorageDetlErrObj?.secret_access_key &&
                  "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Storage Bucket Name *"}
                placeHldr={"Enter Storage Bucket Name"}
                nameStr={"bucktName"}
                handlChange={(e) => handleConnStorageInp(e)}
                value={connStorageDetl?.bucktName}
                error={
                  connStorageDetlErrObj?.bucket_name &&
                  "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Service Name *"}
                placeHldr={"Enter Service Name"}
                nameStr={"serviceName"}
                handlChange={(e) => handleConnStorageInp(e)}
                value={connStorageDetl?.serviceName}
                error={
                  connStorageDetlErrObj?.service_name &&
                  "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Region Name *"}
                placeHldr={"Enter Region Name"}
                nameStr={"regionName"}
                handlChange={(e) => handleConnStorageInp(e)}
                value={connStorageDetl?.regionName}
                error={
                  connStorageDetlErrObj?.region_name &&
                  "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <div className="connect_storage_screen_connect_to_strg_btn_wrppr">
              <FileSyncButton title="Connect Storage" handleClick={connStorage}>
                {isConnectLoading ? (
                  <div className="loadingSpinner_wrppr_conn_strg">
                    <LoadingComponent
                      styl={{
                        bottom: "0px",
                        right: "0px",
                        position: "relative",
                      }}
                      size={"14px"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </FileSyncButton>
            </div>
          </div>
        </div>
        <div className="selStorageModalActvArea_btnWrppr">
          <FileSyncButton
            title="Save"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
          <FileSyncButton
            title="Cancel"
            stylObj={{
              background: "white",
              color: "#05306B",
              // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
        </div>
      </div>

      {/* ***************************************************************** */}
      {/* </div> */}
    </>
  );
}

export default ConnectStorageScreen;

export function MyButton({ label = "Button", children }) {
  return (
    <>
      <button
        className="sidenavigation_newcamerabutton"
        style={{ color: "white" }}
      >
        {children}
        <p style={{ color: "white" }}>{label}</p>
      </button>
    </>
  );
}

export function MyTable({
  headers = [],
  data = [],
  columnMinWidths = [],
  handleSelCamera = () => {},
  currentSelCamera,
}) {
  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI cameraListTableBodyCCTVScreen">
          {data?.map((row, rowIndex) => (
            <tr
              key={`row-${rowIndex}`}
              className={`datasetTableRowFDFI ${
                currentSelCamera?.camera_id === row?.camera_id
                  ? "actvRowCameraList"
                  : ""
              }`}
              onClick={() => {
                console.log("clicking camera row", currentSelCamera);
                handleSelCamera(row);
              }}
            >
              <td>{row?.camera_name}</td>
              <td>
                {row?.camera_status ? (
                  <div className="statusWrpprCameraList">
                    <div className="statusLight actvStatuslight"></div>
                    Active
                  </div>
                ) : (
                  <div className="statusWrpprCameraList">
                    <div className="statusLight inactvStatuslight"></div>
                    In-Active
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export function MyStorageTable({
  headers = [],
  data = [],
  columnMinWidths = [],
  handleSelRow = () => {},
  currentSelRow,
  handleActStrg = () => {},
}) {


  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI cameraListTableBodyCCTVScreen">
          {data?.map((row, rowIndex) => (
            <tr
              key={`row-${rowIndex}`}
              className={`datasetTableRowFDFI ${
                currentSelRow?.storage_id === row?.storage_id
                  ? "actvRowCameraList"
                  : ""
              }`}
              onClick={() => {
                console.log("clicking camera row", currentSelRow);
                handleSelRow(row);
              }}
            >
              <td>{row?.storage_name}</td>
              <td>
                {/* connection_status */}
                {row?.connection_status ? (
                  <div className="myStrgTblConnStr_wrppr">
                    {ConntdLink}{" "}
                    <span className="myStrgTblConnStr">Connected</span>
                  </div>
                ) : (
                  <div className="myStrgTblConnStr_wrppr">
                    {DisConntdLink}{" "}
                    <span className="myStrgTblConnStr">Disconnected</span>
                  </div>
                )}
              </td>
              <td>
                <IOSSwitch
                  checked={row?.is_active}
                  onChange={(e) => {
                    console.log("checked change", e.target.checked);
                    handleActStrg(row);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
