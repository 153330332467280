import InputPageFD from "../fileUpload/fileUploader";

import { getAllCameraInfo, testCamera } from "../action/action";

import {
  FormSelectInp,
  RadioBtnInpFaceDetection,
} from "../utility/formElements/formElements";

import { useNavigate } from "react-router-dom";

import "./routes.css";

import { NavBarOptions } from "../utility/variables/variables";

import {
  faceDetectionCoordToBoundingBox,
  objectCountCoordToBoundingBox,
} from "../utility/functions/boundingBoxFunction";

// ************************ COMPONENTS ************************

import MainFrameComponent from "../components/mainFrame/mainFrame"; //               MAIN FRAME
import ProtectionWrppr from "./protectionWrppr";

import ConnectStorageScreen from "../components/screens/connectStorageScreen/connectStorageScreen"; //               CONNECT STORAGE SCREEN

import ConnectCameraScreen from "../components/screens/connectCameraScreen/connectCameraScreen"; //               CONNECT CAMERA SCREEN

import UploadToCloudScreen from "../components/screens/uploadToCloudScreen/uploadToCloudScreen"; //               UPLOAD FILE SCREEN

import AllCameraScreen from "../components/screens/AllCameraScreen/allCameraScreen"; //               ALL CAMERA SCREEN

import MyCameraScreen from "../components/screens/myCameraScreen/myCameraScreen"; //               MY CAMERA SCREEN

import InpPageAIModel from "../components/screens/inpPageAIModel/inpPageAIModel"; //               AI INPUT PAGE SCREEN

import JobPageAIModel from "../components/screens/JobPageAIModel/jobPageAIModel"; //               AI JOB PAGE SCREEN

import AddEmployeeScrn from "../components/screens/addEmployeeScreen/addEmployeeScrn"; //               INPUT PAGE ADD EMPLOYEE SCREEN

import UserDetlPgAddEmplyScrn from "../components/screens/userDetlPgAddEmplyScrn/userDetlPgAddEmplyScrn"; //               USER DETAILS PAGE ADD EMPLOYEE SCREEN

import UserManagementCrtUsrScreen from "../components/screens/UserManagementCrtUsrScreen/UserManagementCrtUsrScreen"; //               USER MANAGEMENT CREAT USER SCREEN

import UserManagementViewUsrScreen from "../components/screens/UserManagementViewUsrScreen/UserManagementViewUsrScreen"; //               USER MANAGEMENT VIEW USER SCREEN

import AttenDanceMngmntScrn from "../components/screens/attendanceMngmntScrn/attendanceMngmntScrn"; //               ATTENDANCE MANAGEMENT SCREEN

import AttendanceSettingScrn from "../components/screens/attendanceSettingScrn/attendanceSettingScrn"; //               ATTENDANCE SETTINGS SCREEN

import AnnotationScreen from "../components/screens/AnnotationScreen/annotationScreen"; //               ANNOTATION SCREEN

import CameraSettingsScrn from "../components/screens/cameraSettingsScreen/cameraSettingsScrn"; //               CAMERA SETTINGS SCREEN

// ************************ COMPONENTS ************************

// ************************ TESTING ************************

import { useRef, useCallback, useEffect, useState, useContext } from "react";
import Webcam from "react-webcam";
// import axios from "axios";
import io from "socket.io-client";
// import CircularProgress from "@mui/joy/CircularProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import {
  ExportTypeModalForAIModeling,
  UserBulkUploadModalAddEmployeeScrn,
} from "../utility/modals/modals";
import { FileSyncButton } from "../utility/button/button";
import { toast } from "react-toastify";
import { coordinates, binaryImage } from "../binaryImage";
import MyCameraRecordingScreen from "../components/screens/MyCameraRecordingScreen/myCameraRecordingScreen";
import FormInpWrpprFaceDetection from "../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";
import { MyContext } from "../context/context";
import LiveFeedPlayer from "../components/screens/liveFeedPlayer/liveFeedPlayer";
import { Checkbox } from "@mui/material";
import { waitingFunction } from "../utility/functions/helperFunc";
// ************************ TESTING ************************

export function DemoRoute() {
  const socketRef = useRef();
  const mediaRecorderRef = useRef();
  const videoRef = useRef();

  const [serverImg, setServerImg] = useState("");
  const [cirProgress, setCirProgress] = useState(0);

  const CircularProgressStyled = styled(CircularProgress)({
    position: "absolute",
    top: "-10px",
    left: "-9px",
    zIndex: 11,
    color: "#4caf50",
  });

  let be_max_count = 0;
  let be_current_count = 0;

  // useEffect(()=>{
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((userStream) => {
  //       // console.log("getting user stream ===>", userStream);
  //       videoRef.current.srcObject = userStream;

  //       let x = setInterval(() => {
  //         setCirProgress((prev) => {
  //           if (prev < 100) {
  //             return prev + 5;
  //           } else {
  //             clearInterval(x);
  //             return prev
  //           }
  //         });
  //       }, 1000);

  //       // Capture a frame and convert it to base64
  //       // const captureFrame = () => {
  //       //   const canvas = document.createElement("canvas");
  //       //   const context = canvas.getContext("2d");

  //       //   canvas.width = videoRef.current.videoWidth;
  //       //   canvas.height = videoRef.current.videoHeight;
  //       //   context.drawImage(
  //       //     videoRef.current,
  //       //     0,
  //       //     0,
  //       //     canvas.width,
  //       //     canvas.height
  //       //   );

  //       //   const base64Data = canvas.toDataURL("image/jpeg"); // or 'image/png'

  //       //   // Now you can send base64Data to the server
  //       //   // console.log("Base64 Image:", base64Data);

  //       //   socket.send(
  //       //     JSON.stringify({
  //       //       event: "frameImg",
  //       //       data: base64Data,
  //       //       ajay: "Sharma",
  //       //       max_count: be_max_count,
  //       //       current_count: be_current_count,
  //       //     })
  //       //   );

  //       //   // Repeat the process for continuous streaming
  //       //   requestAnimationFrame(captureFrame);
  //       // };

  //       // // Start capturing frames
  //       // requestAnimationFrame(captureFrame);
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing webcam:", error);
  //     });

  // },[])

  // useEffect(() => {
  //   const socket = new WebSocket("ws://localhost:8000/webcam/");

  //   // Connection opened
  //   socket.addEventListener("open", (event) => {
  //     console.log("WebSocket connection opened");

  //     navigator.mediaDevices
  //       .getUserMedia({ video: true, audio: true })
  //       .then((userStream) => {
  //         // console.log("getting user stream ===>", userStream);
  //         videoRef.current.srcObject = userStream;

  //         // Capture a frame and convert it to base64
  //         const captureFrame = () => {
  //           const canvas = document.createElement("canvas");
  //           const context = canvas.getContext("2d");

  //           canvas.width = videoRef.current.videoWidth;
  //           canvas.height = videoRef.current.videoHeight;
  //           context.drawImage(
  //             videoRef.current,
  //             0,
  //             0,
  //             canvas.width,
  //             canvas.height
  //           );

  //           const base64Data = canvas.toDataURL("image/jpeg"); // or 'image/png'

  //           // Now you can send base64Data to the server
  //           // console.log("Base64 Image:", base64Data);

  //           socket.send(
  //             JSON.stringify({
  //               event: "frameImg",
  //               data: base64Data,
  //               ajay: "Sharma",
  //               max_count: be_max_count,
  //               current_count: be_current_count,
  //             })
  //           );

  //           // Repeat the process for continuous streaming
  //           requestAnimationFrame(captureFrame);
  //         };

  //         // Start capturing frames
  //         requestAnimationFrame(captureFrame);
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing webcam:", error);
  //       });
  //   });

  //   // Listen for messages from the server
  //   socket.addEventListener("message", (msg) => {
  //     let socketRespData = JSON.parse(msg.data);
  //     console.log("socket message from server:", socketRespData?.data);

  //     let respData1 = JSON.parse(msg.data);
  //     be_max_count = respData1.max_count;
  //     be_current_count = respData1.current_count;

  //     console.log("be_current_count - BE:", respData1);

  //     setServerImg(respData1?.data);
  //   });

  //   // Connection closed
  //   socket.addEventListener("close", (event) => {
  //     console.log("WebSocket connection closed");
  //   });

  //   return () => {
  //     socket.close(); // closing web socket connection..
  //   };
  // }, []);

  // useEffect(() => {
  //   // Connect to the server using socket.io
  //   socketRef.current = io("http://localhost:12345"); // Replace with your server URL

  //   // Set up media stream from the webcam
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((userStream) => {
  //       // console.log("getting user stream ===>", userStream);
  //       videoRef.current.srcObject = userStream;

  //       // let mediaRecorder = new MediaRecorder(userStream);

  //       // console.log("my media recorder ====>",mediaRecorder);

  //       // mediaRecorder.ondataavailable = (event) => {
  //       //   console.log("inside on data available block ===>",event.data);
  //       //   if (event.data.size > 0) {

  //       //     // Concatenate the chunks into a single blob
  //       //     const blob = new Blob(event.data, { type: "video/webm" });

  //       //     // Send the blob to the server
  //       //     socketRef.current.emit("chunk", blob);
  //       //   }
  //       // };

  //       // Capture a frame and convert it to base64
  //       const captureFrame = () => {
  //         const canvas = document.createElement("canvas");
  //         const context = canvas.getContext("2d");

  //         canvas.width = videoRef.current.videoWidth;
  //         canvas.height = videoRef.current.videoHeight;
  //         context.drawImage(
  //           videoRef.current,
  //           0,
  //           0,
  //           canvas.width,
  //           canvas.height
  //         );

  //         const base64Data = canvas.toDataURL("image/jpeg"); // or 'image/png'

  //         // Now you can send base64Data to the server
  //         // console.log("Base64 Image:", base64Data);
  //         socketRef.current.emit("frameImg", base64Data);

  //         // Repeat the process for continuous streaming
  //         requestAnimationFrame(captureFrame);
  //       };

  //       // Start capturing frames
  //       requestAnimationFrame(captureFrame);

  //       // mediaRecorder.start();
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing webcam:", error);
  //     });

  //   // response event ...

  //   //

  //   socketRef.current.on("response",(data)=>{
  //     console.log("response from python server 1111 =====>",data);
  //   })

  //   return () => {
  //     // Cleanup: close the socket and stop the media recorder when the component unmounts
  //     // if (
  //     //   mediaRecorderRef.current &&
  //     //   mediaRecorderRef.current.state !== "inactive"
  //     // ) {
  //     //   mediaRecorderRef.current.stop();
  //     // }
  //     socketRef.current.disconnect();
  //   };
  // }, []);

  return (
    <>
      {/* <RadioBtnInpFaceDetection nameStr="myRadio" radioList={["", ""]} /> */}

      {/* <WebcamCapture /> */}
      {/* <img
        src="http://localhost:8000/camera/testing_fd_web_cam/"
        alt="web_cam"
      /> */}

      <h1>Testing area ...</h1>

      {/* <ExportTypeModalForAIModeling  /> */}

      {/* <ImageCanvas /> */}

      <ImageEditorNew />

      {/* <UserBulkUploadModalAddEmployeeScrn /> */}

      {/* <div style={{ display: "flex", gap: "3rem", justifyContent: "center" }}>
        <div>
          <h3>webcam FE</h3>
          <div style={{ position: "relative" }}>
            <CircularProgressStyled
              variant="determinate"
              value={cirProgress}
              size={"30rem"}
              thickness={1}
            />
            <div
              style={{
                overflow: "hidden",
                borderRadius: "100%",
                width: "25vw",
                height: "48vh",
              }}
            >
              <video ref={videoRef} autoPlay muted playsInline />
            </div>
            <img
              src={
                "https://images-na.ssl-images-amazon.com/images/G/01/gc/designs/livepreview/amazon_dkblue_noto_email_v2016_us-main._CB468775337_.png"
              }
              style={{
                borderRadius: "100%",
                width: "15vw",
                height: "28vh",
              }}
              alt="test_web_cam"
            />
          </div>
        </div>
        <div>
          <h3>webcam BE</h3>
          <img src={serverImg} alt="web_cam" />
        </div>
      </div> */}

      {/* <div
        className="cir_progress_cont"
        style={{ position: "relative", width: "fit-content" }}
      >
        <CircularProgress
          size="lg"
          determinate
          value={75}
          style={{
            position: "absolute",
            top: "43%",
            left: "42%",
          }}
        />
        <img
          src="https://images-na.ssl-images-amazon.com/images/G/01/gc/designs/livepreview/amazon_dkblue_noto_email_v2016_us-main._CB468775337_.png"
          alt="Circular Image"
          className="circular-image"
          style={{
            width: "24vw",
            height: "42vh",
            borderRadius: "100%",
          }}
        />
      </div> */}

      {/* <div>
        <CircularProgressStyled variant="determinate" value={75} />
        <video ref={videoRef} autoPlay muted playsInline />
      </div> */}
    </>
  );
}

export function IpCamWebSocket() {
  const [socketStatus, setSocketStatus] = useState("Disconnected");
  const [imgUrl, setImgUrl] = useState("");
  const [imgUrl1, setImgUrl1] = useState("");
  const [myCoordi, setMyCoordi] = useState([]); // "Ajay Sharma_AJA13032023": [713, 991, 803, 902]
  const [secondListOfCoord, setSecondListOfCoord] = useState([]);
  const socketRef = useRef(null);
  const screenShotContRef = useRef(null);
  const screenShotListForAnnRef = useRef([]);
  const imgScreenShotRef = useRef("");
  const [frameScreenShots, setFrameScreenShots] = useState([]);
  const [cameraListArr, setCameraListArr] = useState([]);
  const [selCameraFromList, setSelCameraFromList] = useState({});
  const [selScreenShotForAnnList, setSelScreenShotForAnnList] = useState([]);
  const [selCameraLabel, setSelCameraLabel] = useState(
    "Select Camera From List"
  );
  const [selModelLabel, setSelModelLabel] = useState("Select Model From List");
  const [modelTypeArr, setModelTypeArr] = useState([
    {
      name: "Face recognition",
      id: "face_reco",
    },
    { name: "Object count", id: "object_count" },
  ]);

  const [selCameraStatus, setSelCameraStatus] = useState("load");

  const [selModelType, setSelModelType] = useState({
    name: "Face recognition",
    id: "face-reco",
  });

  const navigate = useNavigate();

  const startWebSocketCallCount = useRef(0);
  const startWebSocketCamID = useRef(null);

  const { allDetails, imgAnnotation, sideNav, mySelCamStream } =
    useContext(MyContext);

  const sendMessage = (msg = "") => {
    console.log("inside snd message function...");
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      // console.log("websocket connection is redy to send messasge", msg);
      socketRef.current.send(msg);
    }
  };

  // test sample image scrollable ............

  const deals = [
    { image: "https://via.placeholder.com/200", title: "Deal 1" },
    { image: "https://via.placeholder.com/200", title: "Deal 2" },
    { image: "https://via.placeholder.com/200", title: "Deal 3" },
    { image: "https://via.placeholder.com/200", title: "Deal 4" },
    { image: "https://via.placeholder.com/200", title: "Deal 5" },
    { image: "https://via.placeholder.com/200", title: "Deal 5" },
    { image: "https://via.placeholder.com/200", title: "Deal 5" },
    { image: "https://via.placeholder.com/200", title: "Deal 5" },
    { image: "https://via.placeholder.com/200", title: "Deal 5" },
  ];

  // test sample image scrollable ............

  useEffect(() => {
    // console.log("getting my sel cam 11111 ===>", mySelCamStream.selCameraURl);
    if (mySelCamStream.selCameraURl) {
      handleSelCamera([mySelCamStream.selCameraURl]);
      startWebSocketCamID.current = mySelCamStream.selCameraURl;
    } else {
      cameraListArr.length && handleSelCamera([cameraListArr[0]]);
      startWebSocketCamID.current = cameraListArr[0];
      // console.log("all camera list array ====> 11111", cameraListArr);
    }
    waitingFunction(1000);

    if (!startWebSocketCallCount.current && startWebSocketCamID.current) {
      console.log(
        "start websocket with cam id =====>",
        startWebSocketCamID.current
      );
      startWebSocket();
      startWebSocketCallCount.current += 1;
    } else {
      console.log(
        "ELSE BLOCK: start websocket with cam id =====>",
        startWebSocketCamID.current
      );
      console.log(
        "ELSE BLOCK: start websocket count =====>",
        startWebSocketCallCount.current
      );
      // toast.warn("got no cam details to fetch feed");
    }
    // handleSelCamera([mySelCamStream.selCameraURl]);
  }, [mySelCamStream, cameraListArr]);

  const startWebSocket = () => {
    // if(selCameraFromList?.camera_id){
    //   console.log("mySelCamStream.selCameraURl ====>",selCameraFromList?.camera_id);
    //   handleSelCamera([selCameraFromList?.camera_id]);
    // }
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_Websocket}/ipcam/`
    );

    socketRef.current.onopen = () => {
      // toast.success("Connected !");
      sendMessage(
        JSON.stringify({
          event: "start_ip_cam",
          cam_id: startWebSocketCamID.current?.camera_id, // camera_id
          model_type: selModelType?.id,
          name: "gaurav",
        })
      );
    };

    socketRef.current.onmessage = (msg) => {
      const socketRespData = JSON.parse(msg.data);
      // console.log("message received from server side...", socketRespData);
      // if (socketRespData?.event === "update_ui") {
      //   console.log("my rectangle coordinate ===>", socketRespData?.cordinate);

      //   socketRespData?.cordinate &&
      //     setImgUrl1(`data:image/jpeg;base64,${socketRespData?.cordinate}`);
      // } else
      if (socketRespData?.event === "live_video") {
        setImgUrl(`data:image/jpeg;base64,${socketRespData?.data}`);
        imgScreenShotRef.current = `data:image/jpeg;base64,${socketRespData?.data}`; //  taking image for screen shot...
        // setImgUrl1(JSON.stringify(socketRespData?.face_coordinate_dict) || "");
        console.log(
          "getting live camera feed in single camera ===>",
          socketRespData
        );
        setSelCameraStatus(socketRespData?.status);
        // console.log(
        //   "setting face name coordinates list to server value===>",
        //   socketRespData?.face_coordinate_dict
        // );
        setMyCoordi(socketRespData?.face_coordinate_dict);

        // console.log("setting second coordinates list to server value===>");
        setSecondListOfCoord(socketRespData?.face_list);
        // sendMessage(
        //   JSON.stringify({
        //     event: "new_frame",
        //     cam_id: "1",
        //     name: "gaurav",
        //     frameImg: socketRespData?.data,
        //   })
        // );
      } else if (socketRespData?.event === "error_message") {
        toast.error(
          socketRespData?.message || "error occured please refresh the page !"
        );
      }

      // Sending a new message to the server when a message is received
    };

    socketRef.current.onclose = () => {
      console.log("socket closed...");
      // toast.warn("Disconnected !");
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
      // toast.error("Error Occurred !");
    };
  };

  const stopWebSocket = (unMount = false) => {
    if (socketRef.current) {
      socketRef.current.close();
      // (!unMount)&&toast.warn("socket connection disconnected!");
    }
  };

  // async function fetchCameraDetails(isInitial = true) {
  //   let response = await getAllCameraInfo();
  //   if (!response?.success) return;
  //   console.log("res", response?.data?.AllCamera);
  //   if (isInitial) {
  //     if (response?.data?.AllCamera) {
  //       let arr = response?.data?.AllCamera?.map((e) => {
  //         return { id: e?.camera_id, name: e?.camera_name };
  //       });
  //       setCameraListArr([...arr]);
  //     }
  //   }
  // }

  function handleSelModel(e) {
    setSelModelLabel(e[0]?.name);
    setSelModelType({ ...e[0] });
  }

  function handleSelCamera(e) {
    setSelCameraLabel(e[0]?.name);
    setSelCameraFromList({ ...e[0] });
  }

  function handleTakeScreenShot() {
    console.log("taking screen shot ===>", imgScreenShotRef.current);
    if (!imgScreenShotRef.current) {
      toast.warn("no frame to capture...");
      return;
    }

    setFrameScreenShots((prev) => {
      let id = `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
      let imgObj = { id: id, image: imgScreenShotRef.current };
      return [...prev, imgObj];
    });
  }

  function handleSendForAnnotation() {
    if (!screenShotListForAnnRef.current.length) {
      toast.warn("no captured frames ...");
      return;
    }
    let imageList = screenShotListForAnnRef.current?.map((elem) => elem?.image);
    imgAnnotation.handleUpdateImgAnntnFrameList(imageList);

    let dataObj = { main: "", sub: "", current: "" };
    dataObj.main = NavBarOptions["Image Annotation"].slang;
    dataObj.sub = "";
    dataObj.current = NavBarOptions["Image Annotation"].slang;
    sideNav?.handleSelScreen(dataObj);
    // imgForAnnotation,handleUpdateImgAnntnFrameList
    navigate("/");
  }

  function handleChangeSelScreenShot(img, event) {
    let value = event?.target?.checked;
    // console.log("selected screen shot===>", img, "  checkbox value ==>", value);
    if (!value) {
      setSelScreenShotForAnnList((prev) => {
        let newList = prev.filter((elem) => {
          return elem.id !== img.id;
        });
        screenShotListForAnnRef.current = newList;
        return newList;
      });
    } else {
      setSelScreenShotForAnnList((prev) => {
        let newList = prev[0] ? [...prev] : [];
        newList.push(img);
        // console.log("new screen shot list", newList);
        screenShotListForAnnRef.current = newList;
        return newList;
      });
    }
  }

  function handleCheckSelScreenShotInList(id) {
    // selScreenShotForAnnList
    // console.log(
    //   "selected ss element ==>",
    //   selSSElem,
    //   screenShotListForAnnRef.current
    // );
    let isCheck = false;
    screenShotListForAnnRef.current.forEach((elem) => {
      // console.log("the iteration elem",elem.id,"clicked",id," checked ",elem.id === id);
      if (elem.id === id) {
        isCheck = true;
      }
    });
    return isCheck;
  }

  useEffect(() => {
    // fetchCameraDetails();
    setCameraListArr(allDetails?.allCamList || []);
    // console.log(
    //   "getting all camera details live camera===>",
    //   allDetails?.allCamList
    // );
    return () => {
      stopWebSocket(true);
    };
  }, [allDetails?.allCamList]);

  useEffect(() => {
    if (!screenShotContRef.current) return;
    // console.log(
    //   "screen shot cont width  ===>",
    //   screenShotContRef.current.offsetWidth
    // );
    let elem = document.getElementsByClassName(
      "screen_shot_cont_wrapper_single_cam_feed"
    )[0];
    elem.style.maxWidth = `${screenShotContRef.current.offsetWidth - 20}px`;
  }, [screenShotContRef.current?.offsetWidth]);

  // useEffect(()=>{
  // if(!selScreenShotForAnnList[0])return;
  //   screenShotListForAnnRef.current = [...selScreenShotForAnnList];
  // },[selScreenShotForAnnList])

  return (
    <>
      {/* <h1>ip camera demo route</h1> */}
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          gap: "3rem",
          flexDirection: "column",
          margin: "0 5%",
        }}
      >
        <div className="live_stream_container_web_socket_test">
          <div className="selStorageModalActvArea_btnWrppr margin_top_append">
            {/* <div className="sel_drp_dwn_container">
              <FormInpWrpprFaceDetection>
                <FormSelectInp
                  title={"Camera List"}
                  placeHldr={"Select Camera From List"}
                  multiSel={false}
                  handlChange={handleSelCamera}
                  optionList={cameraListArr}
                  initialLabelDropDown={selCameraLabel}
                  selProdList={[selCameraFromList]}
                  // error={
                  //   addCameraErrObj?.pre_trend_model &&
                  //   "Please select a camera to continue"
                  // }
                  unableSearch={true}
                />
              </FormInpWrpprFaceDetection>
              <FormInpWrpprFaceDetection>
                <FormSelectInp
                  title={"Model Type"}
                  placeHldr={"Select Model Type"}
                  multiSel={false}
                  handlChange={handleSelModel}
                  optionList={modelTypeArr}
                  initialLabelDropDown={selModelLabel}
                  selProdList={[selModelType]}
                  // error={
                  //   formDataErrorObj?.pre_trend_model &&
                  //   "Please select a model to continue"
                  // }
                  // unableSearch={true}
                />
              </FormInpWrpprFaceDetection>
            </div>
            <button
              style={{
                background: "white",
                color: "#05306B",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                cursor: "pointer",
              }}
              onClick={() => {
                const payload = {
                  event: "start_ip_cam",
                  cam_id: selCameraFromList?.id,
                  model_type: selModelType?.id,
                  name: "gaurav",
                };
                console.log("payload", payload, selCameraFromList);
                startWebSocket();
              }}
            >
              start
            </button> */}
            {/* <button
            style={{
              background: "white",
              color: "#05306B",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
            onClick={() => stopWebSocket()}
          >
            Stop
          </button> */}

            {/* <button
              style={{
                background: "white",
                color: "#05306B",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                cursor: "pointer",
              }}
              onClick={handleTakeScreenShot}
            >
              Screen Shot
            </button> */}
            {/* <button
              style={{
                background: "white",
                color: "#05306B",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                cursor: "pointer",
              }}
              onClick={handleSendForAnnotation}
            >
              Annotate Images
            </button> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              flexWrap: "wrap",
            }}
          >
            {/* <div>
            <p>original</p>
            <img src={imgUrl} alt="ip_cam_feed" style={{ maxWidth: "45vw" }} />
          </div>
          <div>
            <p>co-ordinates</p>
            {imgUrl1}
          </div> */}

            <div>
              {/* <p>react generated</p> */}

              {/* <ImageWithOverlay
                imageUrl={imgUrl}
                coordinates={myCoordi}
                secondList={secondListOfCoord}
              /> */}
              <LiveFeedPlayer
                handleScreenShot={handleTakeScreenShot}
                camID={selCameraFromList?.id}
                camStatus={selCameraStatus}
              >
                <ImageWithOverlay
                  imageUrl={imgUrl}
                  coordinates={myCoordi}
                  secondList={secondListOfCoord}
                />
              </LiveFeedPlayer>
            </div>

            {/* <div
            style={{
              position: "relative",
              maxWidth: "45vw",
            }}
          >
            <img
              src="./pexels-photo-774909.jpeg"
              alt="Image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />

            <div
              style={{
                position: "absolute",
                left: "27.800000000000004%",
                top: "26.13333333333333%",
                width: "34.4%",
                height: "31.6%",
                border: "2px solid red",
                boxSizing: "border-box",
              }}
            ></div>
          </div> */}
          </div>
        </div>

        {/* <div
          className="screen_shots_side_cont_web_socket_live_test"
          ref={screenShotContRef}
        >
          <h3 className="screen_shot_list_head_single_cam_feed">
            Screenshot list
          </h3>
          <div className="screen_shot_cont_wrapper_single_cam_feed">
            <div className="screen_shot_container_single_cam_feed">
              
              {frameScreenShots?.map((imgElem, index) => {
                // frameScreenShots  deals

                return (
                  <div
                    className="screen_shot_image_wrapper_container"
                    key={`image_screen_shot_wrapper${imgElem?.id}`}
                  >
                    <Checkbox
                      // size="24"
                      checked={handleCheckSelScreenShotInList(imgElem.id)}
                      onChange={(e) => {
                        handleChangeSelScreenShot(imgElem, e);
                      }}
                      color="success"
                      className="screen_shot_image_checkBox"
                      key={`image_screen_shot_wrapper${imgElem.id}`}
                    />
                    <img
                      src={imgElem?.image} // imgElem
                      alt="screen_shots"
                      className="screen_shot_img_single_feed_cam"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export function ImageWithOverlay({
  imageUrl = "",
  coordinates = [], // { "Ajay Sharma_AJA13032023": [713, 991, 803, 902] }
  secondList = [],
  objectCountData = {},
}) {
  // Assuming coordinates is in the format: {'Ajay Sharma_AJA13032023': [(713, 991, 803, 902)]}
  const [overlayStyle, setOverlayStyle] = useState({
    position: "absolute",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    border: "2px solid red", // Adjust styling as needed
    boxSizing: "border-box",
  });

  const { mySelCamStream } = useContext(MyContext);

  const [divListArr, setDivListArr] = useState([]);
  const [secDivListArr, setSecDivListArr] = useState([]);
  const [objCountListArr, setObjCountListArr] = useState([]);

  function loadGreyBackgroundImgTag(event) {
    // console.log("running on error image element.");
    event.target.src = "/images/grey_background_image.jpg";
    event.onerror = null;
  }

  // useEffect(() => {
  //   console.log("upcoming coordi's ====>", coordinates);
  //   let cooo = [{ "Gaurav Jiwane_GAU05092022": [528, 273, 681, 1080] }];
  //   if (coordinates[0]) {
  //     const img = new Image();
  //     img.src = imageUrl;

  //     let originalImageWidth = img.width,
  //       originalImageHeight = img.height;

  //     let myDivList = [];

  //     coordinates.forEach((c) => {
  //       // const {name, [[x1, y1, x2, y2]]} = c;

  //       for (const key in c) {
  //         let name = key,
  //           [y1, x1] = c[key]; // [tl-y,br-x,br-y,tl-x]

  //         name = name.split("_")[0];

  //         console.log("getting coordinates extracted =======>", x1, y1);
  //         const width = 0;
  //         const height = 0;
  //         const left = (x1 / originalImageWidth) * 100;
  //         const top = (y1 / originalImageHeight) * 100;
  //         const paraTop = top - 3;
  //         const paraLeft = left;

  //         const overlayStyle = {
  //             position: "absolute",
  //             left: left + "%",
  //             top: top + "%",
  //             width: (width / originalImageWidth) * 100 + "%",
  //             height: (height / originalImageHeight) * 100 + "%",
  //             border: `2px solid ${
  //               name.toLowerCase() === "unknown" ? "red" : "#04AA6D"
  //             }`, // Adjust styling as needed
  //             boxSizing: "border-box",
  //           },
  //           boundingDetails = {
  //             name,
  //             style: { ...overlayStyle },
  //             paraTop,
  //             paraLeft,
  //           };

  //         myDivList.push(boundingDetails);
  //         console.log("before updating div list =====>", myDivList);
  //         setDivListArr([...myDivList]);
  //       }

  //       // coordinates.forEach((c) => {
  //       //   const [x1, y1, x2, y2] = c;

  //       // console.log("getting coordiantes extracted =======>",x1,y1,x2,y2);

  //       // const width = Math.abs(x2 - x1);
  //       // const height = Math.abs(y2 - y1);
  //       //   const width = x2 - x1;
  //       //   const height = (y2-y1)-10;
  //       //   const left = (x1 / originalImageWidth) * 100 + "%";
  //       //   const top = (y1 / originalImageHeight) * 100 + "%";

  //       //   const overlayStyle = {
  //       //     position: "absolute",
  //       //     left,
  //       //     top,
  //       //     width: (width / originalImageWidth) * 100 + "%",
  //       //     height: (height / originalImageHeight) * 100 + "%",
  //       //     border: "2px solid red", // Adjust styling as needed
  //       //     boxSizing: "border-box",
  //       //   },
  //       //   boundingDetails = {
  //       //     name,
  //       //     style:{...overlayStyle}
  //       //   };
  //       //   myDivList.push(boundingDetails);
  //     });
  //     // console.log("before updating div list =====>", myDivList);
  //     // setDivListArr([...myDivList]);

  //     // const [name, [x1, y1, x2, y2]] = Object.entries(coordinates)[0];

  //     // console.log(
  //     //   "image original dimensions ===>",
  //     //   "    h",
  //     //   originalImageHeight,
  //     //   "     w",
  //     //   originalImageWidth
  //     // );
  //     // Calculate the dimensions and position of the overlay div

  //     // const width = Math.abs(x2 - x1);
  //     // const height = Math.abs(y2 - y1);
  //     // const left = (x1 / originalImageWidth) * 100 + "%";
  //     // const top = (y1 / originalImageHeight) * 100 + "%";

  //     // console.log("rect indicator dimensions ===>", width, height, left, top);

  //     // const overlayStyle = {
  //     //   position: "absolute",
  //     //   left,
  //     //   top,
  //     //   width: (width / originalImageWidth) * 100 + "%",
  //     //   height: (height / originalImageHeight) * 100 + "%",
  //     //   border: "2px solid red", // Adjust styling as needed
  //     //   boxSizing: "border-box",
  //     // };
  //     // console.log("rect indicator style obj ===>", overlayStyle);
  //     // setOverlayStyle({ ...overlayStyle });
  //   } else {
  //     setDivListArr([]);
  //   }
  // }, [coordinates]);

  // useEffect(() => {
  //   console.log("second list of coordinates useEffect====>");
  //   if (secondList[0]) {
  //     console.log("second list of coordinates ======> if statemetn");
  //     const img = new Image();
  //     img.src = imageUrl;

  //     let originalImageWidth = img.width,
  //       originalImageHeight = img.height;

  //     let myDivList = [];

  //     secondList.forEach((c) => {
  //       let [x1, y1, x2, y2] = c;

  //       const width = Math.abs(x2 - x1);
  //       const height = Math.abs(y2 - y1);
  //       const left = (x1 / originalImageWidth) * 100;
  //       const top = (y1 / originalImageHeight) * 100;

  //       console.log(
  //         "coordinates face list  =====>:  ",
  //         "width",
  //         width,
  //         "height",
  //         height,
  //         "top",
  //         top,
  //         "left",
  //         left
  //       );

  //       const overlayStyle = {
  //         position: "absolute",
  //         left: left + "%",
  //         top: top + "%",
  //         width: (width / originalImageWidth) * 100 + "%",
  //         height: (height / originalImageHeight) * 100 + "%",
  //         border: `4px solid #05306B`, // Adjust styling as needed
  //         boxSizing: "border-box",
  //       };

  //       myDivList.push(overlayStyle);
  //       console.log("before updating second div list =====>", myDivList);
  //     });
  //     setSecDivListArr([...myDivList]);
  //   } else {
  //     console.log("setting second coordinates list to empty ===>");
  //     setDivListArr([]);
  //   }
  // }, [secondList]);

  useEffect(() => {
    console.log("upcoming coordinates in useEffect ====>", coordinates);
    let cooo = [{ "Gaurav Jiwane_GAU05092022": [528, 273, 681, 1080] }];
    if (coordinates[0]) {
      console.log("inside got coordinates in useEffect ====>");
      const img = new Image();
      img.src = imageUrl;

      let originalImageWidth = img.width,
        originalImageHeight = img.height;

      let myDivList = [];

      coordinates.forEach((c) => {
        // const {name, [[x1, y1, x2, y2]]} = c;

        // --------------------------------------------------------------------------------------

        // for (const key in c) {
        //   let name = key,
        //     // [y1, x2, y2, x1] = c[key]; // [tl-y,br-x,br-y,tl-x]
        //     [x1, y1, x2, y2] = c[key]; // [tl-x,tl-y,br-x,br-y]

        //   name = name.split("_")[0];

        //   console.log("extracted name =======>", name);
        //   console.log(
        //     `original image dimensions ===>  width:  ${originalImageWidth}`,
        //     "color:blue; font-size:20px;"
        //   );
        //   console.log(
        //     `original image dimensions ===>  height:  ${originalImageHeight}`,
        //     "color:brown; font-size:20px;"
        //   );
        //   console.log(
        //     `original image dimensions ===>  y1:  ${y1}`,
        //     "color:purple; font-size:20px;"
        //   );
        //   const width = Math.abs(x2 - x1);
        //   const height = Math.abs(y2 - y1);
        //   const left = (x1 / originalImageWidth) * 100;
        //   const top = (y1 / originalImageHeight) * 100;
        //   const paraTop = top - 3;
        //   const paraLeft = left;
        //   console.log("width: ===>", width);
        //   console.log("height: ===>", height);
        //   console.log("left: ===>", left);
        //   if (left > 0 && left < 100) {
        //     let outputColor = "color:green; font-size:20px;";
        //     console.log(`%c left:    ====>${left}`, outputColor);
        //   } else {
        //     let outputColor = "color:red; font-size:20px;";
        //     console.log(`%c left:    ====>${left}`, outputColor);
        //   }
        //   console.log("top: ===>", top);
        //   if (top > 0 && top < 100) {
        //     let outputColor = "color:yellow; font-size:20px;";
        //     console.log(`%c top:    ====>${top}`, outputColor);
        //   } else {
        //     let outputColor = "color:pink; font-size:20px;";
        //     console.log(`%c top:    ====>${top}`, outputColor);
        //   }
        //   console.log("paraTop: ===>", paraTop);
        //   console.log("paraLeft: ===>", paraLeft);

        //   const overlayStyle = {
        //       position: "absolute",
        //       left: left + "%",
        //       top: top + "%",
        //       width: (width / originalImageWidth) * 100 + "%",
        //       height: (height / originalImageHeight) * 100 + "%",
        //       border: `2px solid ${
        //         name.toLowerCase() === "unknown" ? "red" : "#04AA6D"
        //       }`, // Adjust styling as needed
        //       boxSizing: "border-box",
        //     },
        //     boundingDetails = {
        //       name,
        //       style: { ...overlayStyle },
        //       paraTop,
        //       paraLeft,
        //     };

        //   myDivList.push(boundingDetails);
        //   // console.log("before updating div list name =====>", myDivList);
        // }
        myDivList = [
          ...faceDetectionCoordToBoundingBox(
            c,
            originalImageWidth,
            originalImageHeight
          ),
        ];

        // --------------------------------------------------------------------------------------

        // coordinates.forEach((c) => {
        //   const [x1, y1, x2, y2] = c;

        // console.log("getting coordiantes extracted =======>",x1,y1,x2,y2);

        // const width = Math.abs(x2 - x1);
        // const height = Math.abs(y2 - y1);
        //   const width = x2 - x1;
        //   const height = (y2-y1)-10;
        //   const left = (x1 / originalImageWidth) * 100 + "%";
        //   const top = (y1 / originalImageHeight) * 100 + "%";

        //   const overlayStyle = {
        //     position: "absolute",
        //     left,
        //     top,
        //     width: (width / originalImageWidth) * 100 + "%",
        //     height: (height / originalImageHeight) * 100 + "%",
        //     border: "2px solid red", // Adjust styling as needed
        //     boxSizing: "border-box",
        //   },
        //   boundingDetails = {
        //     name,
        //     style:{...overlayStyle}
        //   };
        //   myDivList.push(boundingDetails);
      });
      setDivListArr([...myDivList]);
      // console.log("before updating div list =====>", myDivList);
      // setDivListArr([...myDivList]);

      // const [name, [x1, y1, x2, y2]] = Object.entries(coordinates)[0];

      // console.log(
      //   "image original dimensions ===>",
      //   "    h",
      //   originalImageHeight,
      //   "     w",
      //   originalImageWidth
      // );
      // Calculate the dimensions and position of the overlay div

      // const width = Math.abs(x2 - x1);
      // const height = Math.abs(y2 - y1);
      // const left = (x1 / originalImageWidth) * 100 + "%";
      // const top = (y1 / originalImageHeight) * 100 + "%";

      // console.log("rect indicator dimensions ===>", width, height, left, top);

      // const overlayStyle = {
      //   position: "absolute",
      //   left,
      //   top,
      //   width: (width / originalImageWidth) * 100 + "%",
      //   height: (height / originalImageHeight) * 100 + "%",
      //   border: "2px solid red", // Adjust styling as needed
      //   boxSizing: "border-box",
      // };
      // console.log("rect indicator style obj ===>", overlayStyle);
      // setOverlayStyle({ ...overlayStyle });
    } else {
      setDivListArr([]);
    }
  }, [coordinates]);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;

    let originalImageWidth = img.width,
      originalImageHeight = img.height;

    let objectCountList = objectCountCoordToBoundingBox(
      objectCountData,
      originalImageWidth,
      originalImageHeight
    );

    if (!objectCountList[0]) {
      setObjCountListArr([]);
    } else {
      setObjCountListArr([...objectCountList]);
    }
  }, [objectCountData]);

  useEffect(() => {
    // console.log("second list of coordinates useEffect====>", secondList);
    if (secondList[0]) {
      // console.log("second list of coordinates ======> if statemetn");
      const img = new Image();
      img.src = imageUrl;

      let originalImageWidth = img.width,
        originalImageHeight = img.height;

      let myDivList = [];

      secondList.forEach((c) => {
        let [x1, y1, x2, y2] = c;

        const width = Math.abs(x2 - x1);
        const height = Math.abs(y2 - y1);
        const left = (x1 / originalImageWidth) * 100;
        const top = (y1 / originalImageHeight) * 100;

        // console.log(
        //   "coordinates face list  =====>:  ",
        //   "width",
        //   width,
        //   "height",
        //   height,
        //   "top",
        //   top,
        //   "left",
        //   left
        // );

        const overlayStyle = {
          position: "absolute",
          left: left + "%",
          top: top + "%",
          width: (width / originalImageWidth) * 100 + "%",
          height: (height / originalImageHeight) * 100 + "%",
          border: `4px solid #05306B`, // Adjust styling as needed
          boxSizing: "border-box",
        };

        myDivList.push(overlayStyle);
        // console.log("before updating second div list =====>", myDivList);
      });
      setSecDivListArr([...myDivList]);
    } else {
      // console.log("setting second coordinates list to empty ===>");
      setSecDivListArr([]);
    }
  }, [secondList]);
  return (
    <div
      style={{
        position: "relative",
        maxWidth: mySelCamStream?.selCameraFullScreen ? "none" : "74vw", // make it removable when clicked full screen
        overflow: "hidden",
        height: "100%",
      }}
    >
      <img
        src={imageUrl}
        alt="Image_frames"
        style={{ width: "100%", height: "100%" }}
        onError={(event) => loadGreyBackgroundImgTag(event)}
      />
      {divListArr?.map((e, i) => (
        <div key={`bounding_box_wrapper_${i}`}>
          <p
            style={{
              position: "absolute",
              left: e?.paraLeft + "%",
              top: e?.paraTop + "%",
              color: e?.name?.toLowerCase() === "unknown" ? "red" : "#04AA6D",
              fontSize: "10px",
              fontWeight: 800,
              background: "white",
              padding: "0 .1rem",
              zIndex: 3,
            }}
          >
            {e?.name}
          </p>
          <div style={{ ...e?.style }}></div>
        </div>
      ))}
      {secDivListArr?.map((e, i) => (
        <div style={{ ...e }} key={`second_list_bounding_boxes_${i}`}></div>
      ))}
      {objCountListArr?.map((e, i) => (
        <div key={`bounding_box_wrapper_object_count_${i}`}>
          <p
            style={{
              position: "absolute",
              left: e?.paraLeft + "%",
              top: e?.paraTop + "%",
              color: "red",
              fontSize: "10px",
              fontWeight: 800,
              background: "white",
              padding: "0 .1rem",
              zIndex: 3,
            }}
          >
            {e?.name}
          </p>
          <div style={{ ...e?.style }}></div>
        </div>
      ))}
    </div>
  );
}

export function AllCamTestComponent() {
  const [socketStatus, setSocketStatus] = useState("Disconnected");
  const [imgUrlArr, setImgUrlArr] = useState([]);
  const socketRef = useRef(null);

  const sendMessage = (msg = "") => {
    console.log("inside snd message function...");
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      // console.log("websocket connection is redy to send messasge", msg);
      socketRef.current.send(msg);
    }
  };

  const startWebSocket = (
    payload = {
      event: "start_all_cam",
      cam_id: "1",
      name: "gaurav",
    }
  ) => {
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_Websocket}/multi_ipcam/`
    );

    socketRef.current.onopen = () => {
      console.log("websocket connection open...");
      toast.success("Connected !");
      sendMessage(JSON.stringify(payload));
    };

    socketRef.current.onmessage = (msg) => {
      const socketRespData = JSON.parse(msg.data);
      console.log("message received from server side...", socketRespData);
      /*
        'event':"multi_live_video",
                        'data': decoded_frames_data,
                        'content_type':'multipart/x-mixed-replace; boundary=frame',
      */
      if (socketRespData?.event === "multiipcam") {
        console.log("receiving all cam feed ===>", socketRespData);
        setImgUrlArr([...socketRespData?.data]);
      }

      // Sending a new message to the server when a message is received
    };

    socketRef.current.onclose = () => {
      console.log("socket closed...");
      toast.warn("Disconnected !");
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
      toast.error("Error Occurred !");
    };
  };

  const stopWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      toast.warn("socket connection disconnected!");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        flexDirection: "column",
      }}
    >
      <h1>test route all cam feed</h1>
      <div className="selStorageModalActvArea_btnWrppr margin_top_append">
        <button
          style={{
            boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            color: "white",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
          onClick={() => startWebSocket()}
        >
          Connect
        </button>
        <button
          style={{
            background: "white",
            color: "#05306B",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
          onClick={() => stopWebSocket()}
        >
          Disconnect
        </button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "2rem",
          flexWrap: "wrap",
        }}
      >
        {imgUrlArr?.map((e, i) => {
          return (
            <>
              <img
                src={`data:image/jpeg;base64,${e?.frame}`}
                alt="ip_cam_feed_rect"
                style={{ maxWidth: "45vw" }}
                key={`my_cam_feed_${e?.cam_id}`}
              />
            </>
          );
        })}
      </div>
    </div>
  );
}

export function ImageCoordinatesCoordinates() {
  // const myImageUrl = `data:image/jpeg;base64,${binaryImage}`,
  //   myCoordinates = { "Ajay Sharma_AJA13032023": [713, 991, 803, 902] };

  // let coordi = myCoordinates; // myCoordinates["Ajay Sharma_AJA13032023"]
  // console.log("extracting coordiates ====>", coordi);

  // const canvasRef = useRef(null);
  // const [imageSrc, setImageSrc] = useState("");

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   // Clear the canvas
  //   const img = new Image();
  //   img.src = myImageUrl;
  //   canvas.width = img.width;
  //     canvas.height = img.height;

  //   img.onload = () => {
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);
  //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //     // Draw rectangles
  //     // const [x1, y1, x2, y2] = coordi;
  //     // ctx.beginPath();
  //     // ctx.rect(x1, y1, x2 - x1, y2 - y1);
  //     // ctx.strokeStyle = "red";
  //     // ctx.lineWidth = 2;
  //     // ctx.stroke();
  //     // coordi.forEach((coord) => {
  //     // });

  //     Object.keys(coordi).forEach(name => {
  //       const [x1, y1, x2, y2] = coordi[name];
  //       const width = x2 - x1;
  //       const height = y2 - y1;

  //       ctx.beginPath();
  //       ctx.rect(x1, y1, width, height);
  //       ctx.strokeStyle = 'red';
  //       ctx.lineWidth = 2;
  //       ctx.stroke();
  //     });

  //     // Convert canvas to image and set the image source
  //     const dataUrl = canvas.toDataURL("image/png");
  //     setImageSrc(dataUrl);
  //   }
  // }, [myCoordinates]);

  // console.log("getting image coordinates =======>", myCoordinates);

  const myImageUrl = `data:image/jpeg;base64,${binaryImage}`;
  const myCoordinates = { "Ajay Sharma_AJA13032023": [713, 991, 803, 902] };

  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = myImageUrl;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Draw rectangles
      Object.keys(myCoordinates).length &&
        Object.keys(myCoordinates).forEach((name) => {
          const [x1, y1, x2, y2] = myCoordinates[name];
          const width = x2 - x1;
          const height = y2 - y1;

          ctx.beginPath();
          ctx.rect(x1, y1, width, height);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.stroke();
        });

      // Convert canvas to image and set the image source
      const dataUrl = canvas.toDataURL("image/png");
      setImageSrc(dataUrl);
    };
  }, [myCoordinates, myImageUrl]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4rem",
        // position: "relative",
      }}
    >
      <canvas
        ref={canvasRef}
        // width={1200} // Set your desired width
        // height={1200} // Set your desired height
        style={{ border: "1px solid #000" }}
      />
      {/* <img src={myImageUrl} alt="frame" /> */}
      {/* <img src={imageSrc || ""} alt="frame" /> */}
    </div>
  );
}

// ---------------------------- TESTING ----------------------------

export function Components() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
        height: "100%",
        padding: "12rem 0rem",
      }}
    >
      <MainFrameComponent />
    </div>
  );
}

export function FaceRecognitionApp() {
  return (
    <>
      <ProtectionWrppr status="user" redirection="/login">
        <MainFrameComponent />
      </ProtectionWrppr>
    </>
  );
}

export function ProtectedConnectStorageScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <ConnectStorageScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedConnectCameraScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <ConnectCameraScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedUploadToCloudScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <UploadToCloudScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedAllCameraScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <AllCameraScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedMyCameraScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <MyCameraScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedMyCameraRecordingScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <MyCameraRecordingScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedInpPageAIModel() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <InpPageAIModel />
    </ProtectionWrppr>
  );
}

export function ProtectedJobPageAIModel() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <JobPageAIModel />
    </ProtectionWrppr>
  );
}

export function ProtectedAddEmployeeScrn() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <AddEmployeeScrn />
    </ProtectionWrppr>
  );
}

export function ProtectedUserDetlPgAddEmplyScrn() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <UserDetlPgAddEmplyScrn />
    </ProtectionWrppr>
  );
}

export function ProtectedUserManagementCrtUsrScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <UserManagementCrtUsrScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedUserManagementViewUsrScreen() {
  return (
    <ProtectionWrppr status="user" redirection="/login">
      <UserManagementViewUsrScreen />
    </ProtectionWrppr>
  );
}

export function ProtectedAttenDanceMngmntScrn() {
  return (
    <>
      <ProtectionWrppr status="user" redirection="/login">
        <AttenDanceMngmntScrn />
      </ProtectionWrppr>
    </>
  );
}

export function ProtectedAttendanceSettingScrn() {
  return (
    <>
      <ProtectionWrppr status="user" redirection="/login">
        <AttendanceSettingScrn />
      </ProtectionWrppr>
    </>
  );
}

export function ProtectedAnnotationScreen() {
  return (
    <>
      <ProtectionWrppr status="user" redirection="/login">
        <AnnotationScreen />
      </ProtectionWrppr>
    </>
  );
}

export function ProtectedCameraSettingsScreen() {
  return (
    <>
      <ProtectionWrppr status="user" redirection="/login">
        <CameraSettingsScrn />
      </ProtectionWrppr>
    </>
  );
}

// *********************************  TESTING WEB CAM FOR ADD EMPLOYEE INP PAGE *********************************

const WebcamCapture = () => {
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log(imageSrc); // You can use this image source as needed
  }, [webcamRef]);

  return (
    <div
      style={{
        width: "22vw",
        height: "38vh",
        borderRadius: "100%",
        background: "red",
        overflow: "hidden",
        padding: "1rem",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Webcam
        audio={false}
        height={720}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={1280}
        className="myWebCamFD"
        style={{
          aspectRatio: "auto",
          width: "auto",
          height: "auto",
          // position: "absolute",
          // top: "0px",
          // left: "0px",
        }}
      />
      {/* <button onClick={capture}>Capture photo</button> */}
    </div>
  );
};

// *********************************  TESTING WEB CAM FOR ADD EMPLOYEE INP PAGE *********************************

// *********************************  TESTING CANVAS WHITE BOARD *********************************

function ImageCanvas() {
  const canvasRef = useRef(null),
    divRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageDim, setImageDim] = useState({ width: 0, height: 0 });
  const [rectangles, setRectangles] = useState([]);
  const [drawing, setDrawing] = useState(false);
  const [updatedImage, setUpdatedImage] = useState("");
  const [canvasZmScale, setCanvasZmScale] = useState(1);

  useEffect(() => {
    const canvas = canvasRef.current,
      divCont = divRef.current;
    const ctx = canvas.getContext("2d");

    // Draw the uploaded image
    if (image) {
      // console.log("canvas dimensions ====>",divCont.offsetWidth,"div container ---->",divCont);

      // Calculate the scaling factors for width and height
      const widthScale = divCont.offsetWidth / imageDim.width;
      const heightScale = divCont.offsetHeight / imageDim.height;

      // console.log("scale w ===>", widthScale);
      // console.log("scale h ===>", heightScale);

      // Use the minimum scaling factor to ensure the entire image fits inside the canvas
      const scale = Math.min(widthScale, heightScale);

      // console.log("scale factor ===>", scale);

      // Calculate the new dimensions for the scaled image
      const scaledWidth = imageDim.width * scale;
      const scaledHeight = imageDim.height * scale;

      // console.log("scaled, height ====>", scaledHeight);
      // console.log("scaled, width ====>", scaledWidth);

      ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);
    }

    // Draw rectangles
    rectangles.forEach((rect) => {
      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
    });

    const dataUrl = canvas.toDataURL("image/png");
    setUpdatedImage(dataUrl);
  }, [image, rectangles]);

  const handleImageUpload = (e) => {
    const uploadedImage = e.target.files[0];
    const img = new Image();
    img.src = URL.createObjectURL(uploadedImage);
    img.onload = () => {
      // console.log("original image height : ", img.height);
      // console.log("original image width : ", img.width);

      setImage(img);
      setImageDim({ width: img.width, height: img.height });
    };
  };

  // -------------------------------------------- working handle canvas click --------------------

  // const handleCanvasClick = (e) => {
  //   if (!image) return;

  //   const rect = canvasRef.current.getBoundingClientRect();

  //   console.log("rectangle left ===>", rect.left);
  //   console.log("rectangle top ===>", rect.top);

  //   console.log("clientX ===>", e.clientX);
  //   console.log("clientY ===>", e.clientY);

  //   const x = e.clientX - rect.left;
  //   const y = e.clientY - rect.top;

  //   if (drawing) {
  //     // Finish drawing the rectangle
  //     const lastRect = rectangles[rectangles.length - 1];
  //     const width = x - lastRect.x;
  //     const height = y - lastRect.y;
  //     setRectangles([
  //       ...rectangles,
  //       { x: lastRect.x, y: lastRect.y, width, height }
  //     ]);
  //     setDrawing(false);
  //   } else {
  //     // Start drawing a new rectangle
  //     setRectangles([...rectangles, { x, y, width: 0, height: 0 }]);
  //     setDrawing(true);
  //   }
  // };

  // -------------------------------------------- working handle canvas click --------------------

  const handleCanvasClick = (e) => {
    if (!image) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const zoomFactor = parseFloat(canvasRef.current.style.zoom) || 1;
    const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;

    console.log("rectangle left ===>", rect.left);
    console.log("rectangle top ===>", rect.top);

    console.log("clientX ===>", e.clientX);
    console.log("clientY ===>", e.clientY);

    const x = (e.clientX - rect.left) / zoomFactor + scrollX;
    const y = (e.clientY - rect.top) / zoomFactor + scrollY;

    if (drawing) {
      // Finish drawing the rectangle
      const lastRect = rectangles[rectangles.length - 1];
      const width = (x - lastRect.x) * zoomFactor + scrollX;
      const height = (y - lastRect.y) * zoomFactor + scrollY;
      setRectangles([
        ...rectangles,
        { x: lastRect.x, y: lastRect.y, width, height },
      ]);
      setDrawing(false);
    } else {
      // Start drawing a new rectangle
      setRectangles([...rectangles, { x, y, width: 0, height: 0 }]);
      setDrawing(true);
    }
  };

  useEffect(() => {
    const divCont = divRef.current,
      w = divCont.offsetWidth,
      h = divCont.offsetHeight;

    canvasRef.current.width = w;
    canvasRef.current.height = h;

    console.log(
      "off set dimensions canvas ====>",
      canvasRef.current.offsetWidth
    );
  }, []);

  // useEffect(()=>{
  //   let w = canvasRef.current.offsetWidth;
  //   let h = canvasRef.current.offsetHeight;

  //   console.log("scaled width ====>",w);
  //   console.log("scaled height ====>",h);
  // },[canvasRef.current]);

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      <button
        onClick={() => {
          setCanvasZmScale((prev) => prev + 0.1);
        }}
      >
        zoom in
      </button>
      <button
        onClick={() => {
          setCanvasZmScale((prev) => {
            if (prev <= 0.1) {
              console.log("max zoom limit reached!");
              return prev;
            } else {
              // console.log("max zoom limit reached!");
              return prev - 0.1;
            }
          });
        }}
      >
        zoom out
      </button>
      <div
        style={{ width: "50%", height: "30rem", overflow: "scroll" }}
        ref={divRef}
      >
        <canvas
          ref={canvasRef}
          // width={2000} // 800
          // height={2000} // 450

          onClick={handleCanvasClick}
          onResize={() => {
            console.log("canvas resize called!");
          }}
          style={{ border: "1px solid black", zoom: canvasZmScale }}
        />
      </div>
      {/* <img src={updatedImage} alt="updated_image" /> */}
    </div>
  );
}

// import React, { useState, useRef, useEffect } from 'react';

const ImageEditorNew = () => {
  const [image, setImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [rectangles, setRectangles] = useState([]);
  const canvasRef = useRef(null);
  const canvasWrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (image) {
      const scale = canvas.width / canvasWrapperRef.current.clientWidth;
      const newWidth = image.width * scale * zoom;
      const newHeight = image.height * scale * zoom;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      rectangles.forEach((rect) => {
        ctx.strokeStyle = "#ff0000";
        ctx.lineWidth = 2;
        ctx.strokeRect(
          rect.x * scale * zoom,
          rect.y * scale * zoom,
          rect.width * scale * zoom,
          rect.height * scale * zoom
        );
      });
    }
  }, [image, zoom, rectangles]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        setImage(img);
        setZoom(1);
        setRectangles([]);
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Maximum zoom level set to 3
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1)); // Minimum zoom level set to 0.1
  };

  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / zoom;
    const y = (e.clientY - rect.top) / zoom;

    setDrawing(true);
    setStartX(x);
    setStartY(y);
  };

  const handleMouseMove = (e) => {
    if (!drawing) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / zoom;
    const y = (e.clientY - rect.top) / zoom;

    setCurrentX(x);
    setCurrentY(y);
  };

  const handleMouseUp = () => {
    setDrawing(false);
    const width = currentX - startX;
    const height = currentY - startY;

    if (width !== 0 && height !== 0) {
      const newRectangle = {
        x: Math.min(startX, currentX),
        y: Math.min(startY, currentY),
        width: Math.abs(width),
        height: Math.abs(height),
      };
      setRectangles([...rectangles, newRectangle]);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      <button onClick={handleZoomIn}>Zoom In</button>
      <button onClick={handleZoomOut}>Zoom Out</button>
      <div
        ref={canvasWrapperRef}
        style={{ width: "70vw", height: "70vh", overflow: "auto" }}
      >
        <canvas
          ref={canvasRef}
          width={image ? image.width * zoom : 0}
          height={image ? image.height * zoom : 0}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        {image && (
          <img
            ref={imageRef}
            src={image.src}
            alt="Uploaded"
            style={{ display: "none" }}
          />
        )}
      </div>
      <div>
        {rectangles.map((rect, index) => (
          <div key={index}>
            X: {rect.x}, Y: {rect.y}, Width: {rect.width}, Height: {rect.height}
          </div>
        ))}
      </div>
    </div>
  );
};

// export default ImageEditor;

// *********************************  TESTING CANVAS WHITE BOARD *********************************
