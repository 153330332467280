// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_frame_container{
    width: 100%;
    height: 100%;
    flex: auto;
    background: #EBEBEB;
    display: flex;
    justify-content: center;
    padding: 0rem 1rem;
    box-sizing: border-box;
}
.main_frame_sideNav_container{
    min-width: 15%;
    max-width: 20%;
    background: white;
    box-sizing: border-box;
    padding: 1rem;
    min-height: 100vh;
}
.main_frame_right_container{
    flex: auto;
    display: flex;
    flex-direction: column;
}
.main_frame_head_container{}
.main_frame_screen_container{
    background: #F6F9FC;
    /* padding-left: 1rem; */
    margin-left: 1rem;
    flex: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/mainFrame/mainFrame.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,cAAc;IACd,iBAAiB;IACjB,sBAAsB;IACtB,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;AACA,2BAA2B;AAC3B;IACI,mBAAmB;IACnB,wBAAwB;IACxB,iBAAiB;IACjB,UAAU;AACd","sourcesContent":[".main_frame_container{\n    width: 100%;\n    height: 100%;\n    flex: auto;\n    background: #EBEBEB;\n    display: flex;\n    justify-content: center;\n    padding: 0rem 1rem;\n    box-sizing: border-box;\n}\n.main_frame_sideNav_container{\n    min-width: 15%;\n    max-width: 20%;\n    background: white;\n    box-sizing: border-box;\n    padding: 1rem;\n    min-height: 100vh;\n}\n.main_frame_right_container{\n    flex: auto;\n    display: flex;\n    flex-direction: column;\n}\n.main_frame_head_container{}\n.main_frame_screen_container{\n    background: #F6F9FC;\n    /* padding-left: 1rem; */\n    margin-left: 1rem;\n    flex: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
