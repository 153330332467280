import { useState, useEffect, useRef } from "react";
import "./connectCameraSccren.css";

import { FormInp } from "../../../utility/formElements/formElements";
import { FileSyncButton } from "../../../utility/button/button";

import { MyButton } from "../connectStorageScreen/connectStorageScreen";
import { MyTable } from "../connectStorageScreen/connectStorageScreen";

import {
  getAllCameraInfo,
  addCamera,
  editCameraDetails,
  toggleCamera,
} from "../../../action/action";

import FormInpWrpprFaceDetection from "../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";

import { Filter, Search, SquarePen, Camera } from "lucide-react";
import { FaCircleDot } from "react-icons/fa6";

import { TableComp } from "../../../utility/twElems/table/table";
import { AddCameraForm } from "./addCameraForm";

import { toast } from "react-toastify";
import { generateCameraName } from "../../../utility/functions/helperFunc";
import { FormInput } from "../../../utility/twElems/input/input";
import { ButtonComp } from "../../../utility/twElems/Button/button";

const initialAddCamFormVal = {
  name: "",
  description: "",
  location: "",
  building: "",
  floor: "",
  section: "",
  user_name: "",
  password: "",
  ip_address: "",
  port: "",
  cam_id: "",
};

function ConnectCameraScreen() {
  const [cameraDetails, setCameraDetails] = useState({
    userName: "",
    password: "",
    cameraIP: "",
    portNumber: "",
  });
  const [cameraName, setCameraName] = useState({
    name: "",
    description: "",
    building_name: "",
    floor: "",
    city: "",
    area: "",
  });

  const [cameraListArr, setCameraListArr] = useState([]);
  const [addCameraModal, setAddCameraModal] = useState(false);
  const [selCameraFromList, setSelCameraFromList] = useState(null);

  const [addCamFormVal, setAddCamFormVal] = useState(initialAddCamFormVal);

  const addCamFormSubmitMode = useRef(null);

  const [addCameraErrObj, setAddCameraErrObj] = useState({
    name: "",
    camera_description: "",
  });

  const [cameraDetailsErrObj, setCameraDetailsErrObj] = useState({
    camera_id: "",
    user_name: "",
    password: "",
    ip_address: "",
    port_number: "",
  });

  // headers for camera list table ************

  const headers = ["Camera List", "Active/Inactive"];

  const userData = [
    ["camera001", "active"],
    ["camera002", "active"],
    ["camera003", "in-active"],
    ["camera004", "in-active"],
  ];

  const headers2 = [
    "Camera Name",
    "Camera Building",
    "Location",
    "Floor",
    "Section",
    "Active/In-Active",
    "Edit",
    "Change Status",
  ];
  const data2 = [
    {
      "Camera Name": "camera name",
      "Camera Building": "camera name",
      Location: "camera name",
      Floor: "camera name",
      Section: "camera name",
      "Active/In-Active": "active",
      Edit: "Edit",
      "Change Status": "Disconnect",
    },
    {
      "Camera Name": "camera name",
      "Camera Building": "camera name",
      Location: "camera name",
      Floor: "camera name",
      Section: "camera name",
      "Active/In-Active": "In-active",
      Edit: "Edit",
      "Change Status": "Connected",
    },
  ];

  // headers for camera list table ************

  function handleInputChangeCameraDetl(v) {
    let val = v?.target?.value,
      label = v?.target?.name;
    console.log("getting input change in add cctv camera", val, label);
    setCameraDetails({
      ...cameraDetails,
      [label]: val,
    });
  }

  async function handleChangeCameraStatus(v, status) {
    // console.log("changing camera status", v?.row?.meta_data?.camera_id, status);
    let data = new FormData();
    data?.append("camera_id", v?.row?.meta_data?.camera_id);
    data?.append("status", status);
    let resp = await editCameraDetails(data);
    if (resp?.success) {
      await fetchCameraDetails(false);
    }
  }

  function handleInputChangeCameraName(v, isUpdateCameraName = false) {
    let val = v?.target?.value,
      label = v?.target?.name;
    if (isUpdateCameraName) {
      const name = generateCameraName({ ...cameraName, [label]: val });
      setCameraName({ ...cameraName, [label]: val, name: name });
    } else {
      setCameraName({ ...cameraName, [label]: val });
    }
  }

  async function fetchCameraDetails(isInitial = true) {
    let response = await getAllCameraInfo();

    console.log(
      "the response fetch camera details =======>",
      response?.data?.AllCamera
    );
    if (!response?.success) return;

    if (isInitial) {
      let myDetl = response?.data?.AllCamera[0];
      console.log("my");
      setSelCameraFromList({ ...myDetl });
      setCameraDetails({
        ...cameraDetails,
        userName: myDetl?.user_name,
        password: myDetl?.password,
        cameraIP: myDetl?.ip_address,
        portNumber: myDetl?.port_number,
      });
    }
    if (response?.data?.AllCamera) {
      /*
      
      {
      "Camera Name": "camera name",
      "Camera Building": "camera name",
      Location: "camera name",
      Floor: "camera name",
      Section: "camera name",
      "Active/In-Active": "active",
      Edit: "Edit",
      "Change Status": "Disconnect",
    }

      */

      let myUpdatedCameraList = [];
      myUpdatedCameraList = response?.data?.AllCamera?.map((elem) => {
        let myObj = {
          "Camera Name": elem?.camera_name,
          "Camera Building": elem?.building_name,
          Location: elem?.city,
          Floor: elem?.floor,
          Section: elem?.area,
          "Active/In-Active": elem?.camera_status ? "active" : "in-active",
          Edit: "Edit",
          "Change Status": elem?.camera_status ? "Connected" : "Disconnect",
          meta_data: {
            ...elem,
          },
        };
        return myObj;
      });

      setCameraListArr(myUpdatedCameraList);
    }

    // if(!isInitial){
    //   let myObj = response?.data?.AllCamera?.forEach((e)=>{
    //     if(e?.camera_id === myId){
    //       return e;
    //     }
    //   })
    //   return {camDetl:myObj}
    // }
  }

  function checkEmptyFieldsForAddCamera(data) {
    let myErrObj = {},
      isOkay = true;
    for (let [key, val] of data?.entries()) {
      myErrObj[key] = val ? false : true;
      if (!val) isOkay = false;
    }

    return { myErrObj, isOkay };
  }

  async function addCameraToList(meta_data = {}) {
    // if (!cameraName?.name) {
    //   toast.info("please enter some input to add camera");
    //   // console.log("please enter some input to add camera");
    //   return;
    // }

    /*

{
    "name": "abc",
    "city": "Jaipur",
    "building_name": "Marktine",
    "floor": "2nd",
    "area": "l_corner",
    "camera_description": "NA",
    "user_name": "cam_1",
    "password": "Mark@1234",
    "ip_address": "172.10.10.18",
    "port_number": 554
}

*/

    // let data = new FormData();
    // data?.append("name", cameraName?.name);
    // data?.append("camera_description", cameraName?.description);
    // data?.append("city", cameraName?.city);
    // data?.append("building_name", cameraName?.building_name);
    // data?.append("floor", cameraName?.floor);
    // data?.append("area", cameraName?.area);

    // let { myErrObj, isOkay } = checkEmptyFieldsForAddCamera(data);
    // console.log("error object ====>", myErrObj);
    // setAddCameraErrObj({ ...myErrObj });
    // if (!isOkay) return;
    const payload = {
      name: meta_data?.name,
      city: meta_data?.location,
      building_name: meta_data?.premises,
      floor: meta_data?.floor,
      area: meta_data?.section,
      camera_description: meta_data?.description,
      user_name: meta_data?.user_name,
      password: meta_data?.password,
      ip_address: meta_data?.ip_address,
      port_number: meta_data?.port || 554,
    };
    // console.log("add camera payload ===>", payload);
    let resp = await addCamera(payload);
    if (resp?.success) {
      // setCameraDetails({ ...cameraDetails, name: "" });
      setCameraName({ name: "", description: "" });
      fetchCameraDetails();
    }
  }

  function handleSelCamera(v) {
    setSelCameraFromList(v);
    setCameraDetails({
      ...cameraDetails,
      userName: v?.user_name,
      password: v?.password,
      cameraIP: v?.ip_address,
      portNumber: v?.port_number,
    });
    setCameraDetailsErrObj({
      camera_id: "",
      user_name: "",
      password: "",
      ip_address: "",
      port_number: "",
    });
  }

  async function updateCameraDetails(meta_data = {}) {
    // console.log("meta data in update camera details ===>", meta_data);

    // return;

    let cameraID = selCameraFromList?.camera_id,
      selCamDetails = selCameraFromList;
    let data = new FormData();
    /*
    //     "name": "abc", ---> done
//     "city": "Jaipur", ---> done
//     "building_name": "Marktine", ---> done
//     "floor": "2nd", ---> done
//     "area": "l_corner", ---> done
//     "camera_description": "NA", ---> done
//     // "user_name": "cam_1", ---> done
//     "password": "Mark@12345", ---> done
//     "ip_address": "172.10.10.18", ---> done
//     "port_number": 554, ---> done
    "status": "inactive", ---> done
    "camera_id":1 ---> done
    */
    let camDetailsForm = cameraDetails;

    data?.append("camera_id", meta_data?.cam_id);
    data?.append("user_name", meta_data?.user_name);
    data?.append("password", meta_data?.password);
    data?.append("ip_address", meta_data?.ip_address);
    data?.append("port_number", meta_data?.port);
    data?.append("camera_description", meta_data?.description);
    data?.append("city", meta_data?.location);
    data?.append("building_name", meta_data?.premises);
    data?.append("floor", meta_data?.floor);
    data?.append("area", meta_data?.section);
    data?.append("name", meta_data?.name);

    let { myErrObj, isOkay } = checkEmptyFieldsForAddCamera(data);
    data?.append("status", meta_data?.status);
    console.log("error object ====>", myErrObj);
    setCameraDetailsErrObj({ ...myErrObj });
    if (!isOkay) return;

    let resp = await editCameraDetails(data);

    console.log("getting resp from edit camera detl =====>", resp);
    if (resp?.success) {
      toast.success("edit success !");
      await fetchCameraDetails(false);
      setAddCameraModal(false);
      // setSelCameraFromList({ ...selCamDetails });
      // setCameraDetails({ ...camDetailsForm });
    }
  }

  async function toggleCameraReq() {
    let data = new FormData();
    /*
    camera_status:True
camera_id:2
    */

    console.log("getting camera id =====>", selCameraFromList?.camera_id);
    data.append("camera_status", true);
    data.append("camera_id", selCameraFromList?.camera_id);
    let req = await toggleCamera(data);

    console.log("camera toggle request ===>", req);
  }

  // -------------------------------------------------- new updates -------------------------------------------------------------

  async function handleSubmitCallForAddCameraFormModal(meta_data) {
    if (addCamFormSubmitMode.current === "add") {
      console.log("add camera submit button clicked");
      await addCameraToList(meta_data);
    }
    if (addCamFormSubmitMode.current === "edit") {
      await updateCameraDetails(meta_data);
    }
  }

  function handleEditCameraByTable(details) {
    console.log("details clicked edit camera button", details?.row);

    addCamFormSubmitMode.current = "edit";
    const data = details?.row?.meta_data;
    /*
    
    row
: 
Active/In-Active: "active"
Camera Building: "1"
Camera Name: "Raipur office"
Change Status: "Connected"
Edit: "Edit"
Floor: "1"
Location: "Raipur"
Section: "left"

    */
    const addCameraFormInitialValues = {
      name: data?.camera_name || "",
      description: data?.camera_description || "",
      location: data?.city || "",
      building: data?.building_name || "",
      floor: data?.floor || "",
      section: data?.area || "",
      user_name: data?.user_name || "",
      password: data?.password || "",
      ip_address: data?.ip_address || "",
      port: data?.port_number || "",
      cam_id: data?.camera_id || "",
    };
    setAddCamFormVal(addCameraFormInitialValues);
    setAddCameraModal(true);
  }

  // -------------------------------------------------- new updates -------------------------------------------------------------

  useEffect(() => {
    fetchCameraDetails();
  }, []);
  return (
    <>
      {/* <div className="connect_storage_screen_wrppr"> */}
      {/* ***************************************************************** */}

      <div className="connect_storage_screen_container w-full h-full flex flex-col">
        {addCameraModal && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10 flex items-center justify-center">
            <AddCameraForm
              handleClose={() => {
                setAddCameraModal(false);
              }}
              SubmitMode={addCamFormSubmitMode.current}
              initialValues={addCamFormVal}
              handleEmptyInitVal={(val) => setAddCamFormVal(val)}
              handleSubmitCallback={handleSubmitCallForAddCameraFormModal}
            />
          </div>
        )}
        <div className="flex flex-row justify-between">
          <div className="">
            <h6 class="font-manrope text-xl font-bold text-left text-blue-900">
              Connect Camera’s
            </h6>

            <p class="font-manrope text-sm font-normal leading-5 text-left mb-4">
              Connect your own Camera’s with our software{" "}
            </p>
          </div>
          <div className="h-fit">
            <ButtonComp
              label="Add Camera"
              className="bg-blue-900 text-white p-4 font-manrope font-semibold rounded-md flex gap-4"
              clickHandler={() => {
                addCamFormSubmitMode.current = "add";
                setAddCameraModal(true);
              }}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-4/12 flex items-center gap-2 bg-white p-3 rounded-lg shadow">
            <Search className="text-slate-300" />
            <div className="flex-1">
              <FormInput
                important={false}
                placehldr="Search Camera Name/ Camera ID"
                className="bg-transparent"
              />
            </div>
          </div>
          <ButtonComp
            label="Filter"
            className="bg-blue-900 text-white p-4 font-manrope font-semibold rounded-md flex gap-4"
            clickHandler={() => console.log("clicked filter")}
            icon={<Filter />}
          />
        </div>

        <div className="">
          <TableComp
            headers={headers2}
            data={cameraListArr}
            actions={{
              Edit: {
                action: (info) => {
                  return (
                    <ButtonComp
                      className="bg-green-600 text-white p-2 font-manrope font-semibold rounded-md flex gap-4 mx-auto"
                      clickHandler={() => {
                        handleEditCameraByTable(info);
                      }}
                      icon={<SquarePen size={18} />}
                    />
                  );
                },
              },
              "Active/In-Active": {
                action: (info) => {
                  let check = info?.row[info?.cell],
                    buttonStyle =
                      "bg-inherit font-manrope text-slate-900 flex gap-4 mx-auto items-center";
                  if (check === "active") {
                    return (
                      <ButtonComp
                        label="Active"
                        className={buttonStyle}
                        icon={<FaCircleDot className="text-green-500" />}
                      />
                    );
                  } else {
                    return (
                      <ButtonComp
                        label="In-Active"
                        className={buttonStyle}
                        icon={<FaCircleDot className="text-red-500" />}
                      />
                    );
                  }
                },
              },
              "Change Status": {
                action: (info) => {
                  let check = info?.row[info?.cell];
                  if (check === "Connected") {
                    return (
                      <ButtonComp
                        label="Disconnect"
                        className="bg-red-200 text-red-600 p-2 font-manrope font-semibold rounded-md flex gap-4 mx-auto"
                        clickHandler={() =>
                          handleChangeCameraStatus(info, "inactive")
                        }
                      />
                    );
                  } else {
                    return (
                      <ButtonComp
                        label="Connect"
                        className="bg-green-600 text-white p-2 font-manrope font-semibold rounded-md flex gap-4 mx-auto"
                        clickHandler={() =>
                          handleChangeCameraStatus(info, "active")
                        }
                      />
                    );
                  }
                },
              },
              Floor: {
                action: (info) => {
                  let check = info?.row[info?.cell],
                    buttonStyle =
                      "bg-blue-900 font-manrope text-white text-sm flex mx-auto gap-1 items-center p-2 rounded-md";

                  return (
                    <ButtonComp
                      label={check}
                      className={buttonStyle}
                      icon={<Camera className="text-white" size={18} />}
                    />
                  );
                },
              },
            }}
          />
        </div>

        {/* <div className="connect_storage_screen_addstorage_form_cont connect_camera_screen_add_cam_form_cont connect_cam_screen_gaping">
          
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"Camera Name"}
              placeHldr={"Enter Camera Name"}
              nameStr={"name"}
              value={cameraName?.name}
              handlChange={handleInputChangeCameraName}
              error={addCameraErrObj?.name && "field can't be left empty"}
            />
          </FormInpWrpprFaceDetection>
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"Camera Description"}
              placeHldr={"Enter Camera Description"}
              nameStr={"description"}
              value={cameraName?.description}
              handlChange={handleInputChangeCameraName}
              error={
                addCameraErrObj?.camera_description &&
                "field can't be left empty"
              }
            />
          </FormInpWrpprFaceDetection>
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"City"}
              placeHldr={"Enter City"}
              nameStr={"city"}
              value={cameraName?.city}
              handlChange={(e) => handleInputChangeCameraName(e, true)}
              error={addCameraErrObj?.city && "field can't be left empty"}
            />
          </FormInpWrpprFaceDetection>
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"Building Name"}
              placeHldr={"Enter Building Name"}
              nameStr={"building_name"}
              value={cameraName?.building_name}
              handlChange={(e) => handleInputChangeCameraName(e, true)}
              error={
                addCameraErrObj?.building_name && "field can't be left empty"
              }
            />
          </FormInpWrpprFaceDetection>
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"Floor"}
              placeHldr={"Enter Floor"}
              nameStr={"floor"}
              value={cameraName?.floor}
              handlChange={(e) => handleInputChangeCameraName(e, true)}
              error={addCameraErrObj?.floor && "field can't be left empty"}
            />
          </FormInpWrpprFaceDetection>
          <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
            <FormInp
              title={"Area"}
              placeHldr={"Enter area"}
              nameStr={"area"}
              value={cameraName?.area}
              handlChange={(e) => handleInputChangeCameraName(e, true)}
              error={addCameraErrObj?.area && "field can't be left empty"}
            />
          </FormInpWrpprFaceDetection>
          
          <div
            className="connect_camera_screen_add_cam_btn_wrppr"
            onClick={() => addCameraToList()}
          >
            <MyButton label="Add Camera">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  d="M8.16406 3.83331V13.1666"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.49756 8.5H12.8309"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </MyButton>
          </div>
        </div> */}
        {/* <div className="connect_storage_screen_user_activity_area">
          <div className="connect_storage_screen_mytable_wrrpr">
            <MyTable
              headers={headers}
              data={cameraListArr}
              handleSelCamera={(v) => handleSelCamera(v)}
              currentSelCamera={selCameraFromList}
            />
          </div>
          <div className="connect_storage_screen_user_details_form connect_cam_screen_gaping">
            <h2>Enter The Details</h2>
            <p className="connect_storage_screen_container_p">
              Connect your own Camera’s with our software
            </p>

            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Username *"}
                placeHldr={"Enter Username"}
                nameStr={"userName"}
                value={cameraDetails?.userName}
                handlChange={handleInputChangeCameraDetl}
                error={
                  cameraDetailsErrObj?.user_name && "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Passwords *"}
                placeHldr={"Enter Passwords"}
                nameStr={"password"}
                value={cameraDetails?.password}
                handlChange={handleInputChangeCameraDetl}
                error={
                  cameraDetailsErrObj?.password && "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"IP Address *"}
                placeHldr={"Enter IP Address"}
                nameStr={"cameraIP"}
                value={cameraDetails?.cameraIP}
                handlChange={handleInputChangeCameraDetl}
                error={
                  cameraDetailsErrObj?.ip_address && "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection styleObj={{ maxWidth: "100%" }}>
              <FormInp
                title={"Port *"}
                placeHldr={"Enter Port"}
                nameStr={"portNumber"}
                value={cameraDetails?.portNumber}
                handlChange={handleInputChangeCameraDetl}
                error={
                  cameraDetailsErrObj?.port_number &&
                  "Field can't be left empty"
                }
              />
            </FormInpWrpprFaceDetection>
            <div className="connect_storage_screen_connect_to_strg_btn_wrppr">
              <FileSyncButton
                title="Connect Camera"
                handleClick={() => updateCameraDetails()}
              />
            </div>
          </div>
        </div> */}
        {/* <div className="selStorageModalActvArea_btnWrppr">
          <FileSyncButton
            title="Save"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
          <FileSyncButton
            title="Cancel"
            stylObj={{
              background: "white",
              color: "#05306B",
              // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
        </div> */}
      </div>

      {/* ***************************************************************** */}
      {/* </div> */}
    </>
  );
}

export default ConnectCameraScreen;
