// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notif_wrppr_upload_screen {
  width: 10vw;
  height: 7vh;
  background-color: white;
  border-radius: 8px;
  padding: 0px 0px 0px 1rem;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 8rem;
  right: 26px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.loadingSpinner_wrppr_upload_file_screen {
  position: relative;
  margin-right: 0.5rem;
}

.css-18lrjg1-MuiCircularProgress-root {
  color: #1976d2 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/screens/uploadToCloudScreen/uploadToCloudScreen.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,WAAW;EACX,wEAAwE;AAC1E;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".notif_wrppr_upload_screen {\n  width: 10vw;\n  height: 7vh;\n  background-color: white;\n  border-radius: 8px;\n  padding: 0px 0px 0px 1rem;\n  display: flex;\n  align-items: center;\n  position: absolute;\n  z-index: 99;\n  top: 8rem;\n  right: 26px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\n}\n\n.loadingSpinner_wrppr_upload_file_screen {\n  position: relative;\n  margin-right: 0.5rem;\n}\n\n.css-18lrjg1-MuiCircularProgress-root {\n  color: #1976d2 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
