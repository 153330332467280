import LoadingComponent from "../utility/loadingSpinner/loadingSpinner";

import { useState,useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { MyContext } from "../context/context";
import { fetchUser } from "../action/action";


function ProtectionWrppr({ children, status = "", redirection = "" }) {
    const [renStatus, setRenStatus] = useState("loading"); // success loading
    const { user } = useContext(MyContext);

    const navigate = useNavigate();

    useEffect(() => {
      checkProfile();
    }, []);
  
    async function checkProfile() {
      try {
        let myCookie = "";
  
        if (status === "admin") {
          // myCookie = Cookies.get(ADMIN_COOKIE);
        //   myCookie = localStorage.getItem(process?.env?.REACT_APP_Access_Token_Prop_Name);
        //   console.log("cookies get response admin", myCookie);
        }
        if (status === "user") {
          // myCookie = Cookies.get(USER_COOKIE);
          let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name;
          myCookie = localStorage.getItem(acc_token_key_name);
        //   console.log("cookies get response user", myCookie);
        }
        if (!myCookie) {
            navigate(redirection);
          return;
        }
        let myResp = await fetchUser(myCookie);
        console.log("protected route profile response:===>", myResp);
        if (myResp.success) {
          const usrData = myResp?.data?.data;
          console.log("user data ==============>",usrData);
          user?.updateUserDetails({ ...usrData });
          setRenStatus("success");
        } else {
          setRenStatus("failed");
        }
      } catch (error) {
        setRenStatus("failed");
        console.log("error while checking admin", error);
      }
    }
  
    if (renStatus === "loading")
      return (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent
            styl={{
              position: "relative",
              top: "0px",
              bottom: "0px",
              right: "0px",
            }}
          />
        </div>
      );
    if (renStatus === "success") return <div className="w-full h-full">{children}</div>;
    if (renStatus === "failed") {
    //   console.log("ren status", renStatus);
      navigate(redirection);
      return null;
    }
  }
  
  export default ProtectionWrppr;