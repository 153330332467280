import "./progressBar.css";
import React, { useState, useRef } from "react";

const ProgressBarmod = ({ currentTime, duration, onSeek, progBarWidth }) => {
  const [hoveredTime, setHoveredTime] = useState(null);
  const progressBarRef = useRef(null);

  const handleSeek = (event) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const percentage = offsetX / rect.width;
    const seekTime = duration * percentage;
    onSeek(seekTime);
  };

  const handleHover = (event) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const percentage = offsetX / rect.width;
    const hoverTime = duration * percentage;
    setHoveredTime(hoverTime);
  };

  return (
    <div
      ref={progressBarRef}
      style={{
        width: "100%",
        height: "0.2rem",
        backgroundColor: "#D9D9D9",
        cursor: "pointer",
        borderRadius:"1rem",
      }}
      onClick={handleSeek}
      onMouseMove={handleHover}
      onMouseLeave={() => setHoveredTime(null)}
    >
      <div
        style={{
          position: "absolute",
          top: "-5px",
          left: hoveredTime ? `${(hoveredTime / duration) * 100}%` : 0,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "red",
          transform: "translateX(-50%)",
          visibility: hoveredTime ? "visible" : "hidden",
        }}
      />
      <div
        style={{
          width: `${(currentTime / duration) * 100}%`,
          
        }}
        className="progress_bar_current_status"
      />
    </div>
  );
};

export default ProgressBarmod;
