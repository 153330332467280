import { toast } from "react-toastify";
// import { useContext } from "react";
import {
  getCameraBuildings,
  getCameraFloor,
  getCameraSection,
} from "../../action/action";

// import { MyContext } from "../../context/context";

export function reDirectTo(str = "") {
  let crrntURL = window.location.href;
  crrntURL = crrntURL.split("/")[0];
  crrntURL = crrntURL + str;
  window.location.href = crrntURL;
}

export function readAndUpdateRoutes(str = "") {
  let crrntURL = window.location.href;
  if (!crrntURL.includes("/admin")) return;
  crrntURL = crrntURL.split("/admin");
  console.log("readAndUpdate111111----------->", crrntURL);
  crrntURL = crrntURL[crrntURL.length - 1];
  console.log("readAndUpdateRoutes----------->", crrntURL);

  if (crrntURL === "/voiceSearchPanel") {
    console.log("inside voiceSearchPanel----------->", crrntURL);
  } else if (crrntURL === "/addUser") {
    console.log("inside addUser----------->", crrntURL);
  } else if (crrntURL === "/app") {
    console.log("inside app----------->", crrntURL);
  } else if (crrntURL === "") {
    console.log("inside admin----------->");
  }
}

export function validateEmail(email) {
  let err = "";
  if (!email) {
    err = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    err = "Invalid email format.";
  }

  return err;
}
export function validateMobile(mobileNumber) {
  let err = "";
  if (!mobileNumber) {
    err = "Mobile number is required.";
  } else if (!/^\d{10}$/.test(mobileNumber)) {
    err = "Invalid mobile number format. It should contain 10 digits.";
  }

  return err;
}
// export function validateForm(firstName, lastName, email, mobileNumber) {
//   let errors = {};

//   if (!firstName) {
//     errors.firstName = "First name is required.";
//   }

//   if (!lastName) {
//     errors.lastName = "Last name is required.";
//   }

//   if (!email) {
//     errors.email = "Email is required.";
//   } else if (!/\S+@\S+\.\S+/.test(email)) {
//     errors.email = "Invalid email format.";
//   }

//   if (!mobileNumber) {
//     errors.mobileNumber = "Mobile number is required.";
//   } else if (!/^\d{10}$/.test(mobileNumber)) {
//     errors.mobileNumber =
//       "Invalid mobile number format. It should contain 10 digits.";
//   }
//   let arr = Object.keys(errors);
//   if (arr.length === 0) {
//     return false;
//   }
//   // console.log("arr from validation function..",arr);
//   return errors;
// }

export function formatChatDate(inputDate) {
  const currentDate = new Date();
  const chatDate = new Date(inputDate);

  // Check if the date is today
  if (
    chatDate.getDate() === currentDate.getDate() &&
    chatDate.getMonth() === currentDate.getMonth() &&
    chatDate.getFullYear() === currentDate.getFullYear()
  ) {
    return `Today, ${formatTime(chatDate)}`;
  }

  // Check if the date is yesterday
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  if (
    chatDate.getDate() === yesterday.getDate() &&
    chatDate.getMonth() === yesterday.getMonth() &&
    chatDate.getFullYear() === yesterday.getFullYear()
  ) {
    return `Yesterday ${formatTime(chatDate)}`;
  }

  // For older dates
  const formattedDate = chatDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return `${formattedDate}, ${formatTime(chatDate)}`;
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}${ampm}`;
}

// Example usage
// const inputDate = "2023-10-04T11:39:53.366765Z";
// const formattedChatDate = formatChatDate(inputDate);
// console.log(formattedChatDate);

export async function playAudioOnce(audioFilePath = "") {
  if (!audioFilePath) {
    console.log("empty audio url, early return........");
    return { status: false };
  }
  return new Promise((resolve, reject) => {
    console.log("getting audio url from component------>");
    const audio = new Audio(audioFilePath);

    audio.onerror = () => {
      console.error("Error loading audio:", audioFilePath);

      // You can provide user-friendly feedback here, like displaying a message on the UI

      toast.error("error loading the audio file");

      reject({
        status: false,
      });
    };

    audio.onloadedmetadata = () => {
      // Play the audio
      audio.play();
      console.log("playing the audio file.....");
      // Function to stop the audio
      const stopAudio = () => {
        audio.pause();
        audio.currentTime = 0;
      };
      resolve({
        stopAudio,
        audioDuration: audio.duration * 1000, // Convert seconds to milliseconds
        status: true,
      });
      // Return an object with stopAudio function and audioDuration in milliseconds

      // return {
      //   stopAudio,
      //   audioDuration: audio.duration * 1000, // Convert seconds to milliseconds
      // };
    };
  });
}

export async function waitingFunction(milisec = 0) {
  return new Promise((res) => {
    setTimeout(() => {
      console.log("wait ended -------->");
      res(true);
    }, milisec);
  });
}

export function getConfigHeadersForToken() {
  const token_key_prop = process.env.REACT_APP_Access_Token_Prop_Name;

  let access_token = localStorage.getItem(token_key_prop);

  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  return config;
}

export const generateCameraName = (data) => {
  const { area, floor, building_name, city } = data;
  let name = "";
  if (area) {
    name = name + area.replace(" ", "_");
  }

  if (floor) {
    if (area) name = name + `_${floor.replace(" ", "_")}`;
    else name = name + floor.replace(" ", "_");
  }

  if (building_name) {
    if (area || floor) name = name + `_${building_name.replace(" ", "_")}`;
    else name = name + building_name.replace(" ", "_");
  }
  if (city) {
    if (area || floor || building_name)
      name = name + `_${city.replace(" ", "_")}`;
    else name = name + city.replace(" ", "_");
  }
  return name;
};

// export function saveAnnotationDataToLocalStrg(data={}){

//   sessionStorage.setItem('IMGANNOTATIONDATA', JSON.stringify({
//     fabric_value: data?.fabric || [],
//     class_value: data?.class || [],
//   }));

//   sessionStorage.setItem('IMGANNOTATIONTIMELOG', Date.now());
// }

export function checkLocStrgTimeDiffImgAnn() {
  const savedTimestamp = localStorage.getItem("IMGANNOTATIONTIMELOG");

  // let imgAnnData = sessionStorage.getItem("IMGANNOTATIONDATA");

  // imgAnnData = JSON.parse(imgAnnData);

  if (!savedTimestamp) return null;
  // Calculate the difference in milliseconds between the current time and the saved timestamp
  const currentTime = Date.now();
  const differenceInSeconds =
    (currentTime - parseInt(savedTimestamp, 10)) / 1000;

  // Check if the difference is greater than 4 seconds
  if (differenceInSeconds > 4) {
    localStorage.setItem("IMGANNOTATIONTIMELOG", "");
    return true;
  } else {
    return false;
  }
}

export async function fetchCameraFilterList({
  location = "",
  premises = "",
  floor = "",
  type = "premises" || "floor" || "location",
}) {
  switch (type) {
    case "location": {
      if (!location) {
        console.log("location is empty, early return");
        return [];
      }
      let response = await getCameraBuildings(location);
      if (response?.success) {
        console.log("getting buildings filter response ===>", response?.data);
      }
      return response?.data;
    }
    case "premises": {
      if (!location || !premises) {
        console.log(
          "location or premisise is empty, early return",
          location,
          premises
        );
        return [];
      }
      let response = await getCameraFloor(location, premises);
      if (response?.success) {
        console.log("getting floor filter response ===>", response?.data);
      }
      return response?.data;
    }
    case "floor": {
      if (!location || !premises || !floor) {
        console.log(
          "location or premisise or floor is empty, early return",
          location,
          premises,
          floor
        );
        return [];
      }
      let response = await getCameraSection(location, premises, floor);
      if (response?.success) {
        console.log("getting section filter response ===>", response?.data);
      }
      return response?.data;
    }

    default:
      break;
  }
}
