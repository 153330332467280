import React, { useEffect, useState } from "react";
import AddEmployeeScrn from "../addEmployeeScreen/addEmployeeScrn";
import { useSearchParams } from "react-router-dom";
import { verfiyEmployeesBeforeCapturingFace, saveUserImageToDir } from "../../../action/action";
import LoadingComponent from "../../../utility/loadingSpinner/loadingSpinner";
import { toast } from "react-toastify";

function ExtractFaceCaptureScrnWrppr() {
  const [isAuth, setIsAuth] = useState(null);
  const [unAuthMsg,setUnAuthMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDoneCapturing, setIsDoneCapturing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  function handleIsCapturing(v = false) {
    setIsDoneCapturing(v);
  }

  async function sendImageInfo(d){
    if(!d){
      toast.error("no data to save image info...");
      return;
    }
    let resp = await saveUserImageToDir(d);
    if(resp?.success){
      console.log("save image to directory response success =====>",resp?.data);
    }
  }

  useEffect(() => {
    console.log("token to extract ===>", token);
    async function verifyEmployee() {
      setIsLoading(true);
      let resp = await verfiyEmployeesBeforeCapturingFace(token);
      console.log("verify face capturing response 11111111111",resp);
      setIsLoading(false);
      if (resp?.success) {
        /*
        {
  emply_id: "",
  emply_name: "",
  emply_email: "",
  mob_number: "",
};

biometric_id: ""
company_name: "mark1"
contact_num: "9876543211"
email: "gaurav.sharma@marktine.com"
employee_id: null
face_images_dir_path: null
id: 3
organization: null
username: "Gaurav Sharma"
        */
        console.log("getting user data ===>", resp?.data?.data?.data);
        let empData = resp?.data?.data?.data;
        setUserData({
          emply_id: empData?.employee_id || "",
          emply_name: empData?.username || "",
          emply_email: empData?.email || "",
          mob_number: empData?.contact_num || "",
          user_id: empData?.id || ""
        });
        setIsAuth(true);
      } else {
        console.log("verify employee error ===>", resp?.data);
        setUnAuthMsg(resp?.data?.message||"");
        setIsAuth(false);
      }
    }
    verifyEmployee();
  }, []);

useEffect(()=>{
if(isDoneCapturing){
  // window.close();
  console.log("closing tab...");
}
},[isDoneCapturing]);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingComponent
          styl={{
            position: "relative",
            top: "0px",
            bottom: "0px",
            right: "0px",
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        {isAuth ? (
          <>
          {isDoneCapturing?
          <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          capturing face is completed !

        </div>
          :
          <AddEmployeeScrn formData={{ ...userData }} byMail={true} updateIsCaptured={handleIsCapturing} sendImageInfo={sendImageInfo} />
          }
          </>
        ) : (
          <>
          {isAuth===false&&
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection:"column"
            }}
          >
            <h1>
            not authorized...
            </h1>
            <p>{unAuthMsg}</p>
          </div>
          }
          </>
        )}
      </>
    );
  }
}

export default ExtractFaceCaptureScrnWrppr;
