import React, { useEffect } from "react";
import "./sidebarNav.css";
import { SiIconify } from "react-icons/si";
import { IoIosArrowDown } from "react-icons/io";
import {
  AtomIcon as connection,
  StackIcon as cloudstrg,
  PlusIcon,
  CameraIcon as cctv,
  AIBrainIcon as aimodl,
  EmployeeIcon as addemply,
  UserSettingIcon as usrmngmnt,
  AttendanceIcon as attendance,
  ImageAnnotationIcon as annotationimg,
} from "../../utility/Icons/icons.js";

import { useState, useContext, useRef } from "react";
import { MyContext } from "../../context/context";
import { fetchSideBarData } from "../../action/action.js";

function SideNavigation() {
  const [actvSideNavOpt, setActvSideNavOpt] = useState({
    main: "connection",
    sub: "connCam",
  });

  const sideNavOptArr = [
    {
      icon: cctv,
      label: "CCTV",
      slang: "cctv",
      subOptn: [
        { label: "All Camera", slang: "allCam" },
        { label: "Live Camera", slang: "myCam" },
        { label: "Recordings", slang: "recordg" },
      ],
    },
    {
      icon: connection,
      label: "Connections",
      slang: "connection",
      subOptn: [
        { label: "Connect Storage", slang: "connStrg" },
        { label: "Connect Camera", slang: "connCam" },
        { label: "Camera Settings", slang: "connCamSet" },
      ],
    },
    {
      icon: cloudstrg,
      label: "Storage",
      slang: "cloudstrg",
      subOptn: [],
    },
    {
      icon: aimodl,
      label: "AI Modeling",
      slang: "aimodl",
      subOptn: [
        { label: "Input", slang: "inppgaimdl" },
        { label: "Job Page", slang: "jobpgaimdl" },
      ],
    },
    {
      icon: addemply,
      label: "Add Employee",
      slang: "addemply",
      subOptn: [
        { label: "Input Page", slang: "inppgaddemply" },
        { label: "User Details Page", slang: "usrdtlpgaddemply" },
      ],
    },
    {
      icon: attendance,
      label: "Attendance",
      slang: "attendance",
      subOptn: [
        { label: "Attendance Management", slang: "attndncmngmnt" },
        { label: "Attendance Setting", slang: "attndncsettngs" },
      ],
    },
    {
      icon: usrmngmnt,
      label: "User Management",
      slang: "usrmngmnt",
      subOptn: [
        { label: "Create User", slang: "usrmngmntcrtusr" },
        { label: "User View", slang: "usrmngmntusrview" },
      ],
    },
    {
      icon: annotationimg,
      label: "Image Annotation",
      slang: "annotationimg",
      subOptn: [],
    }, // annotationimg
  ];
  const [sidebarOptions, setSidebarOptions] = useState(sideNavOptArr);
  const { sideNav } = useContext(MyContext);
  const isClickedSideNav = useRef(false);

  function handleClickSideNav(x, y) {
    isClickedSideNav.current = true;
    let dataObj = {...x,["current"]:y};
    setActvSideNavOpt(x);
    // console.log("handle set click side Navigation ===> dataObj: ",dataObj);
    if(!dataObj.current)return;
    sideNav?.handleSelScreen(dataObj);
  }

useEffect(()=>{
// console.log("changing side nav: ===>",sideNav.selScreen);
if(isClickedSideNav.current){
  isClickedSideNav.current = false;
  return;
}
let modDataObj = {main:sideNav.selScreen.main,sub:sideNav.selScreen.sub};
setActvSideNavOpt(modDataObj);
},[sideNav.selScreen]);

  useEffect(() => {
    async function fetchSidebarData() {
      let resp = await fetchSideBarData();
      let myArr = sideNavOptArr;
      if (resp?.data?.data?.data) {
        console.log("side navigation bar options list ===> ", resp?.data?.data?.data);
        myArr = resp?.data?.data?.data?.map((item) => {    
            return {
              ...item,
              icon: sideNavOptArr.find((itm) => itm.slang === item.slang)?.icon,
            };        
        });
        setSidebarOptions([...myArr]);
      }
    }
    fetchSidebarData();
  }, []);
  return (
    <>
      {/* <div className="sidenavigation_wrapper"> */}
      {/* ************************************************************* */}

      <div className="sidenavigation_main">
        <div className="sidenavigation_head">
          <img src="/images/g8.svg" alt="FD" />
          <div>
            <h3>Face Detection</h3>
            <p>Detect Face Using AI</p>
          </div>
        </div>

        <button className="sidenavigation_newcamerabutton">
          {PlusIcon}
          <p>New Camera</p>
        </button>

        <div className="sidenavigation_menucontainer">
          <p className="sidenavigation_menucontainer_p">Menu</p>
          <div className="sidenavigation_menucontent_wrppr">
            <SideNavOptionList
              optionList={sidebarOptions}
              clickHandler={(v, w) => {
                handleClickSideNav(v, w);
              }}
              currentOption={actvSideNavOpt}
            />
          </div>
        </div>
      </div>

      {/* ************************************************************* */}
      {/* </div> */}
    </>
  );
}

export default SideNavigation;

function SideNavOptionList({
  optionList = [
    {
      icon: <SiIconify />,
      label: "label",
      slang: "ssss",
      subOptn: [],
    },
  ],
  currentOption = { main: "", sub: "" },
  clickHandler = () => {},
}) {
  // const [selObj,setSelObj] = useState({main:"",sub:""});

  function handleClickMain(x) {
    console.log("sel click element side nav1111 =======>", x);
    let renderScreen = "";
    if (!x?.subOptn[0]) {
      renderScreen = x?.slang; // assinging slang for rendering screen in mainframe via context api...
    }
    clickHandler({ main: x?.slang || "", sub: "" }, renderScreen);
  }

  function handleClickSub(xm, xs) {
    clickHandler({ main: xm || "", sub: xs || "" }, xs);
  }

  return (
    <>
      {optionList?.map((e, i) => {
        return (
          <div key={`SNSCW_${i}`}>
            <div
              className={`sidenavigation_menucontent ${
                currentOption?.main === e?.slang ? "actvSideNavOptFD" : ""
              }`}
              onClick={() => handleClickMain(e)}
              key={`sideNavOptCont_SFSDFE${i}`}
            >
              <div className="iconWrpprSideNavOptSelStrg">{e?.icon}</div>
              <p className="sidenavigation_menucontent_p">{e?.label}</p>
              {e?.subOptn[0] && (
                <IoIosArrowDown className="sidenavigation_menucontent_dwn_arrow_icon" />
              )}
            </div>
            {e?.subOptn[0] && e?.slang === currentOption?.main ? (
              <div className="sidenavigation_menucontent_subcontent_wrppr">
                {e?.subOptn?.map((s) => (
                  <div
                    className={`side_nav_menu_cont_subcont ${
                      s?.slang === currentOption?.sub ? "activate_sub_cont" : ""
                    }`}
                    onClick={() => handleClickSub(e?.slang, s?.slang)}
                    key={`SNMCS_${i + 11}`}
                  >
                    <p>{s?.label}</p>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
}
