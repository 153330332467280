import { LoadingComponentStacked } from "../../../utility/loadingSpinner/loadingSpinner";

export const ButtonComp = ({
  label = "",
  bgColor = "",
  textColor = "",
  className = "",
  clickHandler = () => {},
  icon = "",
  isLoading = false,
}) => {
  return (
    <button
      className={
        className ? className : ` px-8 py-3  ${bgColor} ${textColor} rounded-md`
      }
      onClick={clickHandler}
      disabled={isLoading}
    >
      {isLoading && (
        <LoadingComponentStacked
          size={"1rem"}
          styl={{
            justifyContent: "center",
            alignItems: "center",
            height: "-webkit-fill-available",
            color: "white",
          }}
        />
      )}
      {icon && icon}
      {label}
    </button>
  );
};
