import "./mainFrame.css";

// ************************ COMPONENTS ************************

import SideNavigation from "../sidebar/sidebarNav"; //               SIDE NAVIGATION

import Header from "../header/header"; //               HEADER

import {
  ProtectedAllCameraScreen, //               ALL CAMERA SCREEN
  ProtectedConnectCameraScreen, //               CONNECT CAMERA SCREEN
  ProtectedConnectStorageScreen, //               CONNECT STORAGE SCREEN
  ProtectedInpPageAIModel, //               AI INPUT PAGE SCREEN
  ProtectedJobPageAIModel, //               AI JOB PAGE SCREEN
  ProtectedMyCameraScreen, //               MY CAMERA SCREEN
  ProtectedUploadToCloudScreen, //               UPLOAD FILE SCREEN
  ProtectedAddEmployeeScrn,  //               INPUT EMPLOYEE DETAIL SCREEN
  ProtectedUserManagementCrtUsrScreen,   //               CREATE USER USER MANAGEMENT SCREEN
  ProtectedUserDetlPgAddEmplyScrn,   //               ADD EMPLOYEE USER DETAILS SCREEN
  ProtectedUserManagementViewUsrScreen,    //               ADD EMPLOYEE USER DETAILS SCREEN
  ProtectedAttenDanceMngmntScrn,    //               ATTENDANCE MANAGEMENT SCREEN
  ProtectedAttendanceSettingScrn,    //               ATTENDANCE SETTINGS SCREEN
  ProtectedAnnotationScreen,    //               ANNOTATION SCREEN
  ProtectedMyCameraRecordingScreen,    //               CAMERA RECORDING SCREEN
  ProtectedCameraSettingsScreen    //               CAMERA SETTINGS SCREEN
} from "../../routes/routes";

// ************************ COMPONENTS ************************

import { useContext } from "react";
import { MyContext } from "../../context/context";

function MainFrameComponent() {
  const { sideNav } = useContext(MyContext);
  return (
    <>
      <div className="main_frame_container">
        <div className="main_frame_sideNav_container">
          <SideNavigation />
        </div>
        <div className="main_frame_right_container">
          <div className="main_frame_head_container">
            <Header />
          </div>
          <div className="main_frame_screen_container">
            {sideNav?.selScreen.current === "connCam" && <ProtectedConnectCameraScreen />}
            {sideNav?.selScreen.current === "connStrg" && <ProtectedConnectStorageScreen />}
            {sideNav?.selScreen.current === "cloudstrg" && <ProtectedUploadToCloudScreen />}
            {sideNav?.selScreen.current === "connCamSet"&&<ProtectedCameraSettingsScreen/>}
            {sideNav?.selScreen.current === "allCam" && (
              <ProtectedAllCameraScreen rows={2} columns={2} />
            )}
            {sideNav?.selScreen.current === "myCam" && <ProtectedMyCameraScreen />}
            {sideNav?.selScreen.current === "recordg" && <ProtectedMyCameraRecordingScreen />}
            {sideNav?.selScreen.current === "inppgaimdl" && <ProtectedInpPageAIModel />}
            {sideNav?.selScreen.current === "jobpgaimdl" && <ProtectedJobPageAIModel />}
            {sideNav?.selScreen.current === "inppgaddemply" && <ProtectedAddEmployeeScrn />}
            {sideNav?.selScreen.current === "usrmngmntcrtusr" && <ProtectedUserManagementCrtUsrScreen/>}
            {sideNav?.selScreen.current === "usrdtlpgaddemply" && <ProtectedUserDetlPgAddEmplyScrn/>}
            {sideNav?.selScreen.current === "usrmngmntusrview"&&<ProtectedUserManagementViewUsrScreen/>}
            {sideNav?.selScreen.current === "attndncmngmnt"&&<ProtectedAttenDanceMngmntScrn/>}
            {sideNav?.selScreen.current === "attndncsettngs"&&<ProtectedAttendanceSettingScrn/>}
            {sideNav?.selScreen.current === "annotationimg"&&<ProtectedAnnotationScreen/>}
          </div>
        </div>
      </div>
    </>
  );
}

export default MainFrameComponent;
