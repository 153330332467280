// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_wrppr_face_recognition {
  /* <<<<<========================   remove this                    */
  border: 1px solid gray;
  background-color: grey;
  width: 30vw;
}

.header_main_face_recognition {
  background-color: white;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0px 0px 1rem 1rem;
}

.header_face_recog_log_out_button{
  margin-right: 1rem;
}

.header_face_recog_log_out_button p {
  color: white !important;
}

.header_main_face_recognition img {
  margin-right: 1rem;
}

.header_main_face_recognition p {
  color: var(--Primary-Black, #2d2d2d);
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;EACE,mEAAmE;EACnE,sBAAsB;EACtB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,oBAAoB;EACpB,sBAAsB;EACtB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".header_wrppr_face_recognition {\n  /* <<<<<========================   remove this                    */\n  border: 1px solid gray;\n  background-color: grey;\n  width: 30vw;\n}\n\n.header_main_face_recognition {\n  background-color: white;\n  display: flex;\n  justify-content: end;\n  box-sizing: border-box;\n  padding: 1rem;\n  margin: 0px 0px 1rem 1rem;\n}\n\n.header_face_recog_log_out_button{\n  margin-right: 1rem;\n}\n\n.header_face_recog_log_out_button p {\n  color: white !important;\n}\n\n.header_main_face_recognition img {\n  margin-right: 1rem;\n}\n\n.header_main_face_recognition p {\n  color: var(--Primary-Black, #2d2d2d);\n  text-align: center;\n  font-family: Manrope;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
