import "./jobPageAIModel.css";

import { useContext, useEffect, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { LiaCameraSolid } from "react-icons/lia";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FileSyncButton } from "../../../utility/button/button";
import { Checkbox } from "@mui/material";
import { NavBarOptions } from "../../../utility/variables/variables";

import { fetchJobDetails, fetchJobDetailsByID } from "../../../action/action";
import { MyContext } from "../../../context/context";

function JobPageAIModel() {
  const [pageStatus, setPageStatus] = useState({ status: "jobCard", id: null });

  const { jobPage } = useContext(MyContext);

  function moreDetailClickHandlerJobCard(elem) {
    console.log("getting selected job card element ===>", elem?.model_id);
    setPageStatus({ status: "jobStatusDetails", id: elem?.model_id });
  }

  useEffect(() => {
    async function getJobDetails() {
      let resp = await fetchJobDetails();
      if (resp?.success) {
        console.log(
          "fetch job details response data =========>",
          resp?.data?.data
        );

        /*
        job_description: "testing 121 description"
job_type: "pre_trained_model"
model_id: 10
name: "testing 121"
pre_trend_model: "Model 1"
selected_camera: [1]
selected_stored_files: [1]
        */
        let data = resp?.data?.data?.AllModels || [];
        jobPage?.handleSetJobCardArr(data);
      } else {
        console.log("error occured fetching job details ========>", resp);
      }
    }

    getJobDetails();
  }, []);
  return (
    <div className="connect_storage_screen_container">
      <h2>Basic Details For AI Modeling</h2>
      <p className="connect_storage_screen_container_p">
        Enter Your Details To Make Your Own Custom Model{" "}
      </p>
      {pageStatus?.status === "jobCard" ? (
        <div className="job_card_cont_ai_model_job_page_outer_window_cont">
          <div className="job_card_cont_ai_model_job_page">
            {jobPage?.jobCardArr?.map((e, i) => {
              // i === 0 && console.log("job card detail element ==>", e);
              return (
                <JobCard
                  jobName={e?.name}
                  jobType={e?.job_type}
                  preTrainModal={e?.pre_trend_model}
                  fileNum={e?.selected_stored_files?.length}
                  camNum={e?.selected_camera?.length} // to be replaced no input for images...
                  key={`JCCCAMJP${i}nxj`}
                  clickHandler={() => moreDetailClickHandlerJobCard(e)}
                  jobDetailsObj={e}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <FileSyncButton
            stylObj={{
              color: "white",
              fontFamily: "Manrope",
              fontSize: "17px",
              padding: "0rem 1rem",
              gap: "1rem",
              fontWeight: "500",
            }}
            title="Back"
            handleClick={() => setPageStatus({ status: "jobCard", id: null })}
          >
            <IoMdArrowRoundBack size={24} />
          </FileSyncButton>

          <FileStatusComponent jobModelID={pageStatus?.id} />
        </>
      )}
    </div>
  );
}

export default JobPageAIModel;

function JobCard({
  jobName = "",
  jobType = "",
  preTrainModal = "",
  fileNum = 0,
  camNum = 0,
  clickHandler = () => {},
  jobDetailsObj = {},
}) {
  const { jobPage, sideNav } = useContext(MyContext);
  function handleEditCLick() {
    console.log("getting job details obj ========>", jobDetailsObj);
    jobPage?.handleEditJobCardDetails(jobDetailsObj, true);
    let dataObj = { main: "", sub: "", current: "" };
      dataObj.main = NavBarOptions["AI Modeling"].slang;
      dataObj.sub = NavBarOptions["AI Modeling"].subOptn.Input;
      dataObj.current = NavBarOptions["AI Modeling"].subOptn.Input;
    sideNav?.handleSelScreen(dataObj);
  }
  return (
    <div className="job_page_ai_modal_job_card">
      <div className="status_and_action_wrppr_job_card">
        <div className="status_cont">Running</div>
        <div className="actions_btn_cont">
          <MdOutlineModeEditOutline
            className="edit_icon_actions_btn_cont"
            onClick={handleEditCLick}
          />
          <span>|</span>
          <RiDeleteBin4Fill className="delete_icon_actions_btn_cont" />
        </div>
      </div>
      <div className="job_details_wrppr_job_card">
        <div className="job_field_cont">
          <p className="label_job_field_cont">Job Name</p>
          <p className="detail_job_field_cont">{jobName}</p>
        </div>
        <div className="job_field_inline_wrppr">
          <div className="job_field_cont">
            <p className="label_job_field_cont">Job Type</p>
            <p className="detail_job_field_cont">{jobType} </p>
          </div>
          <div className="job_field_cont">
            <p className="label_job_field_cont">Pre-Trend Model</p>
            <p className="detail_job_field_cont">{preTrainModal}</p>
          </div>
        </div>
      </div>
      <div className="job_other_details_wrppr_job_card">
        <div className="files_detail_icon_wrppr">
          <div className="file_detail_cont">
            <LiaCameraSolid />
            <span>{camNum}</span>
          </div>
          <div className="file_detail_cont">
            <IoDocumentTextOutline />
            <span>{fileNum}</span>
          </div>
        </div>
        <p onClick={clickHandler}>More Details...</p>
      </div>
    </div>
  );
}

function FileStatusComponent({ jobModelID }) {

const [jobCardData,setJobCardData] = useState(null);

  const headersCamTable = [
      "Live Camera Name",
      "Loading",
      "Running Status",
      "Total Run Time",
      "Blank",
      "Blank",
    ],
    headersStorageTable = [
      "File Name",
      "Loading",
      "Running Status",
      "Total Run Time",
      "Blank",
      "Blank",
    ],
    myArr = [
      {
        id: 1,
        camName: "Storage1",
        loadingStatus: "23%",
        runningStatus: "runnning",
        totRunTime: "2hrs30min",
        field: "blank",
        field2: "blank",
      },
      {
        id: 2,
        camName: "Storage1",
        loadingStatus: "23%",
        runningStatus: "runnning",
        totRunTime: "2hrs30min",
        field: "blank",
        field2: "blank",
      },
      {
        id: 3,
        camName: "Storage1",
        loadingStatus: "23%",
        runningStatus: "runnning",
        totRunTime: "2hrs30min",
        field: "blank",
        field2: "blank",
      },
    ];

  const columnMinWidths = [43, 43, 43, 43, 43, 43];

  useEffect(() => {
    async function getJobDetailsByModelID() {
      if(!jobModelID){
        console.log("no job detail id ===>",jobModelID);
        return;
      }
      let resp = await fetchJobDetailsByID(jobModelID);

      if (resp?.success) {
        console.log("getting data job details by model id ===>", resp.data?.data?.AllModel);
        setJobCardData(resp.data?.data?.AllModel);
      }
    }
    getJobDetailsByModelID();
  }, []);

  return (
    <div>
      <div className="live_cam_status_table_cont">
        <p className="cam_status_table_label">Live Camera Status</p>
        <MyStorageTable
          headers={headersCamTable}
          data={jobCardData?.selected_camera}
          columnMinWidths={columnMinWidths}
        />
      </div>
      <div className="running_files_status_table_cont">
        <p className="cam_status_table_label">Stored Files Status</p>
        <MyStorageTable
          headers={headersStorageTable}
          data={jobCardData?.selected_stored_files}
          columnMinWidths={columnMinWidths}
        />
      </div>
    </div>
  );
}

function MyStorageTable({
  headers = [],
  data = [],
  columnMinWidths = [],
  handleSelRow = () => {},
  currentSelRow,
  handleActStrg = () => {},
}) {
  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            <th style={{ width: `50px` }}>
              <Checkbox
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </th>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI file_status_for_job_page_table">
          {data?.map((row, rowIndex) => (
            <tr
              key={`row-${rowIndex}`}
              className={`datasetTableRowFDFI ${
                currentSelRow?.storage_id === row?.id ? "actvRowCameraList" : ""
              }`}
              onClick={() => {
                console.log("clicking camera row", currentSelRow);
                handleSelRow(row);

/*


camera_description: "my cam 1"
camera_status: true
id: 1
ip_address: "172.10.10.10"
loading: "100%"
name: "cam 1"
password: "Marktine@123#"
port_number: 554
running_status: "Running"
total_running_time: "2Hrs 30M"
user_name: "admin"

*/

              }}
            >
              <td width={"50px"}>
                <Checkbox
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>
              <td style={{ width: `${columnMinWidths[rowIndex]}%` }}>
                {row?.name}
              </td>
              <td style={{ width: `${columnMinWidths[rowIndex]}%` }}>
                {row?.loading}
              </td>
              <td style={{ width: `${columnMinWidths[rowIndex]}%` }}>
                <button className="status_cont simpleBtnWrpprNoBorder">
                  {row?.running_status}
                </button>
              </td>
              <td style={{ width: `${columnMinWidths[rowIndex]}%` }}>
                {row?.total_running_time}
              </td>
              <td style={{ width: `${columnMinWidths[rowIndex]}%` }}>
                {row?.field || "- -"}
              </td>
              <td style={{ width: `${columnMinWidths[rowIndex]}%` }}>
                {row?.field2 || "- -"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
