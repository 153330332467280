import React, { useState, useEffect, useRef, useContext } from "react";
import "./liveFeedPlayer.css";

import VideoPlayerhlsmod from "../../../utility/videoHLSPlayer/videoHLSPlayer";
import ProgressBarmod from "../../../utility/progressBar/progressBar";

import { toast } from "react-toastify";

import { MyContext } from "../../../context/context";

import { ImageWithOverlay } from "../../../routes/routes";

import LoadingComponent, {
  LoadingComponentStacked,
} from "../../../utility/loadingSpinner/loadingSpinner";

import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { TbRewindBackward10 } from "react-icons/tb";
import { TbRewindForward10 } from "react-icons/tb";
import { RiScreenshot2Line } from "react-icons/ri";
import { Maximize2, Minimize2 } from "lucide-react";

function LiveFeedPlayer({ children, handleScreenShot, camID, camStatus }) {
  console.log("getting cam id in live feed player ===>", camID);

  const { mySelCamStream } = useContext(MyContext);

  const [isLive, setIsLive] = useState(true); // State to track if live stream or recorded footage is being displayed
  const [progress, setProgress] = useState(0); // State to track progress of recorded footage playback
  const [plyrDim, setPlyrDim] = useState({ width: 0, height: 0 });
  const [vidProgBar, setVidProgBar] = useState({ currTime: 0, duration: 0 });
  const [onSeekCurrTime, setOnSeekCurrTime] = useState(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [playLive, setPlayLive] = useState(false);
  const [isHlsReady, setIsHlsReady] = useState(false);

  const vidInterface = useRef(null);
  const videoPlaybackCurrTime = useRef(null);
  const videoPlaybackDuration = useRef(null);

  function handleUpdateSeekTime(t) {
    // console.log("updating seek time to : ===>",t);
    setOnSeekCurrTime(t);
    setIsLive(false);
    setIsUserInteracted(true);
  }

  function seekBackward10sec() {
    let seek10SecBack = 0;
    if (videoPlaybackCurrTime.current > 10) {
      seek10SecBack = videoPlaybackCurrTime.current - 10;
    }
    setOnSeekCurrTime(seek10SecBack);
    setIsLive(false);
    setIsUserInteracted(true);
  }

  function seekForward10sec() {
    let seek10SecForward = 0;
    if (videoPlaybackCurrTime.current <= videoPlaybackDuration.current - 10) {
      seek10SecForward = videoPlaybackCurrTime.current + 10;
    } else {
      seek10SecForward = videoPlaybackDuration.current;
    }
    setOnSeekCurrTime(seek10SecForward);
    setIsLive(false);
    setIsUserInteracted(true);
  }

  useEffect(() => {
    if (vidProgBar.currTime === vidProgBar.duration) {
      setIsLive(true);
      setIsUserInteracted(false);
    }
    videoPlaybackCurrTime.current = vidProgBar.currTime;
    videoPlaybackDuration.current = vidProgBar.duration;
  }, [vidProgBar]);

  useEffect(() => {
    if (
      !vidInterface.current ||
      !vidInterface.current.offsetWidth ||
      !vidInterface.current.offsetHeight
    )
      return;
    // console.log(
    //   "video interface dimensions ===>",
    //   "   width:",
    //   vidInterface.current.offsetWidth,
    //   "   height:",
    //   vidInterface.current.offsetHeight
    // );
    setPlyrDim({
      width: vidInterface.current.offsetWidth,
      height: vidInterface.current.offsetHeight,
    });
  }, [vidInterface.current]);

  useEffect(() => {
    if (camID) {
      console.log("getting cam id inside live feed in useeffect ===>", camID);
      setIsHlsReady(true);
    }
  }, [camID]);
  return (
    <div
      className={`live_feed_player_interface_main_wrppr ${
        mySelCamStream?.selCameraFullScreen
          ? "full_screen_live_feed_player_main_wrppr"
          : ""
      }`}
    >
      <div
        className={`${isLive ? "" : "in_active_stream"} ${
          mySelCamStream?.selCameraFullScreen
            ? "full_screen_live_feed_img_element"
            : ""
        }`}
        id="live_feed_img_element"
        ref={vidInterface}
      >
        <button
          className={`live-button ${
            camStatus === "load"
              ? "connecting_status_load_live_button"
              : camStatus === "fail"
              ? "connecting_status_fail_live_button"
              : ""
          }`}
        >
          {camStatus === "done"
            ? "Live"
            : camStatus === "load"
            ? "Connecting..."
            : "failed"}
        </button>

        {children}
      </div>
      <div className={isLive ? "in_active_stream" : ""}>
        {!isHlsReady ? (
          // <LoadingComponent
          //   styl={{
          //     bottom: "0px",
          //     right: "0px",
          //     position: "relative",

          //   }}
          //   size={"14px"}
          //   color="white"
          // />
          <LoadingComponentStacked
            size={"2rem"}
            styl={{
              justifyContent: "center",
              alignItems: "center",
              height: "-webkit-fill-available",
              color: "white",
            }}
          />
        ) : (
          <VideoPlayerhlsmod
            dimension={plyrDim}
            updCurrentTime={(time) => {
              // console.log("getting current time video player hls mod ===>", time);
              setVidProgBar((prev) => {
                return { ...prev, currTime: time };
              });
            }}
            updDuration={(dtime) => {
              // console.log("duratio time ===>", dtime);
              setVidProgBar((prev) => {
                return { ...prev, duration: dtime };
              });
            }}
            currTimeOnSeek={onSeekCurrTime}
            isUserInteracted={isUserInteracted}
            camID={camID}
          />
        )}
      </div>
      <div className="progress_bar_wrapper_live_feed">
        <ProgressBarmod
          duration={vidProgBar?.duration}
          currentTime={vidProgBar?.currTime}
          onSeek={(t) => handleUpdateSeekTime(t)}
          progBarWidth={plyrDim.width}
        />
      </div>
      <div className="controls">
        <button
          onClick={() => {
            if (!isLive) {
              setIsUserInteracted((prev) => !prev);
            } else {
              setPlayLive(false);
            }
          }}
          className="video_controll_play_plause"
        >
          {isLive ? "" : isUserInteracted ? <FaPause /> : <FaPlay />}
        </button>
        <button
          onClick={() => mySelCamStream?.handleSwitchFullScreen()}
          className="full_screen_controll_button_live_player"
        >
          {/* selCameraFullScreen,
      handleSwitchFullScreen, */}
          {mySelCamStream?.selCameraFullScreen ? <Minimize2 /> : <Maximize2 />}
        </button>
        {isLive ? (
          <RiScreenshot2Line
            onClick={() => {
              handleScreenShot();
            }}
            className="screen_shot_button_live_video"
          />
        ) : (
          <>
            <TbRewindBackward10
              onClick={() => seekBackward10sec()}
              className="video_controll_play_plause"
            />
            <TbRewindForward10
              onClick={() => seekForward10sec()}
              className="video_controll_play_plause"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default LiveFeedPlayer;
