// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cam_stream_my_camera_scrn {
  width: 100%;
  height: 100%;
}
.grid_wrppr_allcam_screen {
  padding: 1rem;
}
.mycm_reco_screen_btn_wrppr {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding: 0px 0rem 1rem 0px;
}
.mycm_reco_screen_container_header_wrppr {
  display: flex;
  flex-direction: column;
}
.mycm_reco_screen_container_header {
  padding: 0;
  margin: 0;
}
.reco_screen_list_btn_wrppr {
  display: flex;
  flex-direction: row;
}
.grid_container_my_cam_reco_scrn {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 16px;
}
.reco_card_wrpr {
  border: 1px solid lightgrey;
}

.reco_card_content_wrpr {
  display: flex;
  flex-direction: row;
}

.reco_card_text_wrpr {
  display: flex;
  flex-direction: column;
}

.reco_card_file_name {
  padding: 0px;
  margin: 0px;
}

.reco_card_timestamp {
  padding: 0px;
  margin: 0px;
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/MyCameraRecordingScreen/myCameraRecordingScreen.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,8BAA8B;EAC9B,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;IACI,aAAa;IACb,kCAAkC;IAClC,SAAS;AACb;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,WAAW;AACb","sourcesContent":[".cam_stream_my_camera_scrn {\n  width: 100%;\n  height: 100%;\n}\n.grid_wrppr_allcam_screen {\n  padding: 1rem;\n}\n.mycm_reco_screen_btn_wrppr {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  justify-content: space-between;\n  padding: 0px 0rem 1rem 0px;\n}\n.mycm_reco_screen_container_header_wrppr {\n  display: flex;\n  flex-direction: column;\n}\n.mycm_reco_screen_container_header {\n  padding: 0;\n  margin: 0;\n}\n.reco_screen_list_btn_wrppr {\n  display: flex;\n  flex-direction: row;\n}\n.grid_container_my_cam_reco_scrn {\n    display: grid;\n    grid-template-columns: 33% 33% 33%;\n    gap: 16px;\n}\n.reco_card_wrpr {\n  border: 1px solid lightgrey;\n}\n\n.reco_card_content_wrpr {\n  display: flex;\n  flex-direction: row;\n}\n\n.reco_card_text_wrpr {\n  display: flex;\n  flex-direction: column;\n}\n\n.reco_card_file_name {\n  padding: 0px;\n  margin: 0px;\n}\n\n.reco_card_timestamp {\n  padding: 0px;\n  margin: 0px;\n  color: grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
