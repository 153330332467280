export const FormInput = ({
  label = "",
  important = true,
  placehldr = "",
  className = "",
  type = "text",
  fetchvalue = () => {},
  valueToSet = "",
}) => {
  const wrpprStyles = `bg-white p-3 rounded-lg shadow`;

  return (
    <div className={className ? className : wrpprStyles}>
      {label && (
        <label className="block mb-2 text-xs font-normal leading-tight text-left font-manrope text-blue-700">
          {label} {important && <span className="text-red-500">*</span>}
        </label>
      )}
      <input
        type={type}
        value={valueToSet || ""}
        onChange={(e) => {
          fetchvalue(e.target.value);
        }}
        className="w-full px-1 py-1 focus:outline-none font-manrope text-sm placeholder-gray-400 text-[#2D2D2D]"
        placeholder={placehldr}
      />
    </div>
  );
};
