import { MyContext } from "../../../context/context";
import "./myCameraScreen.css";
import { getAllCameraList } from "../../../action/action";
import React, { useContext, useEffect } from "react";

import { IpCamWebSocket } from "../../../routes/routes";

function MyCameraScreen() {
  // const { mySelCamStream } = useContext(MyContext);

  // async function fetchAllCamURL() {
  //   let resp = await getAllCameraList();

  //   // console.log("response from get all camera list",resp?.data?.data?.paylod);

  //   if (resp?.success) {
  //     console.log(
  //       "response from get all camera list",
  //       resp?.data?.data?.paylod
  //     );
  //     let dArr = resp?.data?.data?.paylod || [];
  //     mySelCamStream?.handleSelCameraToStream(dArr[0]?.url);
  //   }
  // }

  // useEffect(() => {
  //   if (!mySelCamStream?.selCameraURl) {
  //     fetchAllCamURL();
  //   }
  // }, []);





  return (
    <div>
      {/* single camera screen */}
      {/* {mySelCamStream?.selCameraURl ? (
        <img
          className="cam_stream_my_camera_scrn"
          src={mySelCamStream?.selCameraURl}
          alt="imm"
        />
      ) : (
        <p>Select a stream to view...</p>
      )} */}
      <IpCamWebSocket/>
    </div>
  );
}

export default MyCameraScreen;
