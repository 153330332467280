// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capturedStatusBtn{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem 0.9rem;
    cursor: pointer;
}

.editBtnUsrDetailsAddEmply{
    padding: .4rem .6rem;
    cursor: pointer;
    border-radius: 6px;
}

.btn_wrppr_manage_user_details_add_emply{
    display: flex;
    gap: 1rem;
}

.user_details_add_emply_bulk_upload_modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007d;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/userDetlPgAddEmplyScrn/userDetlPgAddEmplyScrn.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".capturedStatusBtn{\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    padding: 0.3rem 0.9rem;\n    cursor: pointer;\n}\n\n.editBtnUsrDetailsAddEmply{\n    padding: .4rem .6rem;\n    cursor: pointer;\n    border-radius: 6px;\n}\n\n.btn_wrppr_manage_user_details_add_emply{\n    display: flex;\n    gap: 1rem;\n}\n\n.user_details_add_emply_bulk_upload_modal{\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: #0000007d;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
