// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screen_shots_side_cont_web_socket_live_test {
  background-color: #e4f0ff;
  width: 100%;
  max-width: -webkit-fill-available;
  min-height: 13rem;
  border-radius: 1rem;
  padding: 0.7rem 1rem;
  margin-bottom: 2rem;
}
.screen_shot_cont_wrapper_single_cam_feed {
  overflow-x: scroll;
  max-width: 8rem;
  height: 13rem;
  /* background: green; */
  /* padding: .7rem; */

}

.screen_shot_cont_wrapper_single_cam_feed::-webkit-scrollbar {
  display: none;
}

.screen_shot_container_single_cam_feed {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding: 1rem;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: border-box;
}

.live_stream_container_web_socket_test {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.screen_shot_list_head_single_cam_feed {
  color: #05306b;
  font-family: Manrope;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0.5rem 0rem;
  padding: 0;
}

.screen_shot_img_single_feed_cam {
  width: 18rem;
  border-radius: 0.6rem;
}


.screen_shot_image_wrapper_container{
    position: relative;
}

.screen_shot_image_checkBox{
    position: absolute !important;
    top: .5rem;
    left: .5rem;
}`, "",{"version":3,"sources":["webpack://./src/routes/routes.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,iCAAiC;EACjC,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,oBAAoB;;AAEtB;;AAEA;EACE,aAAa;AACf;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,UAAU;IACV,WAAW;AACf","sourcesContent":[".screen_shots_side_cont_web_socket_live_test {\n  background-color: #e4f0ff;\n  width: 100%;\n  max-width: -webkit-fill-available;\n  min-height: 13rem;\n  border-radius: 1rem;\n  padding: 0.7rem 1rem;\n  margin-bottom: 2rem;\n}\n.screen_shot_cont_wrapper_single_cam_feed {\n  overflow-x: scroll;\n  max-width: 8rem;\n  height: 13rem;\n  /* background: green; */\n  /* padding: .7rem; */\n\n}\n\n.screen_shot_cont_wrapper_single_cam_feed::-webkit-scrollbar {\n  display: none;\n}\n\n.screen_shot_container_single_cam_feed {\n    display: flex;\n    gap: 1.5rem;\n    align-items: center;\n    padding: 1rem;\n    width: fit-content;\n    box-sizing: border-box;\n}\n\n.live_stream_container_web_socket_test {\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n}\n\n.screen_shot_list_head_single_cam_feed {\n  color: #05306b;\n  font-family: Manrope;\n  font-size: 1.3rem;\n  font-weight: 700;\n  margin: 0.5rem 0rem;\n  padding: 0;\n}\n\n.screen_shot_img_single_feed_cam {\n  width: 18rem;\n  border-radius: 0.6rem;\n}\n\n\n.screen_shot_image_wrapper_container{\n    position: relative;\n}\n\n.screen_shot_image_checkBox{\n    position: absolute !important;\n    top: .5rem;\n    left: .5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
