import { useState, useRef, useEffect } from "react";
import { SquareCheck, ChevronDown, CircleX } from "lucide-react";
import { Checkbox } from "../../../shadcnComponents/ui/checkbox";

export const Dropdown = ({
  label = "",
  important = true,
  placehldr = "",
  options = [],
  fetchValue = () => {},
  valueToSet = "",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const filteredOptions = options?.filter((option) =>
    option?.toLowerCase()?.includes(inputValue?.toLowerCase())
  );

  const handleOptionClick = (option) => {
    setInputValue(option);
    fetchValue(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // if (valueToSet) {
    setInputValue(valueToSet);
    // }
  }, [valueToSet]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative bg-white p-3 rounded-lg shadow">
      <label className="block mb-2 text-xs font-normal leading-tight text-left font-manrope text-blue-700">
        {label} {important && <span className="text-red-500">*</span>}
      </label>
      <ChevronDown className="absolute right-2 bottom-3 cursor-pointer text-slate-600" />
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e?.target?.value)}
        onFocus={() => setIsOpen(true)}
        className="w-full px-1 py-1 focus:outline-none font-manrope text-sm placeholder-gray-400 text-[#2D2D2D]"
        placeholder={placehldr}
      />
      {isOpen && (
        <ul className="absolute top-full left-0 z-10 w-full bg-white border border-gray-200 mt-1 rounded-lg max-h-60 overflow-y-auto shadow-lg scrollbar">
          {filteredOptions?.map((option, index) => (
            <li
              key={index}
              className="px-3 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const MultiSelectDropdown = ({
  label = "",
  important = true,
  placehldr = "",
  options = [{ id: 0, label: "option-1" }],
  fettchValue = () => {},
  selectedOptionsListVal = [],
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionsList, setSelectedOptionsList] = useState([
    ...selectedOptionsListVal,
  ]);

  function handleOptionClick(option) {
    setSelectedOptionsList((prev) => {
      if (prev.some((x) => option?.id === x?.id)) {
        let newlist = prev?.filter((item) => item.id !== option?.id);
        return newlist;
      } else {
        return [...prev, { ...option }];
      }
    });
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(event?.target)) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleRemoveOptionFromSelectedList = (option) => {
    setSelectedOptionsList((prev) => {
      return prev?.filter((item) => item?.id !== option?.id);
    });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fettchValue(selectedOptionsList);
  }, [selectedOptionsList]);

  return (
    <>
      <div
        ref={dropdownRef}
        className="relative bg-white p-3 rounded-lg shadow"
      >
        <label className="block mb-2 text-xs font-normal leading-tight text-left font-manrope text-blue-700">
          {label} {important && <span className="text-red-500">*</span>}
        </label>
        <ChevronDown className="absolute right-2 bottom-3 cursor-pointer text-slate-600" />
        <div className="flex flex-wrap gap-4 cursor-pointer">
          {selectedOptionsList?.length == 0 ? (
            <p className="text-slate-400 font-manrope text-sm">{placehldr}</p>
          ) : (
            selectedOptionsList?.map((elem) => {
              return (
                <button className="border-blue-700 border-2 p-2 rounded-full text-blue-700 font-manrope text-sm flex gap-3">
                  {elem?.label}{" "}
                  <CircleX
                    onClick={() => handleRemoveOptionFromSelectedList(elem)}
                  />
                </button>
              );
            })
          )}
        </div>
        {isOpen && (
          <ul className="absolute top-full left-0 z-10 w-full bg-white border border-gray-200 mt-1 rounded-lg max-h-60 overflow-y-auto shadow-lg scrollbar">
            {options?.map((option, index) => (
              <li
                key={index}
                className="px-3 py-2 cursor-pointer hover:bg-gray-100 flex gap-4 items-center"
                onClick={() => handleOptionClick(option)}
              >
                {/* {selectedOptionsList.some((item)=>option?.id === item?.id) && (
                  <SquareCheck className=" text-blue-500" />
                )} */}
                <Checkbox
                  checked={selectedOptionsList.some(
                    (item) => option?.id === item?.id
                  )}
                  className="data-[state=checked]:bg-blue-700"
                />
                {option?.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};
