import { useTheme } from "@emotion/react";
import React, { createContext, useEffect, useRef, useState } from "react";
import {
  getAllCameraInfo,
  fetchAllUploadedData,
  fetchUserAccessFeatures,
  saveImageAnnUserProgress,
  getCameraLocations,
} from "../action/action";
import { NavBarOptions } from "../utility/variables/variables";
import { toast } from "react-toastify";

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  // ================================================================>>>> SIDE NAVIGATION
  const selScreenInitialState = {
    main: NavBarOptions.Connections.slang,
    sub: NavBarOptions.Connections.subOptn["Connect Camera"],
    current: NavBarOptions.Connections.subOptn["Connect Camera"],
  };
  const [selScreen, setSelScreen] = useState(selScreenInitialState); // attndncmngmnt connCam

  function handleSelScreen(v) {
    if (!v) return;
    setSelScreen(v);
  }
  // ================================================================>>>> SIDE NAVIGATION

  // ================================================================>>>> USER DATA
  const [userData, setUserData] = useState(null);

  function handleUserDetails(v) {
    if (!v) return;
    setUserData(v);
  }
  // ================================================================>>>> USER DATA

  // ================================================================>>>> MY CAMERA

  const [selCameraURl, setSelCameraURl] = useState("");
  const [selCameraFullScreen, setSelCameraFullScreen] = useState(false);

  function handleSelCameraToStream(x = "") {
    if (!x) return;
    setSelCameraURl(x);
  }

  function handleSwitchFullScreen() {
    setSelCameraFullScreen(!selCameraFullScreen);
  }

  // ================================================================>>>> MY CAMERA

  // ================================================================>>>> CAMERA SETTINGS

  const [camLocationFilter, setCamLocationFilter] = useState([]);
  // const [camPremisesFilter, setCamPremisesFilter] = useState([]);
  // const [camFloorFilter, setCamFloorFilter] = useState([]);
  // const [camSectionFilter, setCamSectionFilter] = useState([]);

  // function handleUpdatePremisesFilter(v) {
  //   setCamPremisesFilter([...v]);
  // }

  // function handleUpdateFloorFilter(v) {
  //   setCamFloorFilter([...v]);
  // }

  // function handleUpdateSectionFilter(v) {
  //   setCamSectionFilter([...v]);
  // }

  useEffect(() => {
    async function fetchCameraLocations() {
      let response = await getCameraLocations();
      console.log("response from get camera locations ===>", response);
      if (response?.success) {
        // let myArr = [];

        // console.log("getting mapped data ===>", myArr);
        setCamLocationFilter([...response?.data]);
      }
    }
    !camLocationFilter?.length && fetchCameraLocations();
  }, []);

  // ================================================================>>>> CAMERA SETTINGS

  // ================================================================>>>> ALL CAMERA AND STORED FILES LIST

  const [allCamList, setAllCamList] = useState([]);
  const [allStrgFilesList, setAllStrgFilesList] = useState([]);

  useEffect(() => {
    async function fetchCameraDetails(isInitial = true) {
      let response = await getAllCameraInfo();

      console.log(
        "the response fetch camera details =======>",
        response?.data?.AllCamera
      );
      if (!response?.success) return;
      let allCamArr = response?.data?.AllCamera || [];

      setAllCamList([...allCamArr]);
      if (isInitial) {
        let myDetl = response?.data?.AllCamera[0];
        console.log("my ==========>", myDetl);
      }
    }
    fetchCameraDetails();

    async function fetchAllUploadData() {
      let resp = await fetchAllUploadedData();

      console.log("fethed uploaded data =======>", resp?.data?.data?.file_info);
      let myArr = resp?.data?.data?.file_info || [];
      setAllStrgFilesList([...myArr]);
    }
    fetchAllUploadData();
  }, []);

  // ================================================================>>>> ALL CAMERA AND STORED FILES LIST

  // ================================================================>>>> AI MODELING >> INPUT PAGE

  const [editJobCardDetails, setEditJobCardDetails] = useState({
    isEditedCall: false,
    updateDetails: {},
  });

  function handleEditJobCardDetails(x = "", isEditVal = true) {
    if (!x) {
      setEditJobCardDetails({
        isEditedCall: isEditVal,
        updateDetails: {},
      });
    } else {
      setEditJobCardDetails({ ...x, isEditedCall: isEditVal });
    }
  }

  // ================================================================>>>> AI MODELING >> INPUT PAGE

  // ================================================================>>>> AI MODELING >> JOB PAGE

  const [jobCardArr, setJobCardArr] = useState([]);

  function handleSetJobCardArr(x = "") {
    if (!x) return;
    setJobCardArr(x);
  }

  // ================================================================>>>> AI MODELING >> JOB PAGE

  // ================================================================>>>> USER MANAGEMENT >> CREATE USER

  const [userAccessTableFeatures, setUserAccessTableFeatures] = useState([]);
  // fetchUserAccessFeatures

  // function handleSetUsrAccTableFeatures(){

  // }

  useEffect(() => {
    async function getUsrAccessFeatures() {
      let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name,
        myCookie = localStorage?.getItem(acc_token_key_name);
      let resp = await fetchUserAccessFeatures(myCookie);

      if (resp?.success) {
        console.log(
          "response from get user access feature context ===========>",
          resp?.data?.data?.data
        );
        let arr = resp?.data?.data?.data;
        setUserAccessTableFeatures([...arr]);
      }
    }
    !userAccessTableFeatures[0] && getUsrAccessFeatures();
  }, []);
  // ================================================================>>>> USER MANAGEMENT >> CREATE USER

  // ================================================================>>>> IMAGE ANNOTATION SCREEN

  const [preAnnotationData, setPreAnnotationData] = useState(null);

  const [annClassList, setAnnClassList] = useState({});

  const [currSelectedClass, setCurrSelectedClass] = useState({
    id: "",
    name: "",
    color: "",
    count: 0,
  });

  const [saveFabricSession, setSaveFabricSession] = useState([]);
  const [imgForAnnotation, setImgForAnnotation] = useState([]); // image frames for Annotation...
  const [forceClearUsrData, setForceClearUsrData] = useState(false);
  const [stopfetchUsrProg, setStopfetchUsrProg] = useState(false);
  const [activeAnnTool, setActiveAnnTool] = useState("select");

  // const [imgScaleFacByCanID, setImgScaleFacByCanID] = useState([]);

  const fabricCanRefList = useRef([]);

  //   function handleSetImgScaleFacByCanvasID(v){
  // setImgScaleFacByCanID([...v])
  //   }

  function handleActiveAnnTool(v = "select") {
    setActiveAnnTool(v);
  }

  function handleUpdateFabricCanvasRefList(fabCanRef) {
    fabricCanRefList.current.push(fabCanRef);
  }

  function handleAddAnnClassList(
    v = { id: "", name: "", color: "", count: 0 }
  ) {
    // console.log("incoming class data to add ===>", v);
    setCurrSelectedClass({ ...v });
  }

  function handleChangeCurrSelClass(
    v = { id: "", name: "", color: "", count: 0 }
  ) {
    setCurrSelectedClass({ ...v });
  }

  function handleUpdateAnnClassList(d = []) {
    console.log("input to update annotation class ===>", d);
    setAnnClassList({ ...d });
  }

  function handleSetPreAnnData(data = "") {
    setPreAnnotationData(data);
  }

  function handleUpdateSessionData(d = []) {
    console.log("updating session data for fabric canvas ==>", d);
    setSaveFabricSession([...d]);
  }

  function handleUpdateImgAnntnFrameList(v = []) {
    setImgForAnnotation([...v]);
  }

  const isLoadingUsrProgBySavUsrProg = useRef("loading");

  async function handleUpdateStopfetchUsrProg(v) {
    setStopfetchUsrProg(v);

    return new Promise((res, rej) => {
      console.log(
        "isLoadingUsrProgBySavUsrProg.current ===>",
        isLoadingUsrProgBySavUsrProg.current
      );
      if (isLoadingUsrProgBySavUsrProg.current === "done") {
        res(true);
      } else if (isLoadingUsrProgBySavUsrProg.current === "failed") {
        rej(false);
      }
    });
  }

  function handleUpdateForceClearUsrData(v) {
    setForceClearUsrData(v);
  }

  let classValueGlobal = null,
    fabricValueGlobal = [],
    // forceClearUsrProgress = false,
    fabric_unmount_iteration_count = 0;

  async function handleSaveUnfinishedProgress(classValue, fabricValue) {
    // console.log(
    //   "calling save user progress on unmount ===>",
    //   forceClearUsrProgress
    // );
    setStopfetchUsrProg(true);
    fabric_unmount_iteration_count++;
    if (forceClearUsrData) {
      // console.log(
      //   "set force clear usr data conditional value ===>    fabric_unmount_iteration_count:  ",
      //   fabric_unmount_iteration_count,
      //   "      fabricCanRefList.current.length:   ",
      //   fabricCanRefList.current.length
      // );
      // if (fabric_unmount_iteration_count === fabricCanRefList.current.length) {
      //   forceClearUsrProgress = false;
      //   console.log("setting force clear usr data ===>", false);
      //   setForceClearUsrData(false);
      // }
      return;
    }
    if (!classValueGlobal) {
      classValueGlobal = { ...classValue };

      fabricValueGlobal.push(fabricValue);
    } else {
      fabricValueGlobal.push(fabricValue);

      console.log(
        "before handle save api call if block =====>",
        "    faric_value length:    ",
        fabricValueGlobal.length,
        "    fabric ref length:    ",
        fabricCanRefList.current.length
      );

      if (fabricValueGlobal.length === fabricCanRefList.current.length) {
        // toast.success("ready to send unfinished progress");
        console.log(
          "logging fabricGlobalValue after success toast =====>",
          "    faric_value:    ",
          fabricValueGlobal,
          "    class_value:    ",
          classValueGlobal
        );

        fabricCanRefList.current = [];
        let respSaveUsrProg = await saveImageAnnUserProgress({
          fabric_value: fabricValueGlobal || [],
          class_value: classValueGlobal || {},
        });

        if (respSaveUsrProg?.success) {
          let annObj = respSaveUsrProg.data?.data?.data?.annotation_obj;
          isLoadingUsrProgBySavUsrProg.current = "done";
          console.log("response save user ann progress ===>", annObj);
          // usrProgAnnData.current = annObj;
        } else {
          isLoadingUsrProgBySavUsrProg.current = "failed";
          console.log(
            "problem saving user progress for img ann ===>",
            respSaveUsrProg.data?.message
          );
        }
      }
    }
  }

  async function handleClearAnnUsrprogress() {
    classValueGlobal = null;
    fabricValueGlobal = [];
    // forceClearUsrProgress = true;
    setForceClearUsrData(true);
    // console.log(
    //   "calling reset user progress by force ===>",
    //   forceClearUsrProgress
    // );
    fabricCanRefList.current = [];
    let respSaveUsrProg = await saveImageAnnUserProgress({});
    if (respSaveUsrProg?.success) {
      console.log("response save user ann progress ===>", respSaveUsrProg.data);
      setAnnClassList({});
      setCurrSelectedClass({ id: "", name: "", color: "", count: 0 });
      setSaveFabricSession([]);
    } else {
      console.log(
        "problem saving user progress for img ann ===>",
        respSaveUsrProg.data?.message
      );
    }
  }

  // ================================================================>>>> IMAGE ANNOTATION SCREEN

  const contextValue = {
    sideNav: {
      selScreen,
      handleSelScreen,
    },
    mySelCamStream: {
      handleSelCameraToStream,
      selCameraURl,
      selCameraFullScreen,
      handleSwitchFullScreen,
    },
    cameraFilters: {
      location: camLocationFilter,
      // premises: camPremisesFilter,
      // floor: camFloorFilter,
      // section: camSectionFilter,
      // handleUpdateFloorFilter,
      // handleUpdatePremisesFilter,
      // handleUpdateSectionFilter,
    },
    user: {
      updateUserDetails: handleUserDetails,
      userData,
    },
    jobPage: {
      handleSetJobCardArr,
      jobCardArr,
      handleEditJobCardDetails,
      editJobCardDetails,
    },
    allDetails: {
      allCamList: allCamList,
      allStrdFilesList: allStrgFilesList,
    },
    userManagement: {
      accessTableFeatures: userAccessTableFeatures,
    },
    imgAnnotation: {
      preAnnotationData,
      handleSetPreAnnData,
      annClassList,
      currSelectedClass,
      saveFabricSession,
      forceClearUsrData,
      fabricCanRefList,
      stopfetchUsrProg,
      activeAnnTool,
      imgForAnnotation,
      handleAddAnnClassList,
      handleChangeCurrSelClass,
      handleUpdateAnnClassList,
      handleUpdateSessionData,
      handleSaveUnfinishedProgress,
      handleClearAnnUsrprogress,
      handleUpdateFabricCanvasRefList,
      handleUpdateStopfetchUsrProg,
      handleUpdateForceClearUsrData,
      handleActiveAnnTool,
      handleUpdateImgAnntnFrameList,
    },
  };

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
