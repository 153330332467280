import "./addEmployeeScrn.css";

import React, { useEffect, useState, useRef } from "react";

import FormInpWrpprFaceDetection from "../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";
import { FormInp } from "../../../utility/formElements/formElements";

import { FiCamera } from "react-icons/fi";
import { FileSyncButton } from "../../../utility/button/button";

import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { waitingFunction } from "../../../utility/functions/helperFunc";

// import axios from "axios";

const InitialDataJobDetl = {
  emply_id: "",
  emply_name: "",
  emply_email: "",
  mob_number: "",
  user_id: "",
};

function AddEmployeeScrn({
  formData = InitialDataJobDetl,
  byMail = false,
  updateIsCaptured = () => {},
  sendImageInfo = () => {},
}) {
  const [formDetails, setFormDetails] = useState({
    ...formData,
  });

  const [formDetailsErr, setFormDetailsErr] = useState({
    ...InitialDataJobDetl,
  });

  const [cirProgress, setCirProgress] = useState(null);
  // const [serverImg, setServerImg] = useState("");
  const [websockMsgRespData, setWebsockMsgRespData] = useState(null);
  const [lastImg, setLastImg] = useState("");
  const [closeCam, setCloseCam] = useState(false);
  const [disbleCapFace, setDisbleCapFace] = useState(false);
  const [displayCapFace, setDisplayCapFace] = useState(false);
  const [faceIndicators, setFaceIndicators] = useState({
    forward: 0,
    upward: 0,
    left: 0,
    right: 0,
    downward: 0,
  });
  // const [closeSocket, setCloseSocket] = useState(false);

  const videoRef = useRef(),
    webStream = useRef(null);

  // ----------------- web socket new reference -----------------

  const socketRef = useRef(null);

  const sendMessage = (msg = "") => {
    console.log("inside snd message function...");
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      // console.log("websocket connection is redy to send messasge", msg);
      socketRef.current.send(msg);
    }
  };

  // ----------------- web socket new reference -----------------

  let be_max_count = 0;
  let be_current_count = 0;

  // let imgTag = (
  //   <img
  //     // key={`my_key_${retake}`}
  //     src={`${process.env.REACT_APP_AdminBaseUrl}/camera/facecapture/`}
  //     alt="web_cam"
  //     style={{
  //       borderRadius: "1rem",
  //       background: "#05306b",
  //       padding: "0.3rem",
  //     }}
  //   />
  // );

  const CircularProgressStyled = styled(CircularProgress)({
    position: "absolute",
    top: "-10px",
    left: "-9px",
    zIndex: 11,
    color: "#4caf50",
  });

  // const [isCapture, setIsCapture] = useState(false);
  // const [retake, setRetake] = useState(imgTag);

  function handleSetInpforform(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setFormDetails({ ...formDetails, [name]: value });
  }

  // async function captureFace() {
  //   // let resp = await axios.get(
  //   //   `${process.env.REACT_APP_AdminBaseUrl}/camera/facecapture/`
  //   // );

  //   // console.log("response capture face ===>", resp);

  //   // imgTag = null;
  //   setRetake(null);

  //   let x = setTimeout(()=>{
  //     // imgTag =
  //     setRetake(imgTag);
  //     clearTimeout(x);
  //   },1000);
  // }

  // async function callFaceCapture(){
  //   try {

  //     let resp = await axios.get(`${process.env.REACT_APP_AdminBaseUrl}/camera/webrtcvideo/`);
  //     console.log("responde ====>",resp);

  //   } catch (error) {
  //     console.log("error fetching the url ===>",error);
  //   }

  // }

  function checkInpFieldValues() {
    let isOkay = true,
      errObj = {
        emply_name: false,
        emply_id: false,
      };
    // console.log("inside check input values --->",formDetails?.emply_name?.trim());
    if (!formDetails?.emply_name || !formDetails?.emply_name?.trim()) {
      errObj.emply_name = true;
      isOkay = false;
    } else {
      errObj.emply_name = false;
    }
    if (!formDetails?.emply_id || !formDetails?.emply_id?.trim()) {
      // console.log("inside empty emply_id values --->",formDetails?.emply_name?.trim());
      errObj.emply_id = true;
      isOkay = false;
    } else {
      errObj.emply_id = false;
    }
    setFormDetailsErr({ ...formDetailsErr, ...errObj });
    return isOkay;
  }

  async function stopWebCamStream() {
    console.log("inside close ");

    webStream.current?.getTracks().forEach((track) => {
      if (track.readyState === "live") {
        track.stop();
        // videoRef.current.srcObject = lastImg;
        setCloseCam(true);
      }
    });
  }
  let dontCallMe = false;
  let socket = null;
  async function newCaptureFaceFunction() {
    socketRef.current = new WebSocket(`${process.env.REACT_APP_Websocket}/webcam/`);

    console.log("getting my user id in formdata", formData, formData?.user_id);

    // Connection opened
    socketRef.current.onopen = () => {
      console.log("WebSocket connection opened");

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((userStream) => {
          // console.log("getting user stream ===>", userStream);
          videoRef.current.srcObject = userStream;
          webStream.current = userStream;

          setDisbleCapFace(true);

          // Capture a frame and convert it to base64
          const captureFrame = () => {
            if (socketRef.current && socketRef.current.readyState !== WebSocket.OPEN) return;
            console.log("inside capture frame function ====>");
            if (!videoRef?.current) return;
            console.log("video refernce found 11111 ====>");
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            canvas.width = videoRef.current?.videoWidth;
            canvas.height = videoRef.current?.videoHeight;
            context.drawImage(
              videoRef.current,
              0,
              0,
              canvas.width,
              canvas.height
            );

            const base64Data = canvas.toDataURL("image/jpeg"); // or 'image/png'
            setLastImg(base64Data);
            // Now you can send base64Data to the server
            // console.log("Base64 Image:", base64Data);

            // socket.send(
            //   JSON.stringify({
            //     event: cirProgress < 100 ? "frameImg" : "done",
            //     data: base64Data,
            //     ajay: "Sharma",
            //     max_count: be_max_count,
            //     current_count: be_current_count,
            //     empname: formDetails?.emply_name,
            //     empid: formDetails?.emply_id,
            //   })
            // );

            sendMessage(
              JSON.stringify({
                event: cirProgress < 100 ? "frameImg" : "done",
                data: base64Data,
                ajay: "Sharma",
                max_count: be_max_count,
                current_count: be_current_count,
                empname: formDetails?.emply_name,
                empid: formDetails?.emply_id,
              })
            )

            // Repeat the process for continuous streaming
            requestAnimationFrame(captureFrame);
          };

          // Start capturing frames
          requestAnimationFrame(captureFrame);
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }

    // socket.addEventListener("open", (event) => {
    //   console.log("WebSocket connection opened");

    //   navigator.mediaDevices
    //     .getUserMedia({ video: true, audio: true })
    //     .then((userStream) => {
    //       // console.log("getting user stream ===>", userStream);
    //       videoRef.current.srcObject = userStream;
    //       webStream.current = userStream;

    //       setDisbleCapFace(true);

    //       // Capture a frame and convert it to base64
    //       const captureFrame = () => {
    //         if (socket.readyState !== WebSocket.OPEN) return;
    //         console.log("inside capture frame function ====>");
    //         if (!videoRef?.current) return;
    //         console.log("video refernce found 11111 ====>");
    //         const canvas = document.createElement("canvas");
    //         const context = canvas.getContext("2d");

    //         canvas.width = videoRef.current?.videoWidth;
    //         canvas.height = videoRef.current?.videoHeight;
    //         context.drawImage(
    //           videoRef.current,
    //           0,
    //           0,
    //           canvas.width,
    //           canvas.height
    //         );

    //         const base64Data = canvas.toDataURL("image/jpeg"); // or 'image/png'
    //         setLastImg(base64Data);
    //         // Now you can send base64Data to the server
    //         // console.log("Base64 Image:", base64Data);

    //         socket.send(
    //           JSON.stringify({
    //             event: cirProgress < 100 ? "frameImg" : "done",
    //             data: base64Data,
    //             ajay: "Sharma",
    //             max_count: be_max_count,
    //             current_count: be_current_count,
    //             empname: formDetails?.emply_name,
    //             empid: formDetails?.emply_id,
    //           })
    //         );

    //         // Repeat the process for continuous streaming
    //         requestAnimationFrame(captureFrame);
    //       };

    //       // Start capturing frames
    //       requestAnimationFrame(captureFrame);
    //     })
    //     .catch((error) => {
    //       console.error("Error accessing webcam:", error);
    //     });
    // });

    // Listen for messages from the server

    let initial = true;
    socketRef.current.onmessage = (msg) => {
      let socketRespData = JSON.parse(msg.data);

      console.log("socket message from server:", socketRespData?.data);

      let respData1 = JSON.parse(msg.data);
      be_max_count = respData1.max_count;
      be_current_count = respData1.current_count;

      console.log("be_current_count - BE:", respData1);
      console.log("be_current_image_count - BE:", be_current_count);

      if (initial) {
        setWebsockMsgRespData(respData1);
        initial = false;
      }

      setCirProgress(be_current_count);

      setFaceIndicators({
        forward: respData1?.img_info["Looking Forward"],
        upward: respData1?.img_info["Looking Upward"],
        left: respData1?.img_info["Looking Left"],
        right: respData1?.img_info["Looking Right"],
        downward: respData1?.img_info["Looking Downward"],
      });

      if (respData1.event === "stop_img_process") {
        if (byMail) {
          updateIsCaptured(true);
        }
      }

      if (respData1.event === "save_img_count") {
        let totalCount =
          Number(respData1?.img_info["Looking Forward"]) +
          Number(respData1?.img_info["Looking Upward"]) +
          Number(respData1?.img_info["Looking Left"]) +
          Number(respData1?.img_info["Looking Right"]) +
          Number(respData1?.img_info["Looking Downward"]);

        sendMessage(
          JSON.stringify({
            event: "save_img_count",
            user_id: formDetails?.user_id,
            face_img_s3dir: respData1?.face_img_s3dir,
            current_progess: totalCount || 0
          })
        );
      }
    }
    // socket.addEventListener("message", (msg) => {
    //   let socketRespData = JSON.parse(msg.data);

    //   console.log("socket message from server:", socketRespData?.data);

    //   let respData1 = JSON.parse(msg.data);
    //   be_max_count = respData1.max_count;
    //   be_current_count = respData1.current_count;

    //   console.log("be_current_count - BE:", respData1);
    //   console.log("be_current_image_count - BE:", be_current_count);

    //   if (initial) {
    //     setWebsockMsgRespData(respData1);
    //     initial = false;
    //   }

    //   setCirProgress(be_current_count);

    //   // forward:0,
    //   // upward:0,
    //   // left:0,
    //   // right:0,
    //   // downward:0

    //   /*
    //   "Looking Left": 0,
    //     "Looking Right": 0,
    //     "Looking Upward": 0,
    //     "Looking Downward": 0,
    //     "Looking Forward": 10
    //   */

    //   setFaceIndicators({
    //     forward: respData1?.img_info["Looking Forward"],
    //     upward: respData1?.img_info["Looking Upward"],
    //     left: respData1?.img_info["Looking Left"],
    //     right: respData1?.img_info["Looking Right"],
    //     downward: respData1?.img_info["Looking Downward"],
    //   });

    //   if (respData1.event === "stop_img_process") {
    //     if (byMail) {
    //       updateIsCaptured(true);
    //       // let totalCount =
    //       //   Number(respData1?.img_info["Looking Forward"]) +
    //       //   Number(respData1?.img_info["Looking Upward"]) +
    //       //   Number(respData1?.img_info["Looking Left"]) +
    //       //   Number(respData1?.img_info["Looking Right"]) +
    //       //   Number(respData1?.img_info["Looking Downward"]);
    //       // sendImageInfo({
    //       //   user_id: formDetails?.user_id,
    //       //   face_img_s3dir: respData1?.face_img_s3dir,
    //       //   current_progess: totalCount || 0,
    //       // });
    //     }
    //   }

    //   if (respData1.event === "save_img_count") {
    //     let totalCount =
    //       Number(respData1?.img_info["Looking Forward"]) +
    //       Number(respData1?.img_info["Looking Upward"]) +
    //       Number(respData1?.img_info["Looking Left"]) +
    //       Number(respData1?.img_info["Looking Right"]) +
    //       Number(respData1?.img_info["Looking Downward"]);
    //     socket.send(
    //       JSON.stringify({
    //         event: "save_img_count",
    //         user_id: formDetails?.user_id,
    //         face_img_s3dir: respData1?.face_img_s3dir,
    //         current_progess: totalCount || 0,
    //       })
    //     );
    //   }

    //   // if (dontCallMe) return;
    //   // console.log("running after dont call me =====>");
    //   // let x = setInterval(() => {
    //   //   dontCallMe = true;
    //   //   setCirProgress((prev) => {
    //   //     if (prev < 100) {
    //   //       return prev + 5;
    //   //     } else {
    //   //       stopWebCamStream();
    //   //       socket.close();
    //   //       clearInterval(x);
    //   //       return prev;
    //   //     }
    //   //   });
    //   // }, 1000);

    //   // setServerImg(respData1?.data);
    // });

    // Connection closed
    
    
    socketRef.current.onclose = () => {
      console.log("socket closed...");
      // toast.warn("Disconnected !");
      // socket.close();
      stopWebSocket();
      stopWebCamStream();
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
      // toast.error("Error Occurred !");
    };

    // socket.addEventListener("close", (event) => {
    //   console.log("WebSocket connection closed");
    //   socket.close();
    //   stopWebCamStream();
    // });
  }

  const stopWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      // toast.warn("socket connection disconnected!");
    }
  };

  async function handleRetake() {
    setCloseCam(false);
    // setCirProgress(0);
    // dontCallMe = false;
    // await waitingFunction(500);
    newCaptureFaceFunction();
  }

  function handleCaptureBtnClick() {
    if (!checkInpFieldValues()) return;
    setDisplayCapFace(true);
    setDisbleCapFace(true);
    newCaptureFaceFunction();
  }

  useEffect(() => {
    return () => {
      // {
      //     user_id: formDetails?.user_id,
      //     face_img_s3dir: websockMsgRespData?.face_img_s3dir,
      //     current_progess: totalCount || 0,
      //   }

      let totalCount =
        Number(faceIndicators?.downward) +
        Number(faceIndicators?.upward) +
        Number(faceIndicators?.forward) +
        Number(faceIndicators?.left) +
        Number(faceIndicators?.right);

      // socketConnectWebSocket.current.send(
      //   JSON.stringify({
      //     event: "save_img_count",
      //     user_id: formDetails?.user_id,
      //     face_img_s3dir: websockMsgRespData?.face_img_s3dir,
      //     current_progess: totalCount || 0,
      //   })
      // );

      sendMessage(
        JSON.stringify({
          event: "save_img_count",
          user_id: formDetails?.user_id,
          face_img_s3dir: websockMsgRespData?.face_img_s3dir,
          current_progess: totalCount || 0,
        })
      );

      // socketConnectWebSocket.current.close();
      stopWebSocket();
      stopWebCamStream();
    };
  }, []);

  return (
    <div className="connect_storage_screen_container">
      <h2>Add Employee</h2>
      <p className="connect_storage_screen_container_p">
        Enter Your Emplyee Details To Make Your Own Custom Model{" "}
      </p>
      <div className="inp_page_ai_model_flex_form_inp_cont">
        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Employee ID"}
            placeHldr={"Enter Employee ID"}
            nameStr={"emply_id"}
            value={formDetails?.emply_id}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDetailsErr?.emply_id && "Field can't be left empty"}
            isDisable={byMail}
          />
        </FormInpWrpprFaceDetection>

        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Employee Name"}
            placeHldr={"Enter Employee Name"}
            nameStr={"emply_name"}
            value={formDetails?.emply_name}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDetailsErr?.emply_name && "Field can't be left empty"}
            isDisable={byMail}
          />
        </FormInpWrpprFaceDetection>
      </div>
      <div className="inp_page_ai_model_flex_form_inp_cont">
        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Employee Email address"}
            placeHldr={"Enter Employee Email address"}
            nameStr={"emply_email"}
            value={formDetails?.emply_email}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDetailsErr?.emply_email && "Field can't be left empty"}
            isDisable={byMail}
          />
        </FormInpWrpprFaceDetection>

        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Mobile Number"}
            placeHldr={"Enter your mobile number"}
            nameStr={"mob_number"}
            value={formDetails?.mob_number}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDetailsErr?.mob_number && "Field can't be left empty"}
            isDisable={byMail}
          />
        </FormInpWrpprFaceDetection>
      </div>
      <button
        className="sidenavigation_newcamerabutton"
        onClick={() => handleCaptureBtnClick()}
        disabled={disbleCapFace}
      >
        <FiCamera />
        <p>Capture</p>
      </button>
      {displayCapFace && (
        <div className="face_detec_web_cam_main_cont">
          <div className="face_detec_web_cam_main_cont_face_indicators">
            <p>Forward: {faceIndicators?.forward}/10</p>
            <p>Upward: {faceIndicators?.upward}/10</p>
            <p>Downward: {faceIndicators?.downward}/10</p>
            <p>Left: {faceIndicators?.left}/10</p>
            <p>Right: {faceIndicators?.right}/10</p>
          </div>
          {!byMail && (
            <>
              <FileSyncButton
                title="Retake"
                stylObj={{
                  background: "#FF004C",
                  color: "white",
                  // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  height: "3rem",
                  fontStyle: "normal",
                  alignSelf: "end",
                  fontWeight: "400",
                }}
                handleClick={() => {
                  console.log("circular progress retake...", cirProgress);
                  if (cirProgress < 100) return;
                  console.log("clicking retake face capture...");
                  handleRetake();
                }}
              />
            </>
          )}
          <div className="web_cam_stream_cir_cont">
            <CircularProgressStyled
              variant="determinate"
              value={cirProgress}
              // size={"30rem"}
              size={"480px"}
              thickness={1}
            />
            <div className="web_cam_stream_cont">
              {/* <video ref={videoRef} autoPlay muted playsInline style={{minHeight:"464px"}} /> */}
              {!closeCam ? (
                <video
                  ref={videoRef}
                  autoPlay
                  muted
                  playsInline
                  style={{ minHeight: "464px" }}
                />
              ) : (
                <img src={lastImg} alt="my_img" />
              )}
            </div>
          </div>
        </div>
      )}

      {/* {isCapture && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "2rem",
            paddingTop: "3rem",
          }}
        >
          {/* <FileSyncButton
            title="retake"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              maxHeight: "3rem",
              marginRight: "3rem",
            }}
            handleClick={ async() => {
              await captureFace();
              // setRetake(retake+1)
            }}
          /> */}

      {/* <img
              src={`${process.env.REACT_APP_AdminBaseUrl}/camera/facecapture/`}
              key={`my_key_${retake}`}
              alt="web_cam"
              style={{
                borderRadius: "1rem",
                background: "#05306b",
                padding: "0.3rem",
              }}
            />
            
        </div>
      )}
     */}
      {!byMail && (
        <div className="selStorageModalActvArea_btnWrppr margin_top_append">
          <FileSyncButton
            title="Save"
            stylObj={{
              boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              color: "white",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
          <FileSyncButton
            title="Cancel"
            stylObj={{
              background: "white",
              color: "#05306B",
              // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default AddEmployeeScrn;
