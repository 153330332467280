import axios from "axios";
import { getConfigHeadersForToken } from "../utility/functions/helperFunc";

const AdminBaseUrl = process.env.REACT_APP_AdminBaseUrl;

console.log("admin base url =============>", AdminBaseUrl);

// ************************************** ROLE BASED SIDEBAR VIEW API'S *******************************
export async function fetchSideBarData() {
  try {
    const url = "/account/sidebar/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch sidebar data request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// sjdnsdjnsdjsnd
// ************************************** UPLOAD PAGE API'S **************************************

export async function sendUploadedData(data) {
  try {
    const url = "/storage/uploadmultifiles/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("uploading file request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function fetchAllUploadedData() {
  try {
    const url = "/storage/getalldbdata/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch uploaded file request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** UPLOAD PAGE API'S **************************************

// ************************************** CONNECT CAMERA SCREEN API'S **************************************

export async function getAllCameraInfo() {
  try {
    const url = "/camera/all_camera_info/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    let statusCode = response?.status;
    response = response?.data?.paylod; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("uploading file request error", error);

    return { success: false, data: error?.response?.data };
  }
}

export async function addCamera(data) {
  try {
    console.log("getting data action api ===>", data);
    const url = "/camera/add_camera/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    let statusCode = response?.status;
    response = response?.data?.paylod; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("uploading file request error", error);

    return { success: false, data: error?.response?.data };
  }
}

export async function editCameraDetails(data) {
  // /camera/edit_camera/
  try {
    console.log("getting data action api ===>", data);
    const url = "/camera/edit_camera/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    let statusCode = response?.status;
    response = response?.data?.paylod; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("uploading file request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function toggleCamera(data) {
  // /camera/toggle_camera/
  try {
    const url = "/camera/toggle_camera/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("toggle camera request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** CONNECT CAMERA SCREEN API'S **************************************

// **************************************  CAMERA SETTINGS SCREEN API'S **************************************

export async function getCameraLocations() {
  try {
    const url = "/camera/get_location_all_camera/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    let statusCode = response?.status;
    response = response?.data?.data; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("get camera locations request error", error);

    return { success: false, data: error?.response?.data };
  }
}

export async function getCameraBuildings(location = "") {
  try {
    const url = `/camera/get_buildings/?location=${location}`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    let statusCode = response?.status;
    response = response?.data?.data; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("get camera buildings filter request error", error);

    return { success: false, data: error?.response?.data };
  }
}

export async function getCameraFloor(location = "", premises = "") {
  try {
    const url = `/camera/get_floor/?premises=${premises}&location=${location}`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    let statusCode = response?.status;
    response = response?.data?.data; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("get camera floor filter request error", error);

    return { success: false, data: error?.response?.data };
  }
}

export async function getCameraSection(
  location = "",
  premises = "",
  floor = ""
) {
  try {
    const url = `/camera/get_section/?location=${location}&premises=${premises}&floor=${floor}`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    let statusCode = response?.status;
    response = response?.data?.data; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("get camera section filter request error", error);

    return { success: false, data: error?.response?.data };
  }
}

export async function getDSModelList() {
  try {
    //camera/dsmodel/
    const url = `/camera/dsmodel/`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("get ds model list request error", error);
    return { success: false, data: error?.response?.data };
  }
}

//   camera/get_camera_settings/?location=jaipur&premises=akj&area=b-block&floor=3

export async function getCameraModelList(
  location = "",
  premises = "",
  floor = "",
  section = ""
) {
  try {
    //camera/dsmodel/
    const url = `/camera/get_camera_settings/?location=${location}&premises=${premises}&area=${section}&floor=${floor}`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("get ds model to camera list request error", error);
    return { success: false, data: error?.response?.data };
  }
}
//
export async function sendCameraModelConnectionList(data) {
  try {
    // console.log("getting data action api ===>", data);
    const url = "/camera/camera_settings/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    let statusCode = response?.status;
    // response = response?.data?.paylod; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("send camera model connection list request error", error);
    return { success: false, data: error?.response?.data };
  }
}

//

export async function deleteCameraModelConnectionListByID(id) {
  try {
    // console.log("getting data action api ===>", data);
    const url = `/camera/delete_camera_settings/?cam_set_id=${id}`;
    const config = getConfigHeadersForToken();
    let response = await axios.delete(AdminBaseUrl + url, config);
    let statusCode = response?.status;
    // response = response?.data?.paylod; // ?.data

    return { success: true, data: response };
  } catch (error) {
    console.log("send camera model connection list request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** CAMERA SETTINGS SCREEN API'S **************************************

// ************************************** CONNECT STORAGE SCREEN API'S **************************************

export async function addStorage(data) {
  try {
    const url = "/storage/addstoragename/";

    const config = getConfigHeadersForToken();

    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("add storage request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function connectStorage(data) {
  try {
    const url = "/storage/updateconnect/";

    const config = getConfigHeadersForToken();

    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("connect storage request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function activateStorage(data) {
  try {
    const url = "/storage/activestorage/";

    const config = getConfigHeadersForToken();

    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("activate storage request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function getAllStorage() {
  try {
    const url = "/storage/allstorage/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("activate storage request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** CONNECT STORAGE SCREEN API'S **************************************

// ************************************** camera test API'S **************************************

export async function testCamera() {
  try {
    const url = "/camera/video_feed/";

    let d = new FormData();
    d.append("camera_id", 1);

    let response = await axios.post(AdminBaseUrl + url, d);
    console.log("response get camera ======>", response);
    return { success: true, data: response };
  } catch (error) {
    console.log("test camera request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** camera test API'S **************************************

// ************************************** ALL CAMERA SCREEN API'S **************************************

export async function getAllCameraList() {
  try {
    const url = "/camera/all_video_urls/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("get all camera request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** ALL CAMERA SCREEN API'S **************************************

// ************************************** AI Modeling input SCREEN API'S **************************************

export async function submitJobDetails(data) {
  try {
    const url = "/camera/save_aimodel/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("create job card request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function fetchJobDetails() {
  try {
    const url = "/camera/all_aimodel/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch job details request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function editJobDetails(id, data = {}) {
  try {
    const url = `/camera/edit_aimodel/${id}/`;
    const config = getConfigHeadersForToken();
    let response = await axios.patch(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("edit job details request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function fetchPreDefModels() {
  try {
    const url = `/camera/pertrainmodels/`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch pre define model request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// camera/all_aimodel/1

export async function fetchJobDetailsByID(id = "") {
  try {
    const url = `/camera/all_aimodel/${id}/`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch job details by id request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** AI Modeling input SCREEN API'S **************************************

// ************************************** login/signup SCREEN API'S **************************************

export async function createNewOrganisation(data) {
  try {
    const url = "/account/reg_new_user/";
    let response = await axios.post(AdminBaseUrl + url, data);
    return { success: true, data: response };
  } catch (error) {
    console.log("create new organisation request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function loginUser(data) {
  try {
    const url = "/account/loginapi/";
    let response = await axios.post(AdminBaseUrl + url, data);
    return { success: true, data: response };
  } catch (error) {
    console.log("login user request error", error);
    return { success: false, data: error?.response?.data };
  }
}

//

export async function fetchUser(token) {
  try {
    const url = "/account/profile/";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch user request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function resetPassword(data, token) {
  try {
    const url = `/account/verify_user/${token}/`;
    let response = await axios.post(AdminBaseUrl + url, data);
    return { success: true, data: response };
  } catch (error) {
    console.log("verfiy user request error", error?.response?.data);
    return { success: false, data: error?.response?.data };
  }
}

// account/forget_password/?email=gaurav.surolia@marktine.com

export async function forgotPassword(email = "") {
  try {
    const url = `/account/forget_password/?email=${email}`;

    let response = await axios.get(AdminBaseUrl + url);
    return { success: true, data: response };
  } catch (error) {
    console.log("forgot password request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** login/signup SCREEN API'S **************************************

//
// ************************************** Add Employee API'S **************************************

export async function fetchUserAccessFeatures(token) {
  try {
    const url = "/account_feature/features/";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch user access features request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function fetchEmployeesData(token, image_count = false) {
  try {
    const static_url = `/account/all_employees/`;
    const url = image_count
      ? `${static_url}?image_count=${image_count}`
      : `${static_url}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch employees data request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function sendMailToEmployees(data, token) {
  try {
    const url = "/account/send_mail_to_emp/";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("send mail to employees request error", error);
    return { success: false, data: error?.response?.data };
  }
}

/*
/account/verify_employee/gAAAAABlygfncouIsZpaRnayP0hdtGJIZ4o9ayZ_Q_jK7ZRo6NvlYw6jMAlCYqZxCA2aOHpdPyt-i_bIoQ1yOKUqnQ-
CKKmw3esYYePg3R7OGo5GFkvSgumJTdpSOrhNI5oRYC_-6znLTlpp0W8e9k-A8ZkfHjToPA==/
*/

export async function verfiyEmployeesBeforeCapturingFace(data) {
  try {
    const url = `/account/verify_employee/${data}/`;

    let response = await axios.post(AdminBaseUrl + url, data);
    return { success: true, data: response };
  } catch (error) {
    console.log("verify employees before face capture request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function startTrainingUsrMngmntAddEmply() {
  try {
    const url = `/camera/facetraning/`;

    let response = await axios.get(AdminBaseUrl + url);
    return { success: true, data: response };
  } catch (error) {
    console.log("start training user management request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function saveUserImageToDir(data) {
  try {
    const url = `/account/save_user_image/`;

    let response = await axios.post(AdminBaseUrl + url, data);
    return { success: true, data: response };
  } catch (error) {
    console.log("save user image to directory request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** Add Employee API'S **************************************
export async function addEmployee(data, token) {
  try {
    const url = `/account/add_employes/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("add employee error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** Attendance management screen ****************************

export async function getAttendanceDataByEmpID(
  data = { emp_id: "", filter: "weekly" }
) {
  try {
    const url = `/account/emp_attendance/?employee_id=${data?.emp_id}&date_filter=${data?.filter}`;
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch uploaded file request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** Attendance management screen ****************************

// ************************************** Image Annotation screen ****************************

export async function sendImageAnnotationData(data) {
  try {
    const url = "/account/annotation/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("send image annotation request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function fetchImgAnnUsrProgress() {
  try {
    const url = "/account/annotation/user_progress/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch image annotation user progress request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function saveImageAnnUserProgress(data) {
  try {
    const url = "/account/annotation/user_progress/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("save img annotation user progress request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** Image Annotation screen ****************************

// ************************************** access management screen ****************************

export async function getAccessFeatureList() {
  try {
    const url = "/account/all_features/";
    const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch accesses request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function updateAccessManagementList(data) {
  try {
    const url = `/account/access_management/`;
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("access management update request error", error);
    return { success: false, data: error?.response?.data };
  }
}

export async function sendAccessPermissionData(data) {
  try {
    const url = "/account/access_management/";
    const config = getConfigHeadersForToken();
    let response = await axios.post(AdminBaseUrl + url, data, config);
    return { success: true, data: response };
  } catch (error) {
    console.log("send access permission request error", error);
    return { success: false, data: error?.response?.data };
  }
}

// ************************************** access management screen ****************************

// ************************************** SINGLE CAMERA SCREEN API'S *******************************

export async function getVideoFeedByCamID(data) {
  try {
    const url = `/camera/hls/stream/vclip.m3u8?company_name=${data.company}&recording_date=${data.recordingDate}&cam_id=${data.cam_id}`;
    // const config = getConfigHeadersForToken();
    let response = await axios.get(AdminBaseUrl + url); // config
    return { success: true, data: response };
  } catch (error) {
    console.log("fetch accesses request error", error);
    return { success: false, data: error?.response?.data };
  }
}
