export function TableComp({ headers = [], data = [], actions = {} }) {
  /*
    
    SAMPLE USE CASE:
    <TableComp
        headers={headers2}
        data={data2}
        Edit={{
            action: (info) => {
            return (
                <ButtonComp
                className="bg-green-600 text-white p-2 font-manrope font-semibold rounded-md flex gap-4 mx-auto"
                clickHandler={() => console.log("clicked edit", info)}
                icon={<SquarePen size={18} />}
                />
            );
            },
        }}
    />
    
    */
  return (
    <table className="border-separate border-spacing-2">
      <thead className="pr-3">
        <tr>
          {headers.map((h) => (
            <th className="text-blue-900 bg-blue-200 rounded-lg px-4 py-2 font-semibold">
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="max-h-96 overflow-y-scroll scrollbar">
        {data.map((d) => (
          <tr>
            {headers.map((e) => (
              <td className="bg-white text-slate-900 rounded-lg px-4 py-2">
                {actions[e]?.action({ row: d, cell: e }) || d[e]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
