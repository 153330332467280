export function FaceDetectionBoundBxWrppr({ divListArr = [] }) {
  return (
    <>
      {divListArr?.map((e, i) => (
        <div key={`bounding_box_wrapper_${i}`}>
          <p
            style={{
              position: "absolute",
              left: e?.paraLeft + "%",
              top: e?.paraTop + "%",
              color: e?.name?.toLowerCase() === "unknown" ? "red" : "#04AA6D",
              fontSize: "10px",
              fontWeight: 800,
              background: "white",
              padding: "0 .1rem",
              zIndex: 3,
            }}
          >
            {e?.name}
          </p>
          <div style={{ ...e?.style }}></div>
        </div>
      ))}
    </>
  );
}

export function ObjectCountBoundBxWrppr({ divListArr = [] }) {
  return (
    <>
      {divListArr?.map((e, i) => (
        <div key={`bounding_box_wrapper_object_count_${i}`}>
          <p
            style={{
              position: "absolute",
              left: e?.paraLeft + "%",
              top: e?.paraTop + "%",
              color: "red",
              fontSize: "10px",
              fontWeight: 800,
              background: "white",
              padding: "0 .1rem",
              zIndex: 3,
            }}
          >
            {e?.name}
          </p>
          <div style={{ ...e?.style }}></div>
        </div>
      ))}
    </>
  );
}
