import "./loadingSpinner.css";
import React,{useState} from "react";
import Stack from '@mui/material/Stack';
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
// import './loadingSpinner.css';

const StyledLoader = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LoadingComponent = ({ size = 40, color = "#57A0D3", styl={} }) => {
  return (
    <div className="myloaderspinner" style={{...styl}} >
      <StyledLoader>
        <CircularProgress size={size} style={{ color }} />
      </StyledLoader>
    </div>
  );
};

export default LoadingComponent;


export const LoadingComponentStacked = ({ size = 40, color = "#57A0D3", styl={} }) => {
  return (
    // <div className="myloaderspinner" style={{...styl}} >
      <Stack sx={{ ...styl }} spacing={2} direction="row">
        <CircularProgress size={size} color="inherit" />
      </Stack>
    // </div> 
  );
};





export function LoaderWithOverlayBlur ({children,isLoading}) {

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader"></div>
            {/* <div className="loader-text">Loading...</div> */}
          </div>
        </div>
      )}
      <div className={isLoading ? 'content blur' : 'content'}>
        {/* Your content */}
        {children}
      </div>
    </>
  );
};