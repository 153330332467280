import React, { useState } from "react";
import "./myCameraRecordingScreen.css";

import { AllCamScrnFormatBtn } from "../../../utility/button/button";
import { CiBoxList } from "react-icons/ci";
import { IoGridOutline } from "react-icons/io5";
import { TableCompFD } from "../uploadToCloudScreen/uploadToCloudScreen";
import { Checkbox } from "@mui/material";

function MyCameraRecordingScreen({ rows, columns }) {
  // Create an array to hold the camera stream elements
  const [selFormat, setSelFormat] = useState("list");
  const headers = ["File Name", "File Type", "Size", "Upload Date & Time"];

  const userData = [
    {
      file_name: "Video Recording 1",
      file_type: "Model 01",
      file_size: "2Mb",
      timestamp: "23/05/2023, 12:00:10",
      img_url: "icon.png",
    },
    {
      file_name: "Video Recording 2",
      file_type: "Model 02",
      file_size: "2Mb",
      timestamp: "23/05/2023, 12:00:10",
      img_url: "icon1.png",
    },
    {
      file_name: "Video Recording 3",
      file_type: "Model 03",
      file_size: "2Mb",
      timestamp: "23/05/2023, 12:00:10",
      img_url: "icon2.png",
    },
    {
      file_name: "Video Recording 4",
      file_type: "Model 04",
      file_size: "2Mb",
      timestamp: "23/05/2023, 12:00:10",
      img_url: "icon2.png",
    },
    {
      file_name: "Video Recording 5",
      file_type: "Model 05",
      file_size: "2Mb",
      timestamp: "23/05/2023, 12:00:10",
      img_url: "icon.png",
    },
    {
      file_name: "Video Recording 6",
      file_type: "Model 06",
      file_size: "2Mb",
      timestamp: "23/05/2023, 12:00:10",
      img_url: "icon1.png",
    },
    // Add more rows as needed
  ];

  const columnMinWidths = [43, 43, 43, 43, 43, 43];
  return (
    <div className="grid_wrppr_allcam_screen">
      <div className="mycm_reco_screen_btn_wrppr">
        <div className="mycm_reco_screen_container_header_wrppr">
          <h2 className="mycm_reco_screen_container_header">
            Camera Recording
          </h2>
          <p className="connect_storage_screen_container_p">
            Annotate your images here{" "}
          </p>
        </div>
        <div className="reco_screen_list_btn_wrppr">
          <AllCamScrnFormatBtn
            label={"List View"}
            clickHandler={() => setSelFormat("list")}
            active={selFormat === "list"}
          >
            <CiBoxList />
          </AllCamScrnFormatBtn>
          <AllCamScrnFormatBtn
            label={"Grid View"}
            clickHandler={() => setSelFormat("grid")}
            active={selFormat === "grid"}
          >
            <IoGridOutline />
          </AllCamScrnFormatBtn>
        </div>
      </div>
      {selFormat === "grid" ? (
        <div className="grid_container_my_cam_reco_scrn">
          {userData?.map((item, i) => (
            <RecordingCard item={item} />
          ))}
        </div>
      ) : (
        <div className="list_view_wrppr_all_cam_screen">
          <div className="list_view_inner_wrppr_all_cam_screen">
            <TableCompFD
              headers={headers}
              data={userData}
              columnMinWidths={columnMinWidths}
              actionsBtnVisible={false}
              showCheckboxes={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}


function RecordingCard({ item }) {
  return (
    <>
      <div className="reco_card_wrpr">
        <img
          className="videotag_allcam_screen"
          src={item.img_url}
          alt="imm"
          key={`myCameraStream`}
        />

        <div className="reco_card_content_wrpr">
          <Checkbox
            sx={{
              color: "#A3ABBD",
              "&.Mui-checked": { color: "#A3ABBD" },
            }}
          />
          <div className="reco_card_text_wrpr">
            <p className="reco_card_file_name">{item?.file_name}</p>
            <p className="reco_card_timestamp">{item?.timestamp}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default MyCameraRecordingScreen;
