import "./userDetlPgAddEmplyScrn.css";

import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { FileSyncButton } from "../../../utility/button/button";
import { IoCloudUploadOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineCameraAlt } from "react-icons/md";
import { RiEditFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import {
  fetchEmployeesData,
  sendMailToEmployees,
  startTrainingUsrMngmntAddEmply,
} from "../../../action/action";
import { toast } from "react-toastify";
import { UserBulkUploadModalAddEmployeeScrn } from "../../../utility/modals/modals";

function UserDetlPgAddEmplyScrn() {
  const [emplyData, setEmplyData] = useState([]);
  const [selEmplyData, setSelEmplyData] = useState([]);
  const [disableTrainBtn, setDisableTrainBtn] = useState(false);
  const [displayBulkUpldModl, setDisplayBulkUpldModl] = useState(false);

  const headersCamTable = [
      "Employee ID",
      "Employee Name",
      "Employee Email address",
      "Mobile Number",
      "Face Capture",
      "Send Mail",
      "Image Count",
      "Edit",
      "Delete",
    ],
    headersStorageTable = [
      "File Name",
      "Loading",
      "Running Status",
      "Total Run Time",
      "Blank",
      "Blank",
    ],
    myArr = [
      {
        id: 1,
        name: "Storage1",
        email: "blabla@gmail.com",
        mobNum: "987654311",
        capStatus: "2hrs30min",
        field: "blank",
        field2: "blank",
        field1: "blank",
        field4: "blank",
      },
      {
        id: 2,
        name: "Storage1",
        email: "blabla@gmail.com",
        mobNum: "987654311",
        capStatus: "2hrs30min",
        field: "blank",
        field2: "blank",
        field1: "blank",
        field4: "blank",
      },
      {
        id: 3,
        name: "Storage1",
        email: "blabla@gmail.com",
        mobNum: "987654311",
        capStatus: "2hrs30min",
        field: "blank",
        field2: "blank",
        field1: "blank",
        field4: "blank",
      },
    ];

  const columnMinWidths = ["-", 10, 20, 10, 10, "-", "-", "-", "-"];

  async function handleSendMail(email = []) {
    // console.log("getting user send mail===>", email);

    if (!email?.length) {
      toast.error("no email id, update record...");
      return;
    }
    toast.info("sending mail...");
    let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name,
      myCookie = localStorage?.getItem(acc_token_key_name);

    let resp = await sendMailToEmployees(email, myCookie);

    if (resp?.success) {
      // console.log("mail sent to capture image...", resp?.data);
      toast.success("mail sent successfully!");
    } else {
      toast.error("mail failed!");
    }
  }

  async function handleStartTraining() {
    setDisableTrainBtn(true);
    toast.info("requested training...");
    let resp = await startTrainingUsrMngmntAddEmply();
    setDisableTrainBtn(false);
    if (resp?.success) {
      toast.success("training done");
    } else {
      toast.error("training failed");
    }
  }

  function handleBulkUploadModal(v = false) {
    setDisplayBulkUpldModl(v);
  }

  function handleSelAllEmployee(selEmp) {
    // console.log("getting selected employee =========>", selEmp);
    setSelEmplyData([...selEmp]);
  }

  function handleSelSingleEmployee(selEmp) {
    // console.log("getting selected single employee =========>", selEmp);
    let isSelected = false,
      newArr = [];
    selEmplyData?.forEach((elem, indx) => {
      if (elem?.id === selEmp?.id) {
        isSelected = true;
      }
    });
    if (isSelected) {
      newArr = selEmplyData?.filter((x) => x?.id !== selEmp?.id);
      // console.log("filtering selected employee ===>", newArr);
    } else {
      newArr = [...selEmplyData, { ...selEmp }];
    }
    setSelEmplyData([...newArr]);
  }

  function handleSendMailToBulk() {
    // console.log("sending mail to =====>",selEmplyData);
    let newArr = selEmplyData?.map((x) => x?.mail);
    handleSendMail(newArr);
  }

  useEffect(() => {
    async function getEmployeesData() {
      let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name,
        myCookie = localStorage?.getItem(acc_token_key_name);
      let resp = await fetchEmployeesData(myCookie,true);
      if (resp?.success) {
        // console.log(
        //   "my employees data 24244244242 ========>",
        //   resp?.data?.data?.data
        // );
        let data = resp?.data?.data?.data;
        setEmplyData([...data]);
      }
    }
    getEmployeesData();

    /*
biometric_id: ""
company_name: "marktine"
contact_num: "9999999999"
email: "gaurav.surolia@marktine.com"
employee_id: null
id: 1
organization: null
username: "gaurav"
    */
  }, []);

  return (
    <div className="connect_storage_screen_container">
      {displayBulkUpldModl && (
        <>
          <div className="user_details_add_emply_bulk_upload_modal">
            <UserBulkUploadModalAddEmployeeScrn
              closeModal={() => handleBulkUploadModal(false)}
            />
          </div>
        </>
      )}
      <h2>Manage Added Employees Data</h2>
      <p className="connect_storage_screen_container_p">
        Manage Your Employee Details From the Following Table{" "}
      </p>
      <div className="btn_wrppr_manage_user_details_add_emply">
        <button
          className="sidenavigation_newcamerabutton"
          onClick={() => handleBulkUploadModal(true)}
          disabled={false}
          style={{ margin: "1rem 0rem" }}
        >
          <IoCloudUploadOutline />
          <p>Bulk Upload</p>
        </button>
        <button
          className="sidenavigation_newcamerabutton"
          onClick={() => handleStartTraining()}
          disabled={disableTrainBtn}
          style={{ margin: "1rem 0rem" }}
        >
          {/* <IoCloudUploadOutline /> */}
          <p>Start Training</p>
        </button>
        <button
          className="sidenavigation_newcamerabutton"
          onClick={() => handleSendMailToBulk()}
          disabled={false}
          style={{ margin: "1rem 0rem" }}
        >
          {/* <IoCloudUploadOutline /> */}
          <p>Send Bulk Mail</p>
        </button>
      </div>

      <MyStorageTable
        headers={headersCamTable}
        data={emplyData}
        columnMinWidths={columnMinWidths}
        handleSendMail={handleSendMail}
        selectedRow={selEmplyData}
        handleSelAllEmp={handleSelAllEmployee}
        handleSelSinglEmp={handleSelSingleEmployee}
      />

      <div className="selStorageModalActvArea_btnWrppr margin_top_append">
        <FileSyncButton
          title="Save"
          stylObj={{
            boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            color: "white",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        />
        <FileSyncButton
          title="Cancel"
          stylObj={{
            background: "white",
            color: "#05306B",
            // boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        />
      </div>
    </div>
  );
}

export default UserDetlPgAddEmplyScrn;

function MyStorageTable({
  headers = [],
  data = [],
  columnMinWidths = [],
  handleSelRow = () => {},
  currentSelRow,
  handleActStrg = () => {},
  handleSendMail = () => {},
  selectedRow = [],
  handleSelAllEmp = () => {},
  handleSelSinglEmp = () => {},
}) {
  function handleSelectedAll(e) {
    // console.log("select all get data =====>", data);
    let isChecked = e?.target?.checked,
      newArr = [];
    // console.log("getting checked value ===>", isChecked);
    if (isChecked) {
      newArr = data?.map((e) => {
        return { mail: e?.email, id: e?.id };
      });
    }

    // console.log("new array =======>", newArr);
    handleSelAllEmp(newArr);
  }

  function handleSelectSingleEmp(x) {
    // console.log("selected single employee ===>", x);
    let obj = { mail: x?.email, id: x?.id };
    handleSelSinglEmp(obj);
  }

  function checkSelected(x) {
    let isSel = false;
    selectedRow?.forEach((elem) => {
      if (x?.id === elem?.id) {
        isSel = true;
      }
    });
    return isSel;
  }

  return (
    <>
      <table>
        <thead className="mtHeadFDFI">
          <tr>
            <th style={{ width: `3%` }}>
              <Checkbox
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
                onChange={handleSelectedAll}
                checked={selectedRow?.length === data?.length}
              />
            </th>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI file_status_for_job_page_table">
          {data?.map((row, rowIndex) => (
            <tr
              key={`row-${rowIndex}`}
              className={`datasetTableRowFDFI ${
                currentSelRow?.storage_id === row?.id ? "actvRowCameraList" : ""
              }`}
              onClick={() => {
                // console.log("clicking camera row", currentSelRow);
                handleSelRow(row);

                /*
biometric_id: ""
company_name: "marktine"
contact_num: "9999999999"
email: "gaurav.surolia@marktine.com"
employee_id: null
id: 1
organization: null
username: "gaurav"
    */
              }}
            >
              <td width={"3%"}>
                <Checkbox
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                  onChange={() => handleSelectSingleEmp(row)}
                  checked={checkSelected(row)}
                />
              </td>
              <td
              // style={{ width: `${columnMinWidths[rowIndex]}%` }}
              >
                {row?.employee_id || "--"}
              </td>
              <td style={{ width: `10%` }}>{row?.username}</td>
              <td style={{ width: `20%` }}>{row?.email}</td>
              <td style={{ width: `10%` }}>{row?.contact_num || "- -"}</td>
              <td style={{ width: `10%`, textAlign: "-webkit-center" }}>
                {row?.face_images_dir_path ? (
                  <button
                    className="status_cont simpleBtnWrpprNoBorder capturedStatusBtn"
                    style={{ background: "#0EC096" }}
                  >
                    <IoMdCheckmark />
                    Captured
                  </button>
                ) : (
                  <button
                    className="status_cont simpleBtnWrpprNoBorder capturedStatusBtn"
                    style={{ background: "#05306B" }}
                  >
                    <MdOutlineCameraAlt />
                    Capture Image
                  </button>
                )}
              </td>
              <td
                // style={{ width: `${columnMinWidths[rowIndex]}%` }}
                style={{ textAlign: "-webkit-center" }}
              >
                {/* {"- -"} */}
                <button
                  className="status_cont simpleBtnWrpprNoBorder capturedStatusBtn"
                  style={{ background: "#FF2B6A" }}
                  onClick={() => handleSendMail([row?.email])}
                >
                  {/* <MdOutlineCameraAlt /> */}
                  Send Mail
                </button>
              </td>
              <td
              // style={{ width: `${columnMinWidths[rowIndex]}%` }}
              >
                {row?.image_count || "- -"}
              </td>
              <td style={{ textAlign: "-webkit-center" }}>
                {/* {row?.field2 || "- -"} */}
                <button
                  className="status_cont simpleBtnWrpprNoBorder editBtnUsrDetailsAddEmply"
                  style={{ background: "#2BA084" }}
                >
                  <RiEditFill />
                </button>
              </td>
              <td style={{ textAlign: "-webkit-center" }}>
                {/* {row?.field2 || "- -"} */}
                <button
                  className="status_cont simpleBtnWrpprNoBorder editBtnUsrDetailsAddEmply"
                  style={{ background: "#FF2B6A" }}
                >
                  <MdDelete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
