import './formInpWrpprFaceDetection.css';

import {useState,useEffect} from 'react';

function FormInpWrpprFaceDetection({children,styleObj={}}) {
  return (
    <div className='inp_page_ai_model_form_inp_wrppr' style={{...styleObj}}>{children}</div>
  )
}

export default FormInpWrpprFaceDetection