import React, { useState, useRef, useEffect } from "react";
import "./logScreen.css";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const AccordionItem = ({ item, isOpen, onClick }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={onClick}>
        <div className="log_screen_accordian_head_wrapper_left">
          <div className="log_screen_accordian_head_icon_wrapper">
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <h3 className="log_screen_accordian_title">{item.title}</h3>
          <p className="log_screen_accordian_date_para">(22 May 2024)</p>
        </div>
        <div className="log_screen_accordian_head_wrapper_right">
          <p className="log_screen_accordian_head_wrapper_left_time_para">
            Start
            <span className="log_screen_accordian_head_wrapper_left_time_span">
              10:00AM
            </span>
          </p>
          <p className="log_screen_accordian_head_wrapper_left_time_para">
            End
            <span className="log_screen_accordian_head_wrapper_left_time_span">
              10:15AM
            </span>
          </p>
          <p className="log_screen_accordian_head_wrapper_left_time_para">
            Total Time Difference
            <span className="log_screen_accordian_head_wrapper_left_time_span">
              00:00:05
            </span>
          </p>
          {/* <span>{isOpen ? "-" : "+"}</span> */}
        </div>
      </div>
      <div
        ref={contentRef}
        style={{ height }}
        className={`accordion-content ${isOpen ? "open" : ""}`}
      >
        {Array(5)
          .fill("")
          .map(() => (
            // <div className="accordion-content-inner">{item.content}</div>
            <div className="accordian_list_wrapper_log_screen">
              <div className="log_screen_accordian_head_wrapper_left accordian_list_log_screen_left_title_container">
                <p className="log_screen_accordian_date_para accordian_list_log_screen_title_para">
                  DS Process 001
                </p>
              </div>
              <div className="log_screen_accordian_head_wrapper_right ">
                <p className="log_screen_accordian_head_wrapper_left_time_para accordian_list_log_screen_left_time_para">
                  Start
                  <span className="log_screen_accordian_head_wrapper_left_time_span">
                    10:00AM
                  </span>
                </p>
                <p className="log_screen_accordian_head_wrapper_left_time_para">
                  End
                  <span className="log_screen_accordian_head_wrapper_left_time_span">
                    10:15AM
                  </span>
                </p>
                <p className="log_screen_accordian_head_wrapper_left_time_para">
                  Total Time Difference
                  <span className="log_screen_accordian_head_wrapper_left_time_span">
                    00:00:05
                  </span>
                </p>
                {/* <span>{isOpen ? "-" : "+"}</span> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const items = [
  {
    title: "Frame 001",
    content: "Content of Section 1.",
  },
  {
    title: "Frame 002",
    content: "Content of Section 2.",
  },
  {
    title: "Frame 003",
    content: "Content of Section 3.",
  },
];
const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          item={item}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
