// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect_camera_screen_add_cam_form_cont{
    flex-direction: column;
}

.connect_camera_screen_add_cam_btn_wrppr{
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 24px;
}

.connect_camera_screen_add_cam_form_inp_wrppr{
    width:100% !important;
}
.connect_cam_screen_gaping{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/connectCameraScreen/connectCameraSccren.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".connect_camera_screen_add_cam_form_cont{\n    flex-direction: column;\n}\n\n.connect_camera_screen_add_cam_btn_wrppr{\n    width: fit-content;\n    margin-bottom: 24px;\n}\n\n.connect_camera_screen_add_cam_form_inp_wrppr{\n    width:100% !important;\n}\n.connect_cam_screen_gaping{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
