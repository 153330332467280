import React, { useEffect, useState, useContext, useRef } from "react";
import "./allCameraScreen.css";
import { toast } from "react-toastify";

import { AllCamScrnFormatBtn } from "../../../utility/button/button";
import { CiBoxList } from "react-icons/ci";
import { IoGridOutline } from "react-icons/io5";
import { FaCircleDot } from "react-icons/fa6";

import { getAllCameraList, getAllCameraInfo } from "../../../action/action";

import { NavBarOptions } from "../../../utility/variables/variables";

import { MyContext } from "../../../context/context";

import { waitingFunction } from "../../../utility/functions/helperFunc";

import LoadingComponent from "../../../utility/loadingSpinner/loadingSpinner";

import { Grid2X2, TableProperties, VideoOff } from "lucide-react";
import {
  faceDetectionCoordToBoundingBox,
  objectCountCoordToBoundingBox,
} from "../../../utility/functions/boundingBoxFunction";

import {
  FaceDetectionBoundBxWrppr,
  ObjectCountBoundBxWrppr,
} from "../../../utility/boundingboxWrppr/faceDetBoundBxWrppr";

// function AllCameraScreen({ rows, columns }) {
//   // Create an array to hold the camera stream elements

//   const [cameraList,setCameraList] = useState([]);
//   const cameraStreams = ["", "", "", "", "", "", "", "", ""];

//   const [selFormat, setSelFormat] = useState("grid");

//   const {mySelCamStream,sideNav} = useContext(MyContext);

// async function fetchAllCamURL(){
//   let resp = await getAllCameraList();

//   // console.log("response from get all camera list",resp?.data?.data?.paylod);

//   if(resp?.success){
//     console.log("response from get all camera list",resp?.data?.data?.paylod);
//     let dArr = resp?.data?.data?.paylod || [];
//     setCameraList([...dArr]);
//     if(!mySelCamStream?.selCameraURl){
//       mySelCamStream?.handleSelCameraToStream(dArr[0]?.url);
//     }
//   }
// }

// async function handleSetCamStream(x){
//   mySelCamStream?.handleSelCameraToStream(x);
//   await waitingFunction(500);
//   let dataObj = {main:"",sub:"",current:""};
//   dataObj.main = NavBarOptions.CCTV.slang;
//   dataObj.sub = NavBarOptions.CCTV.subOptn["My Camera"];
//   dataObj.current = NavBarOptions.CCTV.subOptn["My Camera"];
//   // console.log("access my camera dynaimcally ===>",dataObj);
//   sideNav?.handleSelScreen(dataObj);
// }

//   useEffect(()=>{fetchAllCamURL()},[]);

//   return (
//     <div className="grid_wrppr_allcam_screen">
//       <div className="allcm_screen_format_btn_wrppr">
//         <AllCamScrnFormatBtn
//           label={"List"}
//           clickHandler={() => setSelFormat("list")}
//         >
//           <CiBoxList />
//         </AllCamScrnFormatBtn>
//         <AllCamScrnFormatBtn
//           label={"Grid"}
//           clickHandler={() => setSelFormat("grid")}
//         >
//           <IoGridOutline />
//         </AllCamScrnFormatBtn>
//       </div>
//       {selFormat === "grid" ? (
//         <div

//           className="grid_container_all_cam_scrn"
//         >
//           {cameraList?.map((e, i) => (
//             <img
//               className="videotag_allcam_screen"
//               src={e?.url}
//               alt="imm"
//               key={`myCameraStream${i + 11}`}
//               onClick={()=>handleSetCamStream(e?.url)}
//             />
//           ))}
//         </div>
//       ) : (
//         <div className="list_view_wrppr_all_cam_screen">
//           <div className="list_view_inner_wrppr_all_cam_screen">
//             {cameraList?.map((e, i) => (
//               <CamCardListView key={`myCameraStream${i + 11}`} url={e?.url} handeleSelCamStream={(x)=>handleSetCamStream(x)} data={e} />
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default AllCameraScreen;

// function CamCardListView({url='',handeleSelCamStream=()=>{},data={camera_name:"",camera_desc:""}}) {
//   console.log("my url ====>",url);
//   return (
//     <div className="list_ciew_cam_card_all_cam_scrn">
//       <img
//         className="videotag_allcam_screen_list_view_card"
//         src={url}
//         alt="imm"
//         onClick={()=>handeleSelCamStream(url)}
//       />
//       <div className="cam_detl_wrppr_list_view_crd">
//         <h1>{data?.camera_name}</h1>
//         <p>
//           {data?.camera_desc}
//         </p>
//       </div>
//     </div>
//   );
// }

// ------------------------------------------------------------   WEBSOCKET MULTI CAMERA STREAM --------------------------------------------------------------

function AllCameraScreen({ rows, columns }) {
  const [camList, setCamList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const [allCameraConnectStatus, setAllCameraConnectStatus] = useState("load");

  const [imgUrlArr, setImgUrlArr] = useState([]);
  const [activeLayout, setActiveLayout] = useState("grid");
  const socketRef = useRef(null);
  const socketConnectCallCount = useRef(0);

  const { mySelCamStream, sideNav } = useContext(MyContext);

  const activeLayoutButton = "bg-blue-900 text-white",
    inActiveLayoutButton = "bg-white text-blue-900";

  function handleClickCameraCard(e) {
    // console.log("inside handleClickCameraCard function", e);
    mySelCamStream?.handleSelCameraToStream(e);

    waitingFunction(500);

    let dataObj = { main: "", sub: "", current: "" };
    dataObj.main = NavBarOptions["CCTV"].slang;
    dataObj.sub = NavBarOptions["CCTV"].subOptn["My Camera"];
    dataObj.current = NavBarOptions["CCTV"].subOptn["My Camera"];
    sideNav?.handleSelScreen(dataObj);
  }

  useEffect(() => {
    let retrieveAllCamCount = 0,
      initialStartSocket = true;
    async function fetchCamInfo() {
      // console.log("calling fetchCam Info", retrieveAllCamCount);
      let response = await getAllCameraInfo();
      if (response?.success) {
        console.log(
          "response from get all camera info",
          response?.data?.AllCamera
        );

        let cameraArrary = response?.data?.AllCamera?.map((e) => {
          return {
            camera_description: e?.camera_description,
            camera_id: e?.camera_id,
            camera_name: e?.camera_name,
            camera_status: e?.camera_status,
            id: e?.id,
            ip_address: e?.ip_address,
            name: e?.name,
            frame: "",
          };
        });
        // console.log("cameraArrary cam details array ===>", cameraArrary);
        setCamList(cameraArrary);
        waitingFunction(500);
        setPageLoading(false);
        socketConnectCallCount.current += 1;
        console.log(
          "socket connect call count ===>",
          socketConnectCallCount.current
        );
        startWebSocket();
        return;
      } else {
        retrieveAllCamCount <= 2 && fetchCamInfo();
        retrieveAllCamCount++;
        if (retrieveAllCamCount == 2) {
          setPageLoading(false);
          console.log("something wrong, can't fetch cam info");
        }
      }
    }
    !camList.length && fetchCamInfo();

    // if (camList?.length && initialStartSocket) {
    //   console.log("initial start socket calling ====>");
    //   // startWebSocket();
    //   initialStartSocket = false;
    // }

    return () => {
      // console.log("inside unmounting all camera...");
      // sendMessage("stop_mul_ip_cam");
      sendMessage({
        event: "stop_mul_ip_cam",
        // cam_id: "1",
        // name: "gaurav",
      });
      waitingFunction(1000);
      stopWebSocket();
    };
  }, []);

  // ------------------------------   WEBSOCKET MULTI CAMERA STREAM ------------------------------

  const sendMessage = (msg = "") => {
    // console.log("inside snd message function...");
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      // console.log("websocket connection is redy to send messasge", msg);
      socketRef.current.send(msg);
    }
  };

  const startWebSocket = (
    payload = {
      event: "start_all_cam",
      cam_id: "1",
      name: "gaurav",
    }
  ) => {
    // console.log("inside start websocket function...");
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_Websocket}/multi_ipcam/`
    );

    socketRef.current.onopen = () => {
      // console.log("websocket connection open...");
      // toast.success("Connected !");
      setAllCameraConnectStatus("done");
      sendMessage(JSON.stringify(payload));
    };

    socketRef.current.onmessage = (msg) => {
      const socketRespData = JSON.parse(msg.data);
      // console.log("message received from server side...", socketRespData);
      /*
        'event':"multi_live_video",
                        'data': decoded_frames_data,
                        'content_type':'multipart/x-mixed-replace; boundary=frame',
      */
      if (socketRespData?.event === "multiipcam") {
        console.log("receiving all cam feed ===>", socketRespData?.data);
        socketRespData?.data?.forEach((e) => {
          let camID = e?.cam_id,
            frame = e?.frame,
            status = e?.status,
            coordinatesType = "",
            coordinates = e?.ds_coordinates,
            objCount = e?.obj_count;
          console.log("ds_coordinates all camera ===>", coordinates);
          console.log("object count coordinates all camera ===>", coordinates);

          setCamList((prevState) => {
            // console.log("previous camlist is", prevState);
            let newCamList = prevState?.map((item) => {
              if (item?.camera_id === camID) {
                item.frame = frame;
                item.camera_status = status;
                let imgUrl = `data:image/jpeg;base64,${frame}`;

                const img = new Image();
                img.src = imgUrl;

                let originalImageWidth = img.width,
                  originalImageHeight = img.height;

                item.ds_coordinates = faceDetectionCoordToBoundingBox(
                  coordinates,
                  originalImageWidth,
                  originalImageHeight
                );

                item.objCount = objectCountCoordToBoundingBox(
                  objCount,
                  originalImageWidth,
                  originalImageHeight
                );
              }
              return item;
            });
            // console.log("modified camlist is", newCamList);
            return newCamList;
          });
        });
        // setImgUrlArr([...socketRespData?.data]);
      }

      // Sending a new message to the server when a message is received
    };

    socketRef.current.onclose = () => {
      console.log("socket closed...");
      setCamList((prevState) => {
        let newCamList = prevState?.map((item) => {
          item.frame = "";
          item.camera_status = "fail";
          return item;
        });
        return newCamList;
      });
      // toast.warn("Disconnected !");
      setAllCameraConnectStatus("fail");
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
      toast.error("Error Occurred !");
      setAllCameraConnectStatus("fail");
    };
  };

  const stopWebSocket = (unMount = false) => {
    if (socketRef.current) {
      socketRef.current.close();
      // toast.warn("socket connection disconnected!");
      setAllCameraConnectStatus("fail");
    }
  };

  // ------------------------------   WEBSOCKET MULTI CAMERA STREAM ------------------------------

  return (
    <>
      {pageLoading ? (
        <LoadingComponent
          styl={{
            position: "relative",
            top: "0px",
            bottom: "0px",
            right: "0px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : camList?.length > 0 ? (
        <div className="w-full h-full">
          {/* <div className="w-full flex gap-3 items-end px-4 pt-3 justify-end">
            <TWC_Button
              label={"Grid View"}
              clickHandler={() => setActiveLayout("grid")}
              icon={<Grid2X2 />}
              className={`flex px-4 py-3  rounded-md gap-4 ${
                activeLayout == "grid"
                  ? activeLayoutButton
                  : inActiveLayoutButton
              }`}
            />
            <TWC_Button
              label={"List View"}
              clickHandler={() => setActiveLayout("list")}
              icon={<TableProperties />}
              className={`flex px-4 py-3  rounded-md gap-4 ${
                activeLayout == "list"
                  ? activeLayoutButton
                  : inActiveLayoutButton
              }`}
            />
          </div> */}

          {activeLayout == "grid" ? (
            <div className="grid grid-cols-3 gap-4 p-4">
              {camList?.map((e, i) => {
                return (
                  <CameraCard
                    key={`myCameraStream${e?.id}`}
                    camDetails={{ ...e }}
                    clickHandler={() => handleClickCameraCard(e)}
                    camStatus={e?.camera_status || allCameraConnectStatus}
                  />
                );
              })}
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 p-4">
              {camList?.map((e, i) => {
                return (
                  <CameraCardListView
                    key={`myCameraStream${e?.id}`}
                    camDetails={{ ...e }}
                    clickHandler={() => handleClickCameraCard(e)}
                  />
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <p className="font-manrope text-3xl">no camera found !</p>
      )}
    </>
  );
}

export default AllCameraScreen;

function CameraCard({
  camDetails,
  clickHandler = () => {},
  showName = true,
  camStatus = "",
}) {
  const imageELem = useRef(null);
  // console.log("camcard camDetails", camDetails);
  const [imgStatus, setImgStatus] = useState(false);
  useEffect(() => {
    if (camStatus === "fail") {
      setImgStatus(false);
    }
  }, [camStatus]);
  return (
    <div
      className="h-60 bg-slate-900 text-white rounded-lg cursor-pointer relative overflow-hidden"
      onClick={clickHandler}
    >
      {!imgStatus ? (
        <>
          {camStatus === "load" && (
            <button className="flex gap-3 items-center bg-amber-400 rounded-full text-white text-sm px-3 py-1 font-manrope absolute top-6 left-3 font-semibold">
              <FaCircleDot className="w-4 h-4" />
              Connecting...
            </button>
          )}
          <VideoOff className="absolute top-6 right-6 w-8 h-8" />
          {showName && (
            <div className="absolute bottom-6 left-0 max-w-full box-border px-2">
              <p className="truncate font-manrope font-semibold text-xl">
                {camDetails?.camera_name}
              </p>
            </div>
          )}
        </>
      ) : (
        <button className="flex gap-3 items-center bg-red-600 rounded-full text-white text-sm px-3 py-1 font-manrope absolute top-6 left-3 font-semibold">
          <FaCircleDot className="w-4 h-4" />
          LIVE
        </button>
      )}
      {camDetails?.frame && (
        <>
          <img
            alt=""
            src={`data:image/jpeg;base64,${camDetails?.frame}`}
            onError={(e) => setImgStatus(false)}
            onLoad={(e) => {
              setImgStatus(true);
            }}
            className={imgStatus ? "w-full h-full rounded-sm" : ""}
          />
          {camDetails?.ds_coordinates && (
            <FaceDetectionBoundBxWrppr
              divListArr={camDetails?.ds_coordinates}
            />
          )}
          {camDetails?.objCount && (
            <ObjectCountBoundBxWrppr divListArr={camDetails?.objCount} />
          )}
        </>
      )}
    </div>
  );
}

function CameraCardListView({ camDetails, clickHandler = () => {} }) {
  return (
    <div
      className="bg-white w-full p-3 shadow-sm rounded-sm cursor-pointer flex gap-5"
      onClick={clickHandler}
    >
      <div className="w-2/5 max-w-md">
        <CameraCard camDetails={{ ...camDetails }} showName={false} />
      </div>
      <div className="">
        <h3 className="font-manrope">{camDetails?.camera_name}</h3>
        <p className="font-manrope">{camDetails?.camera_description}</p>
      </div>
    </div>
  );
}

function TWC_Button({ label, clickHandler, icon, className = "" }) {
  return (
    <button className={className} onClick={clickHandler}>
      {icon}
      {label}
    </button>
  );
}
