// usrmngmntusrview

import "./UserManagementCrtUsrScreen.css";
import FormInpWrpprFaceDetection from "../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";
import { FormInp } from "../../../utility/formElements/formElements";
import { Checkbox } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { MyContext } from "../../../context/context";
import { IoMdClose } from "react-icons/io";

const InitialFormDetails = {
  first_name: "",
  last_name: "",
  email: "",
  mobile_num: "",
};

function UserManagementCrtUsrScreen() {
  const [formDataUsrDetl, setFormDataUsrDetl] = useState({
    ...InitialFormDetails,
  });
  const [formDataErrorObj, setFormDataErrorObj] = useState({
    ...InitialFormDetails,
  });

  const { userManagement } = useContext(MyContext);

  const headers = ["Feature", "Sub Feature", "Comment"];

  const columnMinWidths = [43, 43, 43];

  const dataArrForTable = [
    {
      id: 1,
      feature: "field 1",
      subFeature: [
        { label: "sub field 1", comment: "comment 1", id: 11 },
        { label: "sub field 1", comment: "comment 1", id: 11 },
        { label: "sub field 1", comment: "comment 1", id: 11 },
      ],
    },
    {
      id: 2,
      feature: "field 1",
      subFeature: [
        { label: "sub field 1", comment: "comment 1", id: 11 },
        { label: "sub field 1", comment: "comment 1", id: 11 },
        { label: "sub field 1", comment: "comment 1", id: 11 },
      ],
    },
    {
      id: 3,
      feature: "field 1",
      subFeature: [
        { label: "sub field 1", comment: "comment 1", id: 11 },
        { label: "sub field 1", comment: "comment 1", id: 11 },
        { label: "sub field 1", comment: "comment 1", id: 11 },
      ],
    },
  ];

  function checkEmptyFieldsForAddCamera(data) {
    let myErrObj = {},
      isOkay = true;
    for (let key of data) {
      if (!data[key]) {
        myErrObj[key] = 1;
        isOkay = false;
      }
    }

    return { myErrObj, isOkay };
  }

  function handleSetInpforform(e) {
    let name = e?.target?.name,
      value = e?.target?.value;

    setFormDataUsrDetl({ ...formDataUsrDetl, [name]: value });
  }

  return (
    <div className="connect_storage_screen_container">
      <h2>User Management</h2>
      <p className="connect_storage_screen_container_p">
        Enter Your Details To Make Your Own Custom Model{" "}
      </p>
      <div className="inp_page_ai_model_flex_form_inp_cont">
        <FormInpWrpprFaceDetection>
          <FormInp
            title={"First Name"}
            placeHldr={"Enter First Name"}
            nameStr={"first_name"}
            value={formDataUsrDetl?.first_name}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDataErrorObj?.first_name && "Field can't be left empty"}
          />
        </FormInpWrpprFaceDetection>

        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Last Name"}
            placeHldr={"Enter Last Name"}
            nameStr={"last_name"}
            value={formDataUsrDetl?.last_name}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDataErrorObj?.last_name && "Field can't be left empty"}
          />
        </FormInpWrpprFaceDetection>
      </div>

      <div className="inp_page_ai_model_flex_form_inp_cont">
        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Email Address"}
            placeHldr={"Enter Your Email Address"}
            nameStr={"email"}
            value={formDataUsrDetl?.email}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDataErrorObj?.email && "Field can't be left empty"}
          />
        </FormInpWrpprFaceDetection>

        <FormInpWrpprFaceDetection>
          <FormInp
            title={"Mobile Number"}
            placeHldr={"Enter Your Mobile Number"}
            nameStr={"mobile_num"}
            value={formDataUsrDetl?.mobile_num}
            handlChange={(e) => handleSetInpforform(e)}
            error={formDataErrorObj?.mobile_num && "Field can't be left empty"}
          />
        </FormInpWrpprFaceDetection>
      </div>
      <UserAccessManageTable
        headers={headers}
        columnMinWidths={columnMinWidths}
        data={userManagement?.accessTableFeatures}
      />
    </div>
  );
}

export default UserManagementCrtUsrScreen;

function UserAccessManageTable({
  headers = [],
  columnMinWidths = [],
  data = [
    {
      id: 0,
      feature: "",
      subFeature: [{ label: "", comment: "", id: 11 }]
    }
  ],
  handleSingleSelect = () => {},
}) {
  const [subFeatureModal, setSubFeatureModal] = useState({
    display: false,
    data: []
  });

  const [selFeatureList, setSelFeatureList] = useState([]);

  function checkFeatureIDCheckbox(id = "") {
    let isInclude = false;
    selFeatureList?.forEach((x) => {
      if (id === x?.feature?.id) isInclude = true;
    });
    return isInclude;
  }

  function checkSubFeatureCheckbox(elem = "") {
    let isIncluded = false,
      featID = elem?.feature,
      subFeatID = elem?.id;
    // console.log("checking sub feature checkbox =====>", featID,subFeatID);
    selFeatureList?.forEach((x, indx) => {
      if (x?.feature?.id === featID) {
        // console.log("feature id matched!");
        x?.feature?.subfeature?.forEach((y) => {
          // { feature: { id: r?.id, subfeature: [{ id: e?.id, subList: [] }] } }
          // console.log("feature id ======>",y?.id,subFeatID);
          if (y?.id === subFeatID) isIncluded = true;
        });
      }
    });

    return isIncluded;
  }

  function handleModalPopups(subFeature) {
    if (!subFeature?.open_model) return;
    // console.log("modal popups elements ======>", subFeature);
    setSubFeatureModal({ data: { ...subFeature }, display: true });
  }

  function handleClosePopup(v = false) {
    setSubFeatureModal({ ...subFeatureModal, display: v });
  }

  function handleSelAllSubFeature(r) {
    // console.log("select all sub features =========>", r);

    let isInc = false;

    selFeatureList?.forEach((x) => {
      if (x?.feature?.id === r?.id) {
        isInc = true;
      }
    });

    if (isInc) {
      let newArr = selFeatureList?.filter((x) => {
        return x?.feature?.id !== r?.id;
      });

      setSelFeatureList([...newArr]);
    } else {
      let subFealist = r?.subFeature?.map((e) => {
        // e?.open_model &&
        //   console.log("element in sub feature =====>", e, e?.model_data);

        let obj = { id: e?.id, subList: [], openModel: e?.open_model }; // need to append sub-list id's
        if (e?.open_model) {
          let modArr = e?.model_data?.map((x) => x?.id);
          obj.subList = [...modArr];
        }
        return obj;
      });

      // console.log("subFeaList =========>", subFealist);

      setSelFeatureList([
        ...selFeatureList,
        { feature: { id: r?.id, subfeature: [...subFealist] } },
      ]);
    }
  }

  function handleSelSingleSubFeature(sf) {
    // console.log("select single sub feature ====>", sf);
    if (sf?.open_model) {
      // console.log("return opening model =====>");
      return;
    }
    let featID = sf?.feature,
      isIncFeature = false,
      featureIndx = null,
      subFeatureIndx = null,
      isIncSubFeature = false;

    selFeatureList?.forEach((x, ix) => {
      if (x?.feature?.id === featID) {
        isIncFeature = true;
        featureIndx = ix;
        x?.feature?.subfeature?.forEach((y, iy) => {
          if (y?.id === sf?.id) {
            isIncSubFeature = true;
            subFeatureIndx = iy;
          }
        });
      }
    }); // { feature: { id: r?.id, subfeature: [{ id: e?.id, subList: [] }] } }

    if (isIncFeature) {
      // console.log("feature included ======>");
      if (isIncSubFeature) {
        // console.log("sub feature included ======>");
        // =========> remove sub feature block
        let newArr = [...selFeatureList];
        if (selFeatureList[featureIndx]?.feature?.subfeature?.length > 1) {
          // console.log(
          //   "greater than 1 =======>",
          //   selFeatureList[featureIndx]?.feature?.subfeature?.length
          // );
          newArr[featureIndx]?.feature?.subfeature?.splice(subFeatureIndx, 1);
          // console.log(
          //   "sub feature array length after splice ====>",
          //   selFeatureList[featureIndx]?.feature?.subfeature?.length,
          //   selFeatureList[featureIndx]
          // );
        } else {
          // console.log("not greater than 1 =====>");
          newArr?.splice(featureIndx, 1);
        }
        setSelFeatureList([...newArr]);
      } else {
        // console.log("sub feature not included ======>");
        // =========> add sub feature block

        let newArr = [...selFeatureList];

        newArr[featureIndx]?.feature?.subfeature?.push({
          id: sf?.id,
          subList: [],
        });

        setSelFeatureList([...newArr]);
      }
    } else {
      let newArr = [
        ...selFeatureList,
        {
          feature: { id: featID, subfeature: [{ id: sf?.id, subList: [] }] },
        },
      ];
      // console.log("feature not included ======>", newArr);

      setSelFeatureList([...newArr]);
    }
  }

  function handleSelSubFeatureChilds(sfChilds, sf) {
    // console.log("getting sub feature childs =====>", sfChilds);
    // console.log("getting sub feature =====>", sf);

    let selFeaturesArr = [...selFeatureList],
      featIndx = null,
      subFeatIndx = null;
    // { feature: { id: r?.id, subfeature: [{ id: e?.id, subList: [] }] } }

    selFeaturesArr?.forEach((featElem, indx) => {
      let { feature } = featElem;
      // console.log("feature id iteration",feature?.id,sf?.feature);
      if (feature?.id === sf?.feature) {
        // console.log("inside feature iteration true ==>");
        featIndx = indx;
      }
    });

    if (featIndx !== null) {
      // console.log("feature present ===>");
      let { feature } = selFeaturesArr[featIndx];

      feature?.subfeature?.forEach((subFeatElem, indx) => {
        if (subFeatElem?.id === sf?.id) subFeatIndx = indx;
      });

      if (subFeatIndx !== null) {
        // console.log("sub feature present ===>");
        let isSFChild = feature.subfeature[subFeatIndx]?.subList?.includes(
          sfChilds?.id
        );
        if (isSFChild) {
          // console.log("sf child present ===>");

          if (feature.subfeature[subFeatIndx]?.subList?.length > 1) {
            selFeaturesArr[featIndx].feature.subfeature[subFeatIndx].subList =
              feature.subfeature[subFeatIndx]?.subList?.filter(
                (id) => id !== sfChilds?.id
              );
          } else {
            // removing sub feature ==>
            // console.log("removing sub feature ===>");
            selFeaturesArr[featIndx].feature.subfeature = selFeaturesArr[
              featIndx
            ].feature.subfeature?.filter((sfElem) => sfElem?.id !== sf?.id);

            let len = selFeaturesArr[featIndx].feature.subfeature?.length;
            if (!len) {
              // removing feature ==>
              // console.log(" removing feature ==>");
              selFeaturesArr = selFeaturesArr?.filter((featElem) => {
                let { feature } = featElem;
                return feature?.id !== sf?.feature;
              });
            }
          }
        } else {
          // console.log("sf child not present ===>");
          selFeaturesArr[featIndx].feature.subfeature[subFeatIndx].subList = [
            ...feature.subfeature[subFeatIndx]?.subList,
            sfChilds?.id,
          ];
        }
      } else {
        // console.log("sub feature not present ===>");
        selFeaturesArr[featIndx].feature.subfeature = [
          ...feature?.subfeature,
          { id: sf?.id, subList: [sfChilds?.id] },
        ];
      }
    } else {
      // console.log("feature not present ===>");
      selFeaturesArr = [
        ...selFeaturesArr,
        {
          feature: {
            id: sf?.feature,
            subfeature: [{ id: sf?.id, subList: [sfChilds?.id] }],
          },
        },
      ];
    }

    // console.log(
    //   "reviewing selected features before setting  ====>",
    //   selFeaturesArr
    // );
    setSelFeatureList([...selFeaturesArr]);
  }

  return (
    <>
      {subFeatureModal?.display && (
        <div
          className="modal_popup_wrppr_sel_opt_with_modl_popup"
          style={{ margin: "0", padding: "0px" }}
          onClick={(e) => {
            // console.log("handle click popup modal ------>");
            e?.stopPropagation();
            // handleClosePopup("");
          }}
        >
          <UserManagementPopup
            handleClose={handleClosePopup}
            selFromData={subFeatureModal?.data}
            handleSubFeatureChilds={handleSelSubFeatureChilds}
            selectedFeaturesArr={selFeatureList}
          />
        </div>
      )}
      <table className="remove_border_spacing">
        <thead className="mtHeadFDFI">
          <tr>
            <th style={{ width: `50px` }}>
              <Checkbox
                //   checked={admin?.crtdUsers?.length === selectedUserList?.length}
                //   onChange={handleSelectAllUsers}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </th>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI collapse_border append_max_height append_background">
          {data?.map((row, rowIndex) => (
            <tr
              key={`row-${rowIndex}`}
              className="datasetTableRowFDFI append_border_td remove_padding remove_border"
            >
              <td
                width={"50px"}
                className={`${
                  rowIndex === data?.length - 1 ? "" : "remove_bottom_border"
                }`}
              >
                <Checkbox
                  checked={checkFeatureIDCheckbox(row?.id)}
                  onChange={() => {
                    handleSelAllSubFeature(row);
                  }}
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>

              <td
                style={{ width: `${columnMinWidths[0]}%` }}
                className={`${
                  rowIndex === data?.length - 1 ? "" : "remove_bottom_border"
                }`}
              >
                {row?.feature}
              </td>
              <td
                style={{ width: `${columnMinWidths[1]}%` }}
                className={`${
                  rowIndex === data?.length - 1 ? "" : "remove_bottom_border"
                }`}
              >
                {row?.subFeature?.map((e, ind) => (
                  <>
                    <div
                      className={`${
                        row?.subFeature?.length % 2 !== 0
                          ? ind % 2 !== 0
                            ? "append_top_bottom_border"
                            : ""
                          : ind % 2 === 0
                          ? "append_bottom_border"
                          : ""
                      } acquire_height center_items add_pointer`}
                      onClick={() => handleModalPopups(e)}
                    >
                      <Checkbox
                        checked={checkSubFeatureCheckbox(e, ind)}
                        onChange={() => {
                          handleSelSingleSubFeature(e);
                        }}
                        sx={{
                          color: "#05306B",
                          "&.Mui-checked": { color: "#05306B" },
                        }}
                      />
                      {e?.sub_feature}
                    </div>
                  </>
                ))}
              </td>

              <td
                style={{ width: `${columnMinWidths[2]}%` }}
                className={`${
                  rowIndex === data?.length - 1 ? "" : "remove_bottom_border"
                }`}
              >
                {row?.subFeature?.map((e, ind) => (
                  <div
                    className={`${
                      row?.subFeature?.length % 2 !== 0
                        ? ind % 2 !== 0
                          ? "append_top_bottom_border"
                          : ""
                        : ind % 2 === 0
                        ? "append_bottom_border"
                        : ""
                    }
                    
                    acquire_height center_items`}
                    style={{ justifyContent: "center" }}
                  >
                    {e?.comment}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function UserManagementPopup({
  modalLabel = "some label",
  selFromListLabel = "Select From List",
  selectedListLabel = "Selected List",
  fetchSelFromSlang = "",
  handleSubFeatureChilds = () => {},
  selFromData,
  selectedFeaturesArr = [],
  handleClose = () => {},
}) {
  function checkIsSelectedSelFromCheckBox(e) {
    // console.log("checking sub child list element ===>", e);
    // { feature: { id: r?.id, subfeature: [{ id: e?.id, subList: [] }] } }
    // console.log("select from data list ====>", selFromData);
    /*
    comment: "‌Provide access to only a subset of already added storage"
    feature: 1
    feature_type: "access"
    id: 2
    model_data: (3) [{…}, {…}, {…}]
    model_name: "FileStorageInfomation"
    open_model: true
    popup_head: "Select Storage"
    sub_feature: "Storage Subset Selection"
    */
    let isIncluded = false;
    // console.log("selected features array ====>", selectedFeaturesArr);

    selectedFeaturesArr?.forEach((x) => {
      let { feature } = x;
      if (feature?.id === selFromData?.feature) {
        // console.log("check feature got matched");
        feature?.subfeature?.forEach((sfElem) => {
          // console.log("check feature got matched");
          // console.log("check : sfElem?.id === selFromData?.id =====>",sfElem?.id === selFromData?.id,"sfElem?.id ===>",sfElem?.id,"selFromData?.id ===>",selFromData?.id);
          // console.log("check : sfElem?.subList?.includes(e) =====>",sfElem?.subList?.includes(e),sfElem?.subList);
          if (
            sfElem?.id === selFromData?.id &&
            sfElem?.subList?.includes(e?.id)
          ) {
            // console.log("check sub feature got included");
            isIncluded = true;
          }
        });
      }
    });

    return isIncluded;
  }

  // console.log("select from data set ====>", selFromData);

  // useEffect(() => {
  //   function fetchSelectFromData() {
  //     let resp = [];
  //     switch (fetchSelFromSlang) {
  //       case "modal_slug":
  //         break;

  //       default:
  //         break;
  //     }
  //     setSelFromList([...resp]);
  //   }
  //   fetchSelectFromData();
  // }, []);

  return (
    <>
      <div className="preDefModelSelModal">
        <div className="preDefModelSelModal_head_wrppr">
          <h3>{selFromData?.popup_head}</h3>
          <IoMdClose
            onClick={() => handleClose()}
            className="close_modal_usr_acc_mng_tbl"
          />
        </div>
        <div className="preDefModelSelModal_sel_from_list_cont_wrppr">
          <div className="left_list_container">
            <div className="left_list_cont_head">{selFromListLabel}</div>
            <div className="left_list_cont_list_wrppr">
              {selFromData?.model_data?.map((e) => {
                return (
                  <>
                    <div className="sel_camera_modal_sel_cam_card_checkbox">
                      <Checkbox
                        size="12"
                        checked={checkIsSelectedSelFromCheckBox(e)}
                        onChange={() => handleSubFeatureChilds(e, selFromData)}
                      />
                      <p>{e?.name}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="left_list_container">
            <div className="left_list_cont_head">{selectedListLabel}</div>
            <div className="left_list_cont_list_wrppr">
              {/* {selectedFeaturesArr?.map((e) => (
                <>
                  <div className="sel_camera_modal_sel_cam_card_checkbox predef_modal_sel_list_li pre_def_li_active">
                    <p>Model 1</p>
                  </div>
                </>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
