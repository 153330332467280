import "./UserManagementViewUsrScreen.css";
import React, { useState, useRef, useEffect } from "react";
import FormInpWrpprFaceDetection from "../../../utility/styleWrpprComponents/formInpWrpprFaceDetection/formInpWrpprFaceDetection";
import { FormSelectInp } from "../../../utility/formElements/formElements";
import { FileSyncButton } from "../../../utility/button/button";
import { Checkbox } from "@mui/material";
import {
  getAccessFeatureList,
  sendAccessPermissionData,
  fetchEmployeesData,
  updateAccessManagementList,
} from "../../../action/action";
import { toast } from "react-toastify";

const InitialDataJobDetl = {
  name: "",
  job_description: "",
  job_type: "pre_trained_model",
  pre_trend_model: "",
  selected_camera: [],
  selected_stored_files: [],
  access_type: "",
};

const usersList = [
  { id: 1, name: "user 1" },
  { id: 2, name: "user 2" },
];

// ----------------------------------- TABLE DATA -----------------------------

const headers = ["Camera Name", "View", "Edit", "Delete"];

const columnMinWidths = [43, 43, 43, 43];

const dataArrForTable = [
  {
    id: 1,
    feature: "field 1",
    subFeature: [
      { label: "sub field 1", comment: "comment 1", id: 11 },
      { label: "sub field 1", comment: "comment 1", id: 11 },
      { label: "sub field 1", comment: "comment 1", id: 11 },
    ],
  },
  {
    id: 2,
    feature: "field 1",
    subFeature: [
      { label: "sub field 1", comment: "comment 1", id: 11 },
      { label: "sub field 1", comment: "comment 1", id: 11 },
      { label: "sub field 1", comment: "comment 1", id: 11 },
    ],
  },
  {
    id: 3,
    feature: "field 1",
    subFeature: [
      { label: "sub field 1", comment: "comment 1", id: 11 },
      { label: "sub field 1", comment: "comment 1", id: 11 },
      { label: "sub field 1", comment: "comment 1", id: 11 },
    ],
  },
];

// ----------------------------------- TABLE DATA -----------------------------

function UserManagementViewUsrScreen() {
  const [selEmpElemList, setSelEmpElemList] = useState([
    { id: "", name: "", user_id: "" },
  ]);
  const [selItemList, setSelItemList] = useState([]);
  const [selItemListAccTyp, setSelItemListAccTyp] = useState([]);
  const [filterContOverflow, setFilterContOverflow] = useState(false);
  const [selLabelSelDrpDwn, setSelLabelSelDrpDwn] = useState(
    "Select User From List"
  );
  const [selLabelSelDrpDwnAccTyp, setSelLabelSelDrpDwnAccTyp] =
    useState("Select Access Type");
  const [formDataJobDetl, setFormDataJobDetl] = useState({
    ...InitialDataJobDetl,
  });

  const [formDataErrorObj, setFormDataErrorObj] = useState({
    name: "",
    job_description: "",
    job_type: "",
    pre_trend_model: "",
    selected_camera: "",
    selected_stored_files: "",
    access_type: "",
  });

  // ----------------------- Access List States -----------------------------

  const [accessTypeArr, setAccessTypeArr] = useState([]);
  const [tableHeaderObj, setTableHeaderObj] = useState({});
  const [commonAcessObj, setCommonAcessObj] = useState({});
  const [specificTblDataObj, setSpecificTblDataObj] = useState({});

  const [selectedCommonAccArr, setSelectedCommonAccArr] = useState([]);

  // ----------------------- Access List States -----------------------------

  // ----------------------- Table List States -----------------------------

  const [selectedTblData, setSelectedTblData] = useState({});

  // ----------------------- Table List States -----------------------------

  const filterContRef = useRef(null);

  function handleAddModelSel(e) {
    console.log("getting select values ==>", e[0]);
    setSelItemList([{ ...e[0] }]);
    // setAddStorageDetl({ ...addStorageDetl, type: e[0]?.name });
    setSelLabelSelDrpDwn(e[0]?.name);
    setFormDataJobDetl({ ...formDataJobDetl, pre_trend_model: e[0]?.name });
  }

  function handleAddModelSelAccTyp(e) {
    console.log("getting select values access types ==>", e[0]);
    setSelItemListAccTyp([{ ...e[0] }]);
    // setAddStorageDetl({ ...addStorageDetl, type: e[0]?.name });
    setSelLabelSelDrpDwnAccTyp(e[0]?.name);
    setFormDataJobDetl({ ...formDataJobDetl, access_type: e[0]?.name });
  }

  function handleUpdateSelTblData(list = []) {
    setSelectedTblData((prev) => {
      let newObj = { ...prev };
      newObj[selLabelSelDrpDwnAccTyp] = [...list];
      return { ...newObj };
    });
  }

  function handleChangeCommonAccess(event, label) {
    let checked = event.target.checked;

    setSelectedCommonAccArr((prev) => {
      if (checked) {
        return [...prev, label];
      } else {
        return prev.filter((n) => n !== label);
      }
    });
  }

  function checkCommonAccess(label) {
    let isChecked = selectedCommonAccArr.includes(label);
    // console.log("ischecked value ======>",isChecked,"    checking label =====>",label);
    return isChecked;
  }

  async function handleSendAccessData() {
    if (!selItemList[0]?.id) {
      toast.warn("please select user first");
      return;
    }
    if (
      !selectedTblData?.[selItemListAccTyp[0]?.name]?.length &&
      !selectedCommonAccArr.length
    ) {
      toast.warn("You have not changed anything in access management");
      return;
    }
    let data = {
      user_ids: selItemList.map((item) => item?.user_id),
      [selItemListAccTyp[0].name]: {
        [commonAcessObj[selLabelSelDrpDwnAccTyp].key]: checkCommonAccess(
          commonAcessObj[selLabelSelDrpDwnAccTyp]?.key
        ),
        data: selectedTblData[selItemListAccTyp[0].name]?.map((item) => {
          return {
            id: item.id,
            access: Object.keys(item).filter(
              (key) => key !== "id" && item[key]
            ),
          };
        }),
      },
    };

    updateAccess(data);
  }

  const updateAccess = async (paylod = {}) => {
    let resp = await updateAccessManagementList(paylod);
    if (resp?.success) {
      toast.success("Access updated successfully!");
    }
    if (!resp?.success) {
      toast.error(resp?.data?.message||"Error!");
    }
  };

  function convertToSelListData(arr = []) {
    let data = arr.map((e) => {
      /*
      id: 1
      organization: null
      username: "gaurav"
      */
      //    { id: 1, name: "user 1" }
      return { id: e?.employee_id, name: e?.username, user_id: e?.id };
    });
    console.log("converted data list ---->", data);
    setSelEmpElemList([...data]);
  }

  useEffect(() => {
    let container = filterContRef.current;
    if (
      container.scrollWidth > container.clientWidth ||
      container.scrollHeight > container.clientHeight
    ) {
      setFilterContOverflow(true);
    } else {
      setFilterContOverflow(false);
    }

    // ---------------------- fetching accesses --------------------------

    async function fetchAccessList() {
      let resp = await getAccessFeatureList();

      if (resp?.success) {
        console.log("getting access list ===========>", resp?.data?.data?.data);
        let features = resp?.data?.data?.data?.features,
          subFeatures = resp?.data?.data?.data?.sub_features;

        console.log("got features =====>", features);
        console.log("got sub features =====>", subFeatures);

        // -------------- extracting access types ------------

        let AccessTypesList = [],
          indx = 0,
          commonAccessList = {},
          specificAccessList = {},
          tableHeadersList = {};
        for (const key in features) {
          if (Object.hasOwnProperty.call(features, key)) {
            console.log("feature", features, key);
            const AccessCatg = features[key];
            let commonAccTypes = AccessCatg?.common_access,
              specificAccData = AccessCatg?.data;
            let obj = { id: indx, name: key };
            indx += 1;
            AccessTypesList.push(obj);
            console.log("access", commonAccTypes);
            commonAccessList[key] = commonAccTypes;
            specificAccessList[key] = [...specificAccData];
            tableHeadersList[key] = [key, ...subFeatures];
          }
        }
        console.log("common access list", commonAccessList);
        setAccessTypeArr([...AccessTypesList]);
        setCommonAcessObj({ ...commonAccessList });
        setSpecificTblDataObj({ ...specificAccessList });
        console.log("initialy selected access type ====>", AccessTypesList[0]);
        setSelItemListAccTyp([{ ...AccessTypesList[0] }]);
        setSelLabelSelDrpDwnAccTyp(AccessTypesList[0]?.name);

        setTableHeaderObj({ ...tableHeadersList });

        // -------------- extracting access types ------------
      } else {
        toast.error("problem fetching access data...");
      }
    }
    fetchAccessList();

    // ---------------------- fetching accesses --------------------------

    async function getEmployeesData() {
      let acc_token_key_name = process.env.REACT_APP_Access_Token_Prop_Name,
        myCookie = localStorage?.getItem(acc_token_key_name);
      let resp = await fetchEmployeesData(myCookie);
      if (resp?.success) {
        // console.log(
        //   "my employees data 24244244242 ========>",
        //   resp?.data?.data?.data
        // );
        let data = resp?.data?.data?.data;
        // setEmplyData([...data]);
        convertToSelListData([...data]);
      }
    }
    getEmployeesData();
    /*
biometric_id: ""
company_name: "marktine"
contact_num: "9999999999"
email: "gaurav.surolia@marktine.com"
employee_id: null
id: 1
organization: null
username: "gaurav"
    */
  }, []);
  console.log("access array", commonAcessObj);

  return (
    <>
      <div className="connect_storage_screen_container">
        <h2>Access Management</h2>
        <p className="connect_storage_screen_container_p">
          Manage Access for your selected Cameras{" "}
        </p>
        <div className="adjust_sel_opt_list">
          <div className="sel_drp_dwn_container">
            <FormInpWrpprFaceDetection>
              <FormSelectInp
                title={"User List"}
                placeHldr={"Select User From List"}
                multiSel={true}
                handlChange={setSelItemList}
                optionList={selEmpElemList}
                selProdList={selItemList}
                initialLabelDropDown={selLabelSelDrpDwn}
                error={
                  formDataErrorObj?.pre_trend_model &&
                  "Please select a model to continue"
                }
                unableSearch={true}
              />
            </FormInpWrpprFaceDetection>
            <FormInpWrpprFaceDetection>
              <FormSelectInp
                title={"Access Type"}
                placeHldr={"Select Access Type"}
                multiSel={false}
                handlChange={handleAddModelSelAccTyp}
                optionList={accessTypeArr}
                selProdList={selItemListAccTyp}
                initialLabelDropDown={selLabelSelDrpDwnAccTyp}
                error={
                  formDataErrorObj?.pre_trend_model &&
                  "Please select a model to continue"
                }
                // unableSearch={true}
              />
            </FormInpWrpprFaceDetection>
          </div>

          {/* ----------------------  label list container  ---------------------- */}
          <div className="commn_filtr_cont_wrppr">
            <div
              className="common_filter_list_view_usr_usrmngmnt"
              ref={filterContRef}
            >
              <>
                <div className="sel_camera_modal_sel_cam_card_checkbox">
                  <Checkbox
                    size="12"
                    checked={checkCommonAccess(
                      commonAcessObj[selLabelSelDrpDwnAccTyp]?.key
                    )}
                    onChange={(e) =>
                      handleChangeCommonAccess(
                        e,
                        commonAcessObj[selLabelSelDrpDwnAccTyp]?.key
                      )
                    }
                  />
                  <p>{commonAcessObj[selLabelSelDrpDwnAccTyp]?.title}</p>
                </div>
              </>
            </div>
            {filterContOverflow && (
              <>
                <span className="more_link_commn_filtr_cont_list">more...</span>
              </>
            )}
          </div>

          {/* ----------------------  label list container  ---------------------- */}

          <TableComponent
            headers={tableHeaderObj[selLabelSelDrpDwnAccTyp]}
            columnMinWidths={columnMinWidths}
            data={specificTblDataObj[selLabelSelDrpDwnAccTyp]}
            handleUpdateSelectedDataList={handleUpdateSelTblData}
            selectedDataList={selectedTblData}
            selAccType={selLabelSelDrpDwnAccTyp}
          />
        </div>
        <div className="annotation_btn_wrppr">
          <div className="remove_btn_margin" style={{ marginTop: "1rem" }}>
            <FileSyncButton
              title="Save"
              stylObj={{
                boxShadow: "0px 0px 5.1px 0px rgb(225 225 225 / 88)",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
              handleClick={handleSendAccessData}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManagementViewUsrScreen;

function TableComponent({
  headers = [],
  columnMinWidths = [],
  data = [],
  selectedDataList = {},
  handleUpdateSelectedDataList = () => {},
  selAccType = "",
}) {
  function handleSelectAllRows(event) {
    console.log("event calling ===>", event.target.checked);
    let isChecked = event.target.checked;
    let newArr = [];
    if (isChecked) {
      newArr = data?.map((e) => {
        return { id: e?.id, view: true, edit: true, delete: true };
      });
    }

    handleUpdateSelectedDataList(newArr);
  }

  function handleSelectSingleRow(event, id) {
    let isChecked = event.target.checked;
    let myList = selectedDataList[selAccType] || [];
    if (isChecked) {
      let obj = { id: id, view: true, edit: true, delete: true };
      myList.push(obj);
    } else {
      myList = myList?.filter((elem) => {
        return elem?.id !== id;
      });
    }
    handleUpdateSelectedDataList(myList);
  }

  function handleColumnChange(event, id, label = "") {
    console.log("");
    // if(!selectedDataList[selAccType])return;

    let isChecked = event.target.checked,
      included = false,
      filterList = false;
    let myList = selectedDataList[selAccType] || [];
    myList?.forEach((elem) => {
      if (elem?.id === id) {
        elem[label] = isChecked;
        included = true;
        if (!elem.view && !elem.edit && !elem.delete) {
          filterList = true;
        }
      }
    });
    if (filterList) {
      myList = myList.filter((e) => e?.id !== id);
    }
    if (!included) {
      let obj = { id: id, view: false, edit: false, delete: false };
      obj[label] = isChecked;
      myList.push(obj);
    }
    handleUpdateSelectedDataList(myList);
  }

  function checkAccFeatureID(id) {
    let myList = selectedDataList[selAccType];
    let isChecked = false;

    myList?.forEach((e, i) => {
      if (e?.id === id) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  function checkSpecifiedFeatures(id, label = "") {
    if (!label) return;
    let myList = selectedDataList[selAccType];
    let isChecked = false;

    myList?.forEach((e) => {
      if (e?.id === id) {
        console.log("label value =====>", e[label]);
      }
      if (e?.id === id && e[label]) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  return (
    <>
      <table className="remove_border_spacing">
        <thead className="mtHeadFDFI">
          <tr>
            <th style={{ width: `50px` }}>
              <Checkbox
                checked={data?.length === selectedDataList[selAccType]?.length}
                onChange={handleSelectAllRows}
                sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
              />
            </th>
            {headers?.map((title, index) => (
              <th key={index} style={{ width: `${columnMinWidths[index]}%` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mthCheckBoxRuleNoFDFI mtBodyFDFI collapse_border append_max_height append_background">
          {data?.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`} className={`datasetTableRowFDFI`}>
              <td style={{ width: `50px` }}>
                <Checkbox
                  checked={checkAccFeatureID(row?.id)}
                  onChange={(e) => {
                    handleSelectSingleRow(e, row?.id);
                  }}
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>
              <td>{row?.name || "--"}</td>
              <td>
                <Checkbox
                  checked={checkSpecifiedFeatures(row?.id, "view")}
                  onChange={(e) => {
                    handleColumnChange(e, row?.id, "view");
                  }}
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>
              <td>
                <Checkbox
                  checked={checkSpecifiedFeatures(row?.id, "edit")}
                  onChange={(e) => {
                    handleColumnChange(e, row?.id, "edit");
                  }}
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>
              <td>
                <Checkbox
                  checked={checkSpecifiedFeatures(row?.id, "delete")}
                  onChange={(e) => {
                    handleColumnChange(e, row?.id, "delete");
                  }}
                  sx={{
                    color: "#05306B",
                    "&.Mui-checked": { color: "#05306B" },
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
